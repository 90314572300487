import React, { FC, useCallback } from 'react'

import { RouterLink, LinkProps } from 'router'
import { cn } from 'utils'

/*
  instead of

  <Link to={generatePath(path, { uuid })}/>

  =>

  <Link to={path} query={{ uuid:uuid }} />

*/

export const LINK_THEMES = [
  null,
  'button',
  'primary button',
  'secondary button',
  'flat button',
  'option-like button',
]

export interface Link extends LinkProps {
  theme?: typeof LINK_THEMES[number]
}

const blur = (event) => event.target.blur()

export const Link: FC<Link> = ({ theme, to, next, query, className, children, ...props }) => (
  <RouterLink
    {...props}
    to={to}
    next={next}
    query={query}
    className={cn('link', theme, className)}
    onClick={blur}
  >
    {children}
  </RouterLink>
)

export const LinkWithCustomHandler: FC<Link> = ({
  theme,
  to,
  next,
  query,
  className,
  onClick,
  children,
  ...props
}) => {
  const click = useCallback(
    (e) => {
      onClick?.(e)
      blur(e)
    },
    [onClick],
  )
  return (
    <RouterLink
      {...props}
      to={to}
      next={next}
      query={query}
      className={cn('link', theme, className)}
      onClick={click}
    >
      {children}
    </RouterLink>
  )
}
