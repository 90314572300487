import React from 'react'

import { MenuLink, Menu } from 'ui/menu'

import { routes } from 'const/routes'
import { useLocation } from 'router'

const pagesWithoutRedirect = [
  routes.registerByType,
  routes.recoveryPassword,
  routes.recoveryPasswordDone,
]
export const NavSignedOff = (props) => {
  const { pathname, state } = useLocation()
  const nextAfterLogin = pagesWithoutRedirect.includes(pathname) ? null : pathname
  const nextAfterRegistration =
    !state?.redirect || pagesWithoutRedirect.includes(state.redirect) ? null : state.redirect
  return (
    <Menu {...props}>
      <MenuLink to={routes.login} className="flat button" next={nextAfterLogin}>
        Log in
      </MenuLink>
      <MenuLink to={routes.register} className="primary button" next={nextAfterRegistration}>
        Sign up
      </MenuLink>
    </Menu>
  )
}
