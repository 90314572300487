import { charsets, PasswordPolicy } from 'password-sheriff'

export const cn = (...names: (string | null | undefined | false)[]): string =>
  names.filter((value) => !!value && typeof value === 'string').join(' ')

export const toServerDate = (value: string): string | null =>
  value ? new Date(value).toLocaleDateString('en-US') : null

export const toISODate = (value?: string | number | Date | null): string | null => {
  if (!value) return null

  const date =
    typeof value === 'number'
      ? new Date(value)
      : value instanceof Date
      ? value
      : new Date(Date.parse(value))

  if (!date || isNaN(date.valueOf())) return null

  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDay().toString().padStart(2, '0'),
  ].join('-')
}

export const toHumanDateTime = (value: string, localDateTime?: boolean): string => {
  if (!value) return value
  const date = new Date(value)
  return date
    .toLocaleString('en-US', {
      ...(!localDateTime && { timeZone: 'UTC' }),
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: undefined,
    })
    .replace(' PM', 'pm')
    .replace(' AM', 'am')
}

export const todayDate = (): string => new Date().toISOString().replace(/T.*/, '')

export const nowTimestamp = (): number => new Date().valueOf()
export const timestampAfter = (seconds?: number): number | null =>
  seconds ? nowTimestamp() + (seconds - 1) * 1000 : null

export const isFutureTimestamp = (timestamp: number): boolean => nowTimestamp() < timestamp

export const isIOS = (): Array<any> | null => navigator.userAgent.match(/ipad|iphone/i)

export const isFirefox = (): Array<any> | boolean => {
  /* @ts-ignore */
  if (navigator?.userAgentData) {
    /* @ts-ignore */
    return navigator?.userAgentData?.brands?.some((brand) => brand.brand === 'Firefox')
  }
  return /Firefox/.test(navigator.userAgent)
}

export const iosCopy = (input: HTMLInputElement): void => {
  const range = document.createRange()
  range.selectNodeContents(input)
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  input.setSelectionRange(0, 999999)
  document.execCommand('copy')
}

export const isInputDateSupported = (): boolean => {
  const input = document.createElement('input')
  input.type = 'date'
  return input.type === 'date'
}

export const excludeProp = (obj: Record<string, any>, x: string): Record<string, any> => {
  const { [x]: old, ...result } = obj
  return result
}

const fibonacciTimerGen = function* (timer): Generator<number> {
  let seed1 = 0
  let seed2 = 1
  while (true) {
    yield (() => {
      seed2 = seed2 + seed1
      seed1 = seed2 - seed1
      return timer * seed2
    })()
  }
}

export const fibonacciTimer = (timer: number): (() => number) => {
  const get = fibonacciTimerGen(timer)
  return () => get.next().value
}

export const dateOfBirthValidation = (): string => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - 18)
  return date.toISOString().replace(/T.*/, '')
}

export const validatePasswordStrength = (password: string): string | null => {
  const passwordPolicy = new PasswordPolicy({
    length: { minLength: 8 },
    contains: {
      expressions: [
        charsets.lowerCase,
        charsets.upperCase,
        charsets.numbers,
        charsets.specialCharacters,
      ],
    },
    identicalChars: {
      max: 3,
    },
  })
  const isValid = password && passwordPolicy.check(password) && password.length < 24
  return isValid
    ? null
    : 'Password must be 8-24 characters and contain at least 1 upper case, 1 number, 1 special character and not more then 3 repeated in a row characters'
}
