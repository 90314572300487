import React, { FC } from 'react'

// eslint-disable-next-line import/no-cycle
import { ErrorSection, Container } from 'ui/page'

import { ErrorBoundary, IErrorBoundaryProps } from './error-boundary'

const ErrorPage = (props) => (
  <Container>
    <ErrorSection {...props} />
  </Container>
)

export const ErrorBoundarySection: FC<Omit<IErrorBoundaryProps, 'component'>> = (props) => (
  <ErrorBoundary {...props} component={ErrorSection} />
)

export const ErrorBoundaryPage: FC<Omit<IErrorBoundaryProps, 'component'>> = (props) => (
  <ErrorBoundary {...props} component={ErrorPage} />
)
