import PropTypes from 'prop-types'

import { useField } from '../context'

const getFieldValueArray = (value) => {
  if (!value || Array.isArray(value)) {
    return value
  }

  if (typeof value === 'string') {
    return value?.split(',')
  }

  return [value]
}
export const IsFieldValue = ({ name, blank, equals, not, oneOf, allOf, children }) => {
  const field = useField(name)
  if (field.value === undefined) return null
  let result = false
  if (blank) {
    result = Array.isArray(field.value) ? !field.value.length : !field.value
  }
  if (equals !== undefined) {
    result = result || equals instanceof RegExp ? equals.test(field.value) : field.value === equals
  }
  if (Array.isArray(oneOf)) {
    //for checkbox group case
    const fieldValueArray = getFieldValueArray(field?.value)
    result = result || (fieldValueArray && oneOf.some((one) => fieldValueArray.includes(one)))
  }
  if (Array.isArray(allOf)) {
    //for checkbox group case
    const fieldValueArray = getFieldValueArray(field?.value)
    if (fieldValueArray && fieldValueArray.length === allOf.length) {
      result = result || (fieldValueArray && allOf.every((one) => fieldValueArray.includes(one)))
    }
  }
  if (not) {
    result = !result
  }
  return result ? children : null
}

IsFieldValue.propTypes = {
  blank: PropTypes.bool,
  equals: PropTypes.oneOfType([
    PropTypes.instanceOf(RegExp),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  // field name
  name: PropTypes.string.isRequired,
  not: PropTypes.bool,
  oneOf: PropTypes.arrayOf(PropTypes.any),
}
IsFieldValue.defaultProps = {
  blank: false,
  not: false,
}
