import React from 'react'

import { MenuLink, MenuButton, MenuTitle } from 'ui/menu'

import { SignedIn, WithDashboard } from 'components/signed'
import { useUser } from 'components/user'
import { NotificationIndicator } from 'components/notification-indicator'
import { useNotifications } from 'components/use-notifications'

import { api } from 'api'
import { USER_ROLE } from 'const/types'
import { routes } from 'const/routes'
import IconAccount from 'icons/account.svg'
import { CLIENTS_LIST_TITLE } from 'const/labels'

export { IconAccount }

export const UserMenuItems = () => {
  const {
    notifications: [list],
  } = useNotifications()
  return (
    <>
      <SignedIn userRole={[USER_ROLE.PLATFORM_LICENSEE, USER_ROLE.ADMIN, USER_ROLE.LIMITED_ADMIN]}>
        <WithDashboard>
          <MenuLink to={routes.dashboardUsersList}>Dashboard</MenuLink>
        </WithDashboard>
      </SignedIn>
      <SignedIn userRole={[USER_ROLE.ADMIN, USER_ROLE.LIMITED_ADMIN]}>
        <MenuLink to={routes.accountLicensee}>Issuer Offerings</MenuLink>
      </SignedIn>
      <SignedIn userRole={[USER_ROLE.ADMIN, USER_ROLE.LIMITED_ADMIN]}>
        <MenuLink to={routes.oldAdmin} target="_blank" rel="noopener noreferrer">
          Old Admin Panel
        </MenuLink>
      </SignedIn>
      <MenuLink to={routes.accountNotifications}>
        Notifications {list?.length > 0 && <NotificationIndicator className="menu-notification" />}
      </MenuLink>
      <MenuLink to={routes.accountRequests}>Requests</MenuLink>
      {/*<SignedIn
        userRole={[USER_ROLE.SUPERVISOR]}
        excludeUserRole={[
          USER_ROLE.ADVISOR,
          USER_ROLE.REGISTERED_REPRESENTATIVE,
          USER_ROLE.REGISTERED_BROKER_DEALER,
          USER_ROLE.DELEGATE,
        ]}
      >
        <MenuTitle />
        <MenuLink to={routes.supervisorClientsInvestmentsList}>
          {CLIENTS_LIST_TITLE + ' Investments'}
        </MenuLink>
        <MenuTitle />
      </SignedIn>*/}
      <SignedIn
        userRole={[USER_ROLE.ACCOUNTANT]}
        excludeUserRole={[
          USER_ROLE.ADVISOR,
          USER_ROLE.REGISTERED_REPRESENTATIVE,
          USER_ROLE.REGISTERED_BROKER_DEALER,
          USER_ROLE.DELEGATE,
        ]}
      >
        <MenuTitle />
        <MenuLink to={routes.accountantClientsInvestmentsList}>
          {CLIENTS_LIST_TITLE + ' Investments'}
        </MenuLink>
        <MenuLink to={routes.accountantClientsInvestmentsDocumentsList}>
          {CLIENTS_LIST_TITLE + ' Investments Docs'}
        </MenuLink>
        <MenuTitle />
      </SignedIn>
      <SignedIn
        userRole={[
          USER_ROLE.ADVISOR,
          USER_ROLE.REGISTERED_REPRESENTATIVE,
          USER_ROLE.REGISTERED_BROKER_DEALER,
          USER_ROLE.DELEGATE,
        ]}
      >
        <MenuTitle />
        <MenuLink to={routes.advisorClientsList}>My Clients</MenuLink>
        <MenuLink to={routes.advisorClientsInvestmentsList}>
          {CLIENTS_LIST_TITLE + ' Investments'}
        </MenuLink>
        <MenuLink to={routes.advisorClientsInvestmentsDocumentsList}>
          {CLIENTS_LIST_TITLE + ' Investments Docs'}
        </MenuLink>
        <MenuLink to={routes.advisorClientsActivityReports}>Clients Activity Reports</MenuLink>
        <MenuTitle />
      </SignedIn>
      {/*<SignedIn
        userRole={[
          USER_ROLE.ADVISOR,
          USER_ROLE.REGISTERED_REPRESENTATIVE,
          USER_ROLE.REGISTERED_BROKER_DEALER,
          USER_ROLE.DELEGATE,
        ]}
      >
        <MenuLink to={routes.advisorSupervisors}>My Supervisors</MenuLink>
        <MenuTitle />
      </SignedIn>*/}

      <SignedIn userRole={USER_ROLE.INVESTOR}>
        <MenuLink to={routes.investorProfiles}>My Investment Profiles</MenuLink>
        <MenuLink to={routes.investorInvestments}>My Investments</MenuLink>
        <MenuLink to={routes.investorInvestmentDocuments}>My Investment Docs</MenuLink>
        {/*
        <MenuLink to={routes.investorInvestmentHoldings}>Investment Holdings</MenuLink>
        */}
        <MenuLink to={routes.investorOfferingUpdates}>My Offerings Updates</MenuLink>
        <MenuLink to={routes.investorAdvisorsList}>My Advisors List</MenuLink>
      </SignedIn>
      {/*<SignedIn userRole={[USER_ROLE.SUPERVISOR]}>
        <MenuLink to={routes.supervisorAdvisorsList}>My Supervised Advisors</MenuLink>
      </SignedIn>*/}
      <MenuTitle />
      <MenuLink to={routes.accountSettings}>My Account</MenuLink>
      {/*<MenuLink to={routes.accountDocuments}>My Docs</MenuLink>*/}
      <MenuLink to={routes.accountSharedDocuments}>Shared with Me</MenuLink>
      <MenuLink to={routes.accountSecurity}>Security</MenuLink>
      <MenuTitle />
      <MenuButton onClick={api.auth.logout}>Logout</MenuButton>
    </>
  )
}

export const UserName = ({ className }) => {
  const user = useUser()
  return <span className={className}>{user?.name}</span>
}

export const ProfileButton = () => {
  const {
    notifications: [list],
  } = useNotifications()
  return (
    <div className="profile-toggle notifications">
      <IconAccount className="account-icon" />
      {list?.length > 0 && <NotificationIndicator />}
      <div className="label">
        <UserName />
      </div>
    </div>
  )
}
