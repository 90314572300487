/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './hamburger-menu.css'
import React, { FC, useState } from 'react'

import { IconButton } from 'ui/button'

import IconHamburger from 'icons/hamburger.svg'
import IconClose from 'icons/close.svg'
import { cn } from 'utils'

export const HamburgerMenu: FC<{ className?: string }> = ({ className, children }) => {
  const [isOpen, setOpen] = useState(false)

  const handleCommonClick = (/** @type {MouseEvent} */ event) => {
    event.target.tagName === 'A' && handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
    document.body.classList.add('noScroll')
  }
  const handleClose = () => {
    setOpen(false)
    document.body.classList.remove('noScroll')
  }

  return (
    <div className={cn('hamburger-menu', isOpen && 'open', className)}>
      <div className="mobile-nav-cutter" />

      {!isOpen && (
        <IconButton className="hamburger-toggle" onClick={handleOpen} title="Open Menu">
          <IconHamburger />
        </IconButton>
      )}
      {isOpen && (
        <IconButton className="hamburger-toggle" onClick={handleClose} title="Close Menu">
          <IconClose />
        </IconButton>
      )}

      <div className="hamburger-menu-content" onClick={handleCommonClick}>
        {children}
      </div>
    </div>
  )
}
