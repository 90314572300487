import './styles.css'
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'ui/form'
import { Select } from 'ui/field'
import { Loader } from 'ui/data-loader/loader'

import { useLoader } from 'components/use-loader'
import { usePortal } from 'components/portal-provider'

import { api } from 'api'

const ThemeSwitcherResolved = ({ items }) => {
  const { portalThemeUuid } = usePortal()
  const [theme, setTheme] = useState(null)
  const themeOptions = useMemo(
    () =>
      Object.fromEntries(
        items
          ?.filter(({ uuid }) => uuid !== portalThemeUuid)
          ?.map(({ name, stylesheet }) => [stylesheet, name]),
      ),
    [items, portalThemeUuid],
  )
  const submit = ({ theme }) => setTheme(theme)

  return (
    <>
      {theme && <style>{theme}</style>}
      <Form
        name="theme_switcher_form"
        onSubmit={submit}
        onChange={submit}
        className="theme-switcher"
      >
        <Select name="theme" options={themeOptions} placeholder="Default" />
      </Form>
    </>
  )
}

ThemeSwitcherResolved.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      stylesheet: PropTypes.string,
      uuid: PropTypes.string,
    }),
  ),
}

export const ThemeSwitcher = () => {
  const loader = useLoader(api.themes.list)
  return <Loader {...loader} component={ThemeSwitcherResolved} />
}
