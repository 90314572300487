import React, { ComponentType, FC, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { Throbber } from 'ui/throbber'
import { ErrorSection } from 'ui/page'

interface DefaultErrorHandler {
  error?: string
}

export const DefaultErrorHandler: FC<DefaultErrorHandler> = ({ error }) => (
  <ErrorSection className="loader-error">{error}</ErrorSection>
)
// DefaultErrorHandler.propTypes = {
//   error: PropTypes.string,
// }

type LoaderError = { error: string } | string

interface LoaderHandler<T> {
  error?: LoaderError
  result: T
  pending?: boolean
  errorComponent: ComponentType<{ error: string }>
  pendingComponent: ComponentType
  component: ComponentType
}

const LoaderHandler: FC<LoaderHandler<any>> = ({
  error,
  result,
  pending,
  errorComponent: ErrorComponent = DefaultErrorHandler,
  pendingComponent: PendingComponent = Throbber,
  component: ResultComponent,
  children,
  ...props
}) => {
  if (pending) return <PendingComponent />
  if (error) return <ErrorComponent error={typeof error === 'string' ? error : error?.error} />
  if (ResultComponent) return <ResultComponent {...props} {...result} />
  if (typeof children === 'function') {
    return children(result)
  }
  if (children)
    return React.Children.map(children, (element) =>
      React.isValidElement(element) ? React.cloneElement(element, result) : element,
    )
  return null
}

// LoaderHandler.propTypes = {
//   component: PropTypes.func,
//   error: PropTypes.string,
//   errorComponent: PropTypes.func,
//   pending: PropTypes.bool,
//   pendingComponent: PropTypes.func,
//   result: PropTypes.any,
// }

export const Loader = React.memo(LoaderHandler)
