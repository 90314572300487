import './button.css'
import React, { FC, SVGProps, ComponentProps, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

const enum Theme {
  primary = 'primary',
  secondary = 'secondary',
  inline = 'inline',
  flat = 'flat',
  hyperlink = 'hyperlink',
  optionLike = 'option-like',
}

export interface ButtonProps extends Omit<ComponentProps<'button'>, 'ref'> {
  theme?: `${Theme}`
  small?: boolean
  wide?: boolean
  rightIcon?: FC<SVGProps<SVGElement>>
  leftIcon?: FC<SVGProps<SVGElement>>
  pending?: boolean
  children?: ReactNode
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className: customClassName,
      type,
      theme,
      small,
      wide,
      children,
      rightIcon: RightIcon,
      leftIcon: LeftIcon,
      pending,
      ...props
    },
    ref,
  ) => {
    const className = cn(
      'button',
      wide && 'wide',
      small && 'small',
      theme,
      !theme && type === 'submit' && 'primary',
      !theme && type === 'reset' && 'secondary',
      pending && 'pending',
      customClassName,
    )

    return (
      // eslint-disable-next-line react/button-has-type
      <button {...props} type={type} className={className} ref={ref}>
        {LeftIcon && <LeftIcon className="left" />}
        {children}
        {RightIcon && <RightIcon className="right" />}
      </button>
    )
  },
)

Button.displayName = 'Button'
// Button.propTypes = {
//   leftIcon: PropTypes.func,
//   pending: PropTypes.bool,
//   rightIcon: PropTypes.func,
//   small: PropTypes.bool,
//   theme: PropTypes.oneOf(['primary', 'secondary', 'inline', 'flat', 'option-like']),
//   type: PropTypes.oneOf(['submit', 'reset', 'button']),
//   wide: PropTypes.bool,
// }
Button.defaultProps = {
  type: 'button',
  wide: false,
  small: false,
}
