import './page-content.css'
import React, { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

type ReactComponentLike =
  | string
  | ((props: any, context?: any) => any)
  | (new (props: any, context?: any) => any)

interface IContainer {
  as?: ReactComponentLike
  cols?: number
  theme?: 'elevated' | 'gray'
  center?: boolean
  hero?: boolean
  className?: string
  children?: ReactNode
}

export const Container: FC<IContainer> = ({
  className,
  as: Component,
  cols,
  center,
  hero,
  theme,
  ...props
}) => (
  <Component
    {...props}
    className={cn(
      'page-content',
      center && 'center',
      hero && 'hero',
      theme,
      'cols-' + cols,
      className,
    )}
  />
)
// Container.propTypes = {
//   as: PropTypes.elementType,
//   center: PropTypes.bool,
//   cols: PropTypes.number,
//   hero: PropTypes.bool,
//   theme: PropTypes.oneOf(['elevated', 'gray']),
// }
Container.defaultProps = {
  cols: 1,
  hero: false,
  center: false,
  as: 'section',
}
