import React, { FC, ReactNode, useContext } from 'react'

import { RoutePath } from 'router'

export interface Breadcrumb {
  children: ReactNode
  key: string | number
  to: RoutePath
  query?: Record<string, string | number>
}

const Context = React.createContext<Breadcrumb[]>([])

export const useBreadcrumbs = (): Breadcrumb[] => {
  return useContext(Context)
}

export const BreadcrumbsContext: FC<Omit<Breadcrumb, 'key'> & { label: ReactNode }> = ({
  label,
  to,
  query,
  children,
}) => {
  const breadcrumbs = useBreadcrumbs() || []
  return (
    <Context.Provider
      value={[
        ...breadcrumbs,
        {
          children: label,
          key: typeof label === 'string' ? label : Math.round(new Date().valueOf() * Math.random()),
          to,
          query,
        },
      ]}
    >
      {children}
    </Context.Provider>
  )
}

// BreadcrumbsContext.propTypes = {
//   label: PropTypes.node,
//   query: PropTypes.object,
//   to: RoutePath.isRequired,
// }
