// Safari 'This operation is insecure' fix.
const backupStorage = {}
let storageMode = 'sessionStorage'
try {
  window.sessionStorage.length
  storageMode = 'sessionStorage'
} catch (e) {
  storageMode = 'backupStorage'
}

function setSessionStorage(key, value) {
  switch (storageMode) {
    case 'sessionStorage':
      window.sessionStorage.setItem(key, value)
      break
    case 'backupStorage':
      backupStorage[key] = value
      break
  }
}
function getSessionStorage(key) {
  switch (storageMode) {
    case 'sessionStorage':
      return window.sessionStorage.getItem(key)
    case 'backupStorage':
      return backupStorage[key]
  }
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(getSessionStorage(`retry-${name}-refreshed`) || 'false')
    // try to import the component
    componentImport()
      .then((component) => {
        setSessionStorage(`retry-${name}-refreshed`, 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          setSessionStorage(`retry-${name}-refreshed`, 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}
