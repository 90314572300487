import './select.css'
import React from 'react'

import { Select as SelectInput } from 'ui/form'

import IconDown from 'icons/down.svg'
import { cn } from 'utils'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'Select'

  getWrapperClassName() {
    return super.getWrapperClassName('select-container')
  }

  renderField({ className, ...props }) {
    return (
      <>
        <SelectInput {...props} className={cn(className, 'select')} />
        <IconDown className="select-icon" />
      </>
    )
  }
}

export const Select = withFormDefaultValues(Field)
