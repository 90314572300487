import React, { ReactNode, VFC } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'ui/link'

import { cn } from 'utils'

import { useBreadcrumbs } from './breadcrumbs-context'

export interface Breadcrumbs {
  className?: string
  divider?: ReactNode
}

export const Breadcrumbs: VFC<Breadcrumbs> = ({ className, divider }) => {
  const breadcrumbs = useBreadcrumbs()
  return breadcrumbs?.length > 0 ? (
    <div className={cn('breadcrumbs', className)}>
      {breadcrumbs?.map(({ key, ...props }, index) => (
        <React.Fragment key={key}>
          <Link {...props} />
          {index !== breadcrumbs.length - 1 && divider}
        </React.Fragment>
      ))}
    </div>
  ) : null
}

// Breadcrumbs.propTypes = {
//   divider: PropTypes.node,
//   key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// }

Breadcrumbs.defaultProps = {
  divider: <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>,
}
