export const singleton = <
  T extends (...args: any[]) => Promise<any>,
  TResult extends Awaited<T> = Awaited<T>,
>(
  f: T,
): ((...args: Parameters<T>) => Promise<TResult>) => {
  let currentPromise: null | Promise<TResult> = null
  let resolve: (value: TResult) => void, reject: (value: TResult) => void

  return (...args: Parameters<T>): Promise<TResult> => {
    if (!currentPromise) {
      currentPromise = new Promise((_resolve, _reject) => {
        resolve = _resolve
        reject = _reject
      })

      Promise.resolve(f(...args))
        .then(
          (result) => {
            resolve(result)
          },
          (err) => {
            reject(err)
          },
        )
        .finally(() => {
          currentPromise = null
        })
    }
    return Promise.resolve(currentPromise)
  }
}
