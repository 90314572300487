import React from 'react'
import { Helmet } from 'react-helmet'

import { usePortal } from '../portal-provider'

export const PortalHead = () => {
  const { title, default: defaultPortal } = usePortal()
  const isProduction = process.env.COMPOSE_PROJECT_NAME === '1776ing_ui_prod'
  const isCaliber = location.origin.includes('caliber')

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {defaultPortal && isProduction && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FLM0X60YH0" />
      )}
      {defaultPortal && isProduction && (
        <script>
          {`window.dataLayer = window.dataLayer || [];

            function gtag(){dataLayer.push(arguments);}

            gtag('js', new Date());

            gtag('config', 'G-FLM0X60YH0');
          `}
        </script>
      )}

      {isProduction && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-194313505-1" />
      )}
      {isProduction && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag()

            {dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-194313505-1');
          `}
        </script>
      )}

      {defaultPortal && isProduction && (
        <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/e1ae1f195b37c7674fc45b323/cd31b709bbcd6bb58be60c839.js");`}</script>
      )}

      {/*
      {isProduction && isCaliber && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-M4T6MMMW');`}
        </script>
      )}
      */}
    </Helmet>
  )
}
