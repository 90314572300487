import './theme.css'
import './app.css'

import React, { useEffect } from 'react'

import { AlertProvider } from 'ui/alert'
import { ErrorBoundaryPage } from 'ui/error-boundary'

import { IdleAlert } from 'components/idle-alert'
import { MainHeader } from 'components/main-header'
import { MainFooter } from 'components/main-footer'
import { EmailConfirmedAlert } from 'components/user-alerts'
import { PortalHead } from 'components/portal-head'
import { PortalProvider } from 'components/portal-provider'
import { PortalPagesProvider } from 'components/portal-pages-provider'
import { NotificationsProvider } from 'components/use-notifications'
import { ThemeSwitcher } from 'components/theme-swithcer'

import { useLocation, useRedirect } from 'router'
import { routes } from 'const/routes'

import { SignedIn } from './components/signed'
import { PageActivity } from './components/activity-page'
import { ClickActivity } from './components/activity-click'
import { getParamFromUrl } from './utils/url'
import { WCODE_KEY } from './const/labels'
import { wcodeStorage } from './api/storage'
import { WcodesHandler } from './components/wcodes'
import { UnauthorizedLogOut } from './components/unauthorized-log-out'

function getUuid() {
  return (
    'cimanager' +
    Math.round(new Date().valueOf() * Math.random() * Math.floor(Math.random() * 123456789))
      .toString(36)
      .slice(0, 16)
  )
}
const addScriptToBodyForCaliber = () => {
  const isCaliber = location.origin.includes('caliber')
  const isProduction = process.env.COMPOSE_PROJECT_NAME === '1776ing_ui_prod'

  if (!isCaliber || !isProduction) {
    return
  }
  const id = getUuid()
  const oldIframe = document.getElementById(id)
  if (oldIframe) {
    return
  }
  const root = document.getElementById('root')
  const noScript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-M4T6MMMW'
  iframe.height = '0'
  iframe.width = '0'
  iframe.style = 'display:none;visibility:hidden'
  iframe.id = id
  noScript.append(iframe)
  root.insertAdjacentElement('beforebegin', noScript)
}

const elementObserver = (name, cb) => {
  // set up the mutation observer
  const observer = new MutationObserver((mutations, me) => {
    let onlyIconsChanged = true

    // Icons open/close shouldn't affect on scroll
    for (let mutation of mutations) {
      if (mutation.type === 'childList') {
        const addedNodes = Array.from(mutation?.addedNodes ?? [])
        const removedNodes = Array.from(mutation?.removedNodes ?? [])
        if (addedNodes.length && addedNodes?.some((node) => !(node instanceof SVGElement))) {
          onlyIconsChanged = false
        }

        if (removedNodes.length && removedNodes?.some((node) => !(node instanceof SVGElement))) {
          onlyIconsChanged = false
        }
      }
    }

    if (onlyIconsChanged) {
      return
    }
    // `mutations` is an array of mutations that occurred
    // `me` is the MutationObserver instance
    const element = document.getElementsByName(name)?.[0] || document.getElementById(name)
    if (element) {
      cb(element)
      me.disconnect() // stop observing
      return
    }
  })

  // start observing
  observer.observe(document, {
    childList: true,
    subtree: true,
  })
  return observer
}

const scrollIntoView = (element) =>
  setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 1)

const themeSwitcherOn = process.env.THEME_SWITCHER_ON

export const App = ({ children }) => {
  const { pathname, search, hash } = useLocation()
  const redirect = useRedirect()
  const redirectToErrorPage = () => redirect(routes.errorPage)

  useEffect(() => {
    const name = decodeURI(hash?.substring(1))
    if (!name) {
      window.scrollTo({
        top: 0,
        // behavior: 'smooth'
      })
    }
    const wcode = getParamFromUrl(search, WCODE_KEY)
    if (wcode) {
      wcodeStorage.value = wcode
    }
    const observer = name ? elementObserver(name, scrollIntoView) : null
    return () => observer?.disconnect()
  }, [hash, pathname, search])

  useEffect(() => {
    addScriptToBodyForCaliber()
  }, [])

  return (
    <PortalProvider>
      <PortalPagesProvider>
        <AlertProvider>
          <NotificationsProvider>
            <PortalHead />
            <EmailConfirmedAlert />
            <UnauthorizedLogOut />
            <IdleAlert />
            <MainHeader />
            {themeSwitcherOn && <ThemeSwitcher />}
            <SignedIn>
              <ClickActivity />
              <PageActivity />
              <WcodesHandler />
            </SignedIn>
            <main>
              <ErrorBoundaryPage onError={redirectToErrorPage}>{children}</ErrorBoundaryPage>
            </main>
            <MainFooter />
          </NotificationsProvider>
        </AlertProvider>
      </PortalPagesProvider>
    </PortalProvider>
  )
}
