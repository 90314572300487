import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import './file-dropdown-input.css'
import { BaseInput } from 'ui/form/base-input'

import { cn } from 'utils'

const MAX_FILE_SIZE = 52428800

export class FileDropdownInput extends BaseInput {
  static propTypes = {
    accept: PropTypes.object,
    disabled: PropTypes.bool,
    maxSize: PropTypes.number,
    name: PropTypes.string.isRequired,
    validation: PropTypes.func,
    valueToFilename: PropTypes.func.isRequired,
  }
  static defaultProps = {
    valueToFilename: (f) => f,
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: MAX_FILE_SIZE,
  }

  constructor() {
    super()

    this.state = {
      files: [],
      value: null,
      filename: this.props?.valueToFilename(this.props?.defaultValue),
      validationMessage: null,
    }
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      this.setState({ value: acceptedFiles[0] })
    }
  }

  setElement = (element) => {
    this.element = element?.current
  }

  handleChange = async (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }

    const { valueToFilename } = this.props
    this.setState({
      value: file,
      valid: true,
      touched: true,
      filename: valueToFilename(file),
    })
    await this.broadcastUpdates({
      value: file,
      validationMessage: null,
      valid: true,
      touched: true,
    })
  }

  handleBrowseFile = () => {
    this.element && this.element.click()
  }

  reset() {
    const { defaultValue, valueToFilename } = this.props
    const value = defaultValue || null
    this.setState({ filename: valueToFilename(value), value }, () => {
      this.broadcastUpdates({ value, touched: false })
    })
  }

  /**
   * @override
   */
  setValue(value) {
    this.setState(
      {
        value,
        fileName: this.props.valueToFilename(value),
      },
      () => {
        this.broadcastUpdates({ value: value || null })
      },
    )
  }
  /**
   * @override
   */
  getValue() {
    return this.state.value
  }

  isValid() {
    if (this.props.required && !this.getValue()) return false
    return !this.state.validationMessage
  }

  getValidationMessage() {
    if (!this.willValidate() || this.isValid()) return null
    const { required } = this.props
    const value = this.getValue()
    if (!value) {
      return required ? 'The field is required' : null
    }
    return null
  }
  willValidate() {
    return this.element?.willValidate
  }

  focus() {
    this.element?.focus?.()
  }

  componentDidMount() {
    this.broadcastUpdates({ value: this.props?.defaultValue })
  }

  render() {
    const {
      validation,
      valueToFilename,
      disabled,
      validationMessage,
      hideValidationMessage,
      accept,
      maxSize,
      ...props
    } = this.props
    return (
      <Dropzone onDrop={this.onDrop} accept={accept} maxSize={maxSize}>
        {({
          getRootProps,
          getInputProps,
          isFocused,
          isDragAccept,
          isDragReject,
          fileRejections,
        }) => {
          const { ref } = getInputProps()
          this.setElement(ref)
          return (
            <>
              <div
                aria-required
                {...getRootProps()}
                className={cn(
                  'baseStyle',
                  isFocused && 'focusedStyle',
                  isDragAccept && 'acceptStyle',
                  isDragReject && 'rejectStyle',
                )}
              >
                <input
                  defaultValue={this.state.value}
                  {...props}
                  {...getInputProps()}
                  type="file"
                />
                <p>Drag and drop files here or click to browse.</p>
                <p>(Only *.pdf files will be accepted)</p>
                {this.state.value && (
                  <ul>
                    <li key={this.state.value?.name}>{this.state.value?.name}</li>
                  </ul>
                )}
              </div>
              {fileRejections?.length ? (
                <ul className="file-upload-errors">
                  {fileRejections.map(({ errors }) =>
                    errors.map((e) => <li key={e.code}>{e.message}</li>),
                  )}
                </ul>
              ) : null}
            </>
          )
        }}
      </Dropzone>
    )
  }
}
