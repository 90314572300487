import React, { ComponentProps, FC } from 'react'

import { RouterNavLink, LinkProps, RoutePath } from 'router'
import { cn } from 'utils'

const blur = (event) => event.target.blur()

interface Link extends LinkProps {
  disable?: boolean
}

interface Span extends ComponentProps<'span'> {
  disable?: boolean
  to: RoutePath
  query?: Record<string, string | number>
}

export type NavLink = Link | Span

const isSpan = (props: NavLink): props is Span => {
  return props.disable
}

/**
 * It adds a class to element when url matches current location
 */
export const NavLink: FC<NavLink> = (props) => {
  if (isSpan(props)) {
    return <span {...props} className={cn('link nav-link disable', props.className)} />
  }

  return (
    <RouterNavLink
      {...props}
      to={props.to}
      query={props.query}
      className={cn('link nav-link', props.className)}
      onClick={blur}
    />
  )
}
