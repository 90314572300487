/* eslint-disable react-hooks/exhaustive-deps */
import './styles.css'
import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Dialog } from 'ui/dialog'
import { Button } from 'ui/button'

import { SignedIn } from 'components/signed'
import { useIdle } from 'components/use-idle'

import { api } from 'api'
import { storage } from 'utils/storage'
import { SESSION_EXPIRED_TIME_KEY } from 'const/types'

const SECONDS_BEFORE_LOGOUT = 60

const useTimeoutEffect = (cb, deps = [], delay = 1) => {
  useEffect(() => {
    const timer = setTimeout(cb, delay)
    return () => {
      clearTimeout(timer)
    }
  }, deps)
}

const IdleDialog = () => {
  const logoOutTime = useRef(null)
  const getSecondsLeft = () => Math.ceil((logoOutTime.current - Date.now()) / 1000)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [secondsLeft, setSecondsLeft] = useState(getSecondsLeft())
  const [expired, longExpired, reload] = useIdle()

  const updateSecondsLeft = useCallback(() => {
    setSecondsLeft(getSecondsLeft())
  }, [])

  const handleClick = () => {
    reload()
    setIsDialogOpen(false)
    logoOutTime.current = null
  }

  useEffect(() => {
    if (longExpired) {
      storage.write(SESSION_EXPIRED_TIME_KEY)
      api.auth.logout()
    }
    if (expired && !longExpired) {
      logoOutTime.current = Date.now() + SECONDS_BEFORE_LOGOUT * 1000
      setSecondsLeft(getSecondsLeft())
      setIsDialogOpen(true)
    }
  }, [expired])

  // update with time
  useTimeoutEffect(
    () => {
      const secondsLeft = getSecondsLeft()
      if (logoOutTime.current && secondsLeft <= 0) {
        storage.write(SESSION_EXPIRED_TIME_KEY)
        api.auth.logout()
      } else {
        updateSecondsLeft()
      }
    },
    [secondsLeft],
    1000,
  )

  return isDialogOpen ? (
    <Dialog title="Warning" className="idle-alert-dialog" onCloseButtonOnly={handleClick}>
      <div>
        <p>Are you still here? Session will end in</p>
        <div>
          <span>{secondsLeft}</span>
        </div>
        <p>seconds</p>
      </div>
      <footer>
        <Button theme="secondary" onClick={api.auth.logout}>
          Logout
        </Button>
        <Button theme="primary" onClick={handleClick}>
          I&apos;m still here
        </Button>
      </footer>
    </Dialog>
  ) : (
    false
  )
}

export const IdleAlert = () => {
  return (
    <SignedIn>
      <IdleDialog />
    </SignedIn>
  )
}
