import React, { FC, useContext, useReducer } from 'react'

import { AlertsContainer } from './alert-container'

const enum DispatchTypes {
  ADD = 'add',
  REMOVE = 'remove',
  CLEAR_ALL = 'clear-errors',
}

export interface AlertsContext {
  error: (string) => void
  warning: (string) => void
  success: (string) => void
  close: (string) => void
  clear: () => void
}

// const ADD = 'add'
// const REMOVE = 'remove'
// const CLEAR_ALL = 'clear-errors'
// const CLEAR_ERRORS = 'clear-errors'

const INITIAL_STATE = []
/**
 * @param {Message[]} state
 * @param {AlertAction} action
 * @returns {Message[]}
 */
const reducer = (state = INITIAL_STATE, { type, ...props }) => {
  switch (type) {
    case DispatchTypes.ADD:
      return [...state, props]
    case DispatchTypes.REMOVE:
      return state.filter((item) => item.key !== props.key)
    case DispatchTypes.CLEAR_ALL:
      return INITIAL_STATE
    default:
      return state
  }
}

/**
 * @param {function(AlertAction)} dispatch
 * @returns {Alerts}
 */
const createAlertsDispatcher = (dispatch) => {
  const showMessage = ({
    timeout = 7000,
    key = Date.now().toString(),
    persist = false,
    ...props
  }) => {
    if (!props.message) return
    dispatch({ ...props, persist, key, type: DispatchTypes.ADD })
    const removeMessage = () => dispatch({ ...props, key, type: DispatchTypes.REMOVE })
    timeout && !persist && setTimeout(removeMessage, timeout)
    return removeMessage
  }

  const parseMessageArg = (message) => (typeof message === 'string' ? { message } : message || {})

  return {
    error: (message) => showMessage({ ...parseMessageArg(message), theme: 'error' }),
    warning: (message) => showMessage({ ...parseMessageArg(message), theme: 'warning' }),
    success: (message) => showMessage({ ...parseMessageArg(message), theme: 'success' }),
    close: (key) => dispatch({ key: key || key.key, type: DispatchTypes.REMOVE }),
    clear() {
      dispatch({ type: DispatchTypes.CLEAR_ALL })
    },
  }
}

const AlertApiContext = React.createContext<AlertsContext | null>(null)

/**
 * @returns {Alerts}
 */
export const useAlert = (): AlertsContext => {
  return useContext(AlertApiContext)
}

export const AlertProvider: FC = ({ children }) => {
  const [messages, dispatch] = useReducer(reducer, INITIAL_STATE)
  const alertsApi = createAlertsDispatcher(dispatch)

  return (
    <>
      <AlertsContainer messages={messages} alertsApi={alertsApi} />
      <AlertApiContext.Provider value={alertsApi}>{children}</AlertApiContext.Provider>
    </>
  )
}

/**
 * @typedef {Object} Message
 * @prop {('error'|'success'|'warning')} theme
 * @prop {string|(import('react').Component)} [message]
 * @prop {boolean} [persist]
 */
/**
 * @typedef {Object} AlertAction
 * @prop {('add-error'|'add-success'|'add-warning'|'remove'|'clear-errors')} type
 * @prop {string|(import('react').Component)} [message]
 * @prop {boolean} [persist]
 */
/**
 * @typedef {Object} Alerts
 * @property {function(String message,Boolean persist,Number timeout):void} error
 * @property {function(String message,Boolean persist,Number timeout):void} success
 * @property {function(String message,Boolean persist,Number timeout):void} warning
 * @property {function(String message):void} close
 * @property {function():void} clear
 */
