import './textarea.css'
import React from 'react'

import { TextArea } from 'ui/form'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'TextArea'

  getWrapperClassName() {
    return super.getWrapperClassName('textarea')
  }

  renderField({ ...props }) {
    return <TextArea {...props} />
  }
}

export const TextareaField = withFormDefaultValues(Field)
