import PropTypes from 'prop-types'

import { useFormValues, useFormProps, useField } from './context'
import { Form } from './form'
// helpers -----------------------------------------
import { IsModified } from './helpers/is-modified'
import { IsValid } from './helpers/is-valid'
import { IsInvalid } from './helpers/is-invalid'
import { IsFieldValue } from './helpers/is-field-value'
import { AddMatchFieldValidation } from './helpers/add-match-field-validation'
import { AddLesserThanFieldValidation } from './helpers/add-lesser-than-field-validation'
export { FormControl } from './helpers/form-control'
export { IsFieldModified } from './helpers/is-field-modified'
export {
  IsModified,
  IsValid,
  IsInvalid,
  IsFieldValue,
  AddMatchFieldValidation,
  AddLesserThanFieldValidation,
}
// inputs -------------------------------------------
export { NumberInput } from './number-input'
export { DateInput } from './date-input'
export { TextInput } from './text-input'
export { HiddenInput } from './hidden-input'
export { TextArea } from './textarea'
export { Select } from './select'
export { RadioInputGroup } from './inputs/radio-input-group'
export { CheckboxInputGroup } from './inputs/checkbox-input-group'
// buttons -------------------------------------------
import { SubmitButton, SaveButton, ResetButton } from './form-buttons'
export { SubmitButton, SaveButton, ResetButton }

export { Form, useFormValues, useFormProps, useField }

export const FieldValue = ({ name }) => {
  const { value } = useField(name)
  return value === undefined ? null : String(value)
}
FieldValue.propTypes = {
  name: PropTypes.string.isRequired,
}

// deprecated api - for compatibility -------------------------------------------

export * from './index-deprecated'
