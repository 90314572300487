import React from 'react'
import PropTypes from 'prop-types'
import CleaveInput from 'cleave.js/react'

import { cn } from 'utils'

import { BaseInput } from './base-input'

export class FormattedInput extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    ...CleaveInput.propTypes,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    validation: PropTypes.func,
  }
  static defaultProps = {
    type: 'text',
    autoComplete: 'off',
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    // console.log('setElement', this.props.name)
    this.element = element
  }

  cleaveInstance = null
  setCleaveInstance = (instance) => {
    // console.log('setCleaveInstance', this.props.name)

    // https://github.com/nosir/cleave.js/issues/601 fix
    instance.lastInputValue = this.props.defaultValue || ''

    this.cleaveInstance = instance
    this.broadcastUpdates()
  }

  onBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange = (event) => {
    const { rawValue, value } = event.target

    // let the parent form knows about changes
    if (this.getNormalizedValue(value) === null) {
      this.broadcastUpdates({ value: null })
    } else {
      this.broadcastUpdates({ value: rawValue })
    }
  }

  reset() {
    this.setState({ touched: false }, () => {
      this.setValue(this.getDefaultValue())
    })
  }

  setValue(value = null) {
    if (!this.cleaveInstance) return
    const normalValue = this.getNormalizedValue(value)
    // if (normalValue === null || normalValue === undefined) {
    //   this.element.value = ''
    // } else {
    this.cleaveInstance.setRawValue(normalValue)
    // }
    const validationMessage = this.getValidationMessage(normalValue)
    this.broadcastUpdates({ value: normalValue, validationMessage, valid: !validationMessage })
  }

  /**
   * @override
   */
  getValue() {
    return this.getNormalizedValue(
      this.cleaveInstance ? this.cleaveInstance.getRawValue() : undefined,
    )
  }

  getNormalizedValue(value) {
    if (value === undefined || value === null) {
      return null
    }
    if (typeof value === 'string' && String(value).trim() === '') {
      return null
    }

    if (this.props.options.numeral) {
      return typeof value === 'number' ? value : parseFloat(value.replace(/[^0-9.]/g, ''), 10)
    }

    return value
  }

  getDefaultValue(defaultValue = this.props.defaultValue) {
    return this.getNormalizedValue(defaultValue)
  }

  getValidationMessage(value = this.getValue()) {
    if (!this.willValidate()) return null

    const { required, validation } = this.props
    if (value === null && required) {
      return 'The field is required'
    }
    return (validation && validation(value)) || null
  }

  broadcastUpdates({
    value = this.getValue(),
    defaultValue = this.getDefaultValue(),
    ...props
  } = {}) {
    return super.broadcastUpdates({
      ...props,
      value: this.getNormalizedValue(value),
      defaultValue: this.getNormalizedValue(defaultValue),
    })
  }

  validate() {
    this.broadcastUpdates({ touched: true })
  }

  focus() {
    this.element?.focus()
  }

  render() {
    const { validation, validationMessage, defaultValue, value, className, ...props } = this.props

    return (
      <CleaveInput
        data-lpignore="true"
        {...props}
        className={cn('cleave', className)}
        htmlRef={this.setElement}
        onInit={this.setCleaveInstance}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={this.getDefaultValue()}
      />
    )
  }
}
