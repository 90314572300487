import React from 'react'
import T from 'prop-types'

import { AutoComplete } from 'const/autocomplete'

import { BaseHtmlValidationInput } from './base-html-validation-input'

export class TextArea extends BaseHtmlValidationInput {
  static propTypes = {
    ...BaseHtmlValidationInput.propTypes,
    autoComplete: AutoComplete,
    disabled: T.bool,
    name: T.string.isRequired,
    onBlur: T.func,

    validationMessages: T.exact({
      valueMissing: T.string,
      typeMismatch: T.string,
      patternMismatch: T.string,
      tooLong: T.string,
      tooShort: T.string,
    }),
  }
  static defaultProps = {
    validationMessages: {},
  }

  handleBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = (event) => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })

    // let the parent form knows about changes
    this.broadcastUpdates()
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.resetCustomValidity()
    this.broadcastUpdates()
  }

  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    const value = String(this.element.value).trim()
    return value === '' ? null : value
  }

  render() {
    const { validation, validationMessages, validationMessage, ...props } = this.props
    return (
      <textarea
        data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}
