import './page-header.css'
import React, { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

import { Breadcrumbs } from '../breadcrumbs'

export interface IPageHeader {
  className?: string
  hideBreadcrumbs?: boolean
  title?: string
  center?: boolean
  hero?: boolean
  theme?: 'default' | 'wide'
  children?: ReactNode
}

export const PageHeader: FC<IPageHeader> = ({
  className,
  hideBreadcrumbs,
  title,
  center,
  hero,
  theme,
  children,
  ...props
}) => (
  <header
    {...props}
    className={cn('page-header', center && 'center', hero && 'hero', theme, className)}
  >
    <div className="title-wrapper">
      {!hideBreadcrumbs && <Breadcrumbs />}
      {title && <h1>{title}</h1>}
    </div>
    {children}
  </header>
)

// PageHeader.propTypes = {
//   center: PropTypes.bool,
//   hero: PropTypes.bool,
//   hideBreadcrumbs: PropTypes.bool,
//   theme: PropTypes.oneOf(['default', 'wide']),
//   title: PropTypes.string,
// }
PageHeader.defaultProps = {
  center: false,
  hero: false,
  theme: 'default',
}
