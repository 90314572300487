export const SEARCH_PARAM = 'search'
export const LIMIT_PARAM = 'limit'

export const DEFAULT_LIMIT = 10
export const DEFAULT_PAGE = 1
export const OFFSET_PARAM = 'page'

export const ORDER_BY_PARAM = 'sort'
export const DEFAULT_ORDER = 'desc'

export const EXTRA_ITEMS_AMOUNT = 15
