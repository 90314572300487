import './styles.css'
import React, { useState } from 'react'

import { Link } from 'ui/link'

import { routes } from 'const/routes'
import Icon from 'img/1776-logo.gif'
import PoweredBy from 'icons/powered-by.svg'

export const Logo = () => {
  const [logoUrl, setLogoUrl] = useState('/app/logo')
  const clear = () => setLogoUrl(null)

  return (
    <Link className="main-logo" to={routes.landing} title="Logo">
      {logoUrl ? (
        <img src={logoUrl} alt="logo" onError={clear} />
      ) : (
        <img src={Icon} alt="logo" onError={clear} />
      )}
    </Link>
  )
}

export const PoweredByLogo = () => {
  const [logoUrl, setLogoUrl] = useState('/app/powered-logo')
  const clear = () => setLogoUrl(null)

  return logoUrl ? (
    <img src={logoUrl} alt="powered-by-logo" onError={clear} className="powered-by" />
  ) : (
    <PoweredBy className="powered-by" />
  )
}
