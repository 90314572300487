import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { FC, ReactElement } from 'react'

import customMedia from 'theme-custom-media.json'

const MEDIA = customMedia['custom-media']

type TQuery = keyof typeof MEDIA

interface IMedia {
  query: TQuery
  children: ReactElement
}

export const Media: FC<IMedia> = ({ query, children }) => {
  const match = useMediaQuery({ query: MEDIA[query] })

  return MEDIA[query] && match ? children : null
}

// Media.propTypes = {
//   query: PropTypes.oneOf(Object.keys(MEDIA)).isRequired,
// }
