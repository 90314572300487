import React from 'react'
import T from 'prop-types'

import { AutoComplete } from 'const/autocomplete'

import { BaseHtmlValidationInput } from './base-html-validation-input'

export class TextInput extends BaseHtmlValidationInput {
  static propTypes = {
    ...BaseHtmlValidationInput.propTypes,
    autoComplete: AutoComplete,
    disabled: T.bool,
    name: T.string.isRequired,
    onBlur: T.func,

    type: T.oneOf(['text', 'email', 'tel', 'search', 'password', 'url']),
    validationMessages: T.exact({
      valueMissing: T.string,
      typeMismatch: T.string,
      patternMismatch: T.string,
      tooLong: T.string,
      tooShort: T.string,
    }),
  }
  static defaultProps = {
    type: 'text',
    validationMessages: {},
  }

  handleBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = (event) => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })

    // let the parent form knows about changes
    this.broadcastUpdates()
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid() {
    //! with 'minLength' ValidityState.tooShort returns false-positive validity in Chrome
    if (isInputValueTooShort(this.props.minLength, this.element)) {
      return false
    }
    return super.isValid()
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.resetCustomValidity()
    this.broadcastUpdates()
  }

  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    const value = ['confirmPassword', 'password'].includes(this.props.name)
      ? this.element.value
      : String(this.element.value).trim()
    return String(value).trim() === '' ? null : value
  }

  checkCustomValidity(value = this.getValue()) {
    return (
      super.checkCustomValidity(value) ||
      (isInputValueTooShort(this.props.minLength, this.element) && 'Too short') ||
      ''
    )
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity()
  }

  render() {
    const { validation, validationMessages, validationMessage, ...props } = this.props
    return (
      <input
        data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}

const VALIDITY_PROPS = ['valueMissing', 'typeMismatch', 'patternMismatch', 'tooLong', 'tooShort']

function isInputValueTooShort(minLength, element) {
  return Number.isFinite(+minLength) && element.value.length < +minLength
}
