/* eslint-disable react/prop-types */
import React from 'react'

import { IsFieldValue } from './helpers/is-field-value'
import { IsModified } from './helpers/is-modified'
import { IsValid } from './helpers/is-valid'
import { IsInvalid } from './helpers/is-invalid'

/** @deprecated */
export const FieldValueIs = ({ value, ...props }) => <IsFieldValue {...props} equals={value} />
/** @deprecated */
export const FieldValueIsNot = ({ value, ...props }) => (
  <IsFieldValue {...props} not equals={value} />
)
/** @deprecated */
export const FieldValueOneOf = ({ valuesList, ...props }) => (
  <IsFieldValue {...props} oneOf={valuesList} />
)
/** @deprecated */
export const Filled = (props) => <IsFieldValue {...props} not blank />
/** @deprecated */
export const NotFilled = (props) => <IsFieldValue {...props} blank />
/** @deprecated */
export const HasChanges = IsModified
/** @deprecated */
export const Valid = IsValid
/** @deprecated */
export const Invalid = IsInvalid
