import React, { useContext, useEffect, useState } from 'react'

import { useAlert } from 'ui/alert'

import { useLoaderState } from 'components/use-loader/loader-state'
import { useUser } from 'components/user'

import { api } from 'api'

export const NotificationsContext = React.createContext()

const INTERVAL = 1000 * 60 * 15

export const NotificationsProvider = ({ children }) => {
  const [{ pending, error }, load] = useLoaderState()
  const user = useUser()
  const alert = useAlert()
  const [list, setList] = useState(null)

  const getList = async () => {
    if (user) {
      try {
        await load(api.notifications.list).then(({ items }) => {
          setList(items)
        })
      } catch ({ error, errors }) {
        const message = errors ? Object.values(errors).join(', ') : error
        alert.error({ message: message })
        setList(null)
      }
    } else {
      setList(null)
    }
  }

  useEffect(() => {
    getList()
    const interval = setInterval(() => {
      getList()
    }, INTERVAL)
    return () => clearInterval(interval)
  }, [user]) //eslint-disable-line

  return (
    <NotificationsContext.Provider value={{ notifications: [list, setList], error, pending }}>
      {children}
    </NotificationsContext.Provider>
  )
}

NotificationsProvider.propTypes = {}

export const useNotifications = () => {
  return useContext(NotificationsContext)
}
