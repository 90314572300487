/* eslint-disable import/no-unassigned-import */
import 'core-js/stable/array/includes'
import 'core-js/stable/array/find'
import 'core-js/stable/array/from'
import 'core-js/stable/array/flat'
import 'core-js/stable/object/assign'
import 'core-js/stable/object/values'
import 'core-js/stable/object/entries'
import 'core-js/stable/object/from-entries'
import 'core-js/stable/symbol'
import 'core-js/stable/set'
import 'core-js/stable/promise'
import 'core-js/stable/url-search-params'
import 'whatwg-fetch'
