import { useCallback, useEffect, useRef, useState } from 'react'

import { SESSION_EXPIRED_TIME_KEY } from 'const/types'
import { storage } from 'utils/storage'
import { userStorage } from 'api/storage'

const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress']

const defaultTimeout = 30 * 60 // 30 minutes
const oneHour = 60 * 60 * 1000 // 1 hour

export const useIdle = (timeout = defaultTimeout): [boolean, boolean, () => void, () => void] => {
  const [isTracking, setIsTracking] = useState(true)
  const [isExpired, setIsExpired] = useState(false)
  const [isLongExpired, setIsLongExpired] = useState(false)
  const timeoutTracker = useRef(null)

  const updateExpiredTime = useCallback(() => {
    const expiredTime = storage.read(SESSION_EXPIRED_TIME_KEY)
    if (!expiredTime) {
      return
    }
    const expiredTimeParsed = parseInt(storage.read(SESSION_EXPIRED_TIME_KEY) || '0', 10)
    const expired = expiredTimeParsed < Date.now()

    if (!expired) {
      storage.write(SESSION_EXPIRED_TIME_KEY, Date.now() + timeout * 1000)
    }
  }, [timeout])

  const tracker = useCallback(() => {
    events.forEach((event) => {
      window.addEventListener(event, updateExpiredTime)
    })
  }, [updateExpiredTime])

  const cleanUp = useCallback(() => {
    storage.write(SESSION_EXPIRED_TIME_KEY)
    clearInterval(timeoutTracker.current)
    events.forEach((event) => {
      window.removeEventListener(event, updateExpiredTime)
    })
  }, [updateExpiredTime])

  const expiredTracker = () => {
    timeoutTracker.current = setInterval(() => {
      const expiredTime = storage.read(SESSION_EXPIRED_TIME_KEY)

      if (!expiredTime) {
        storage.write(SESSION_EXPIRED_TIME_KEY, Date.now() + timeout * 1000)
      }

      const expiredTimeParsed = parseInt(storage.read(SESSION_EXPIRED_TIME_KEY) || '0', 10)

      const longTermExpired = Date.now() - expiredTimeParsed > oneHour
      const normalExpired = expiredTimeParsed < Date.now()
      if (longTermExpired) {
        setIsLongExpired(true)
        setIsExpired(true)
        setIsTracking(false)
        cleanUp()
      }

      if (normalExpired && !longTermExpired) {
        setIsExpired(true)
        setIsTracking(false)
        setIsLongExpired(false)
        cleanUp()
      }
    }, 1000)
  }

  useEffect(() => {
    if (isTracking) {
      tracker()
    }

    return () => {
      const user = userStorage.value
      if (!isTracking || !user) {
        timeoutTracker.current && clearInterval(timeoutTracker.current)
        cleanUp()
      }
    }
  }, [cleanUp, isTracking, tracker])

  useEffect(() => {
    if (isTracking) {
      expiredTracker()
    }
  }, [isTracking]) //eslint-disable-line

  const reloadTracking = () => {
    setIsTracking(true)
    setIsExpired(false)
    setIsLongExpired(false)
  }

  return [isExpired, isLongExpired, reloadTracking, cleanUp]
}
