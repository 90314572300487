import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useUser } from 'components/user'
import { AccessDenied } from 'components/dashboard/access-denied'

import { Redirect, useLocation } from 'router'
import { authStorage } from 'api/storage'
import { UserRole, RoutePath, UserType } from 'const/types'
import { routes } from 'const/routes'
const matchExcluded = (currentUserRole, excludedRole) => {
  if (!excludedRole) return false

  const excludedRoles =
    excludedRole && Array.isArray(excludedRole) ? excludedRole : [excludedRole].filter(Boolean)

  if (excludedRoles?.length) {
    const currentRoles = Array.isArray(currentUserRole) ? currentUserRole : [currentUserRole]
    return currentRoles.some((role) => excludedRoles.includes(role))
  }
  return false
}

const matchAny = (currentUserRole, desiredRole) => {
  const currentRoles = Array.isArray(currentUserRole) ? currentUserRole : [currentUserRole]
  const desiredRoles = Array.isArray(desiredRole) ? desiredRole : [desiredRole]
  return desiredRoles.some((role) => currentRoles.includes(role))
}

export const matchUser = (user, { type, role, excludedRole }) => {
  if (role && !matchAny(user.roles, role)) return false
  if (excludedRole && matchExcluded(user.roles, excludedRole)) return false
  if (type && !matchAny(user.type, type)) return false
  return true
}

export const SignedIn = ({
  children,
  userRole,
  userType,
  excludeUserRole,
  redirectTo,
  returnBack,
  isPage,
}) => {
  const { pathname } = useLocation()
  const user = useUser()
  const [isAuthenticated, setAuthenticated] = useState(authStorage.isAuthenticated())
  useEffect(() => authStorage.addListener(setAuthenticated), [])
  if (
    isAuthenticated &&
    user &&
    matchUser(user, { role: userRole, type: userType, excludedRole: excludeUserRole })
  ) {
    return children
  }
  if (
    isAuthenticated &&
    user &&
    !matchUser(user, { role: userRole, type: userType, excludedRole: excludeUserRole }) &&
    isPage
  ) {
    return <AccessDenied />
  }
  return redirectTo ? <Redirect to={redirectTo} next={returnBack && pathname} /> : false
}

SignedIn.propTypes = {
  excludeUserRole: PropTypes.oneOfType([UserRole, PropTypes.arrayOf(UserRole)]),
  isPage: PropTypes.bool,
  redirectTo: RoutePath,
  returnBack: PropTypes.bool,
  userRole: PropTypes.oneOfType([UserRole, PropTypes.arrayOf(UserRole)]),
  userType: PropTypes.oneOfType([UserType, PropTypes.arrayOf(UserType)]),
}

export const SignedOut = ({ children, redirectTo, noSubscription = false }) => {
  const [isAuthenticated, setAuthenticated] = useState(authStorage.isAuthenticated())
  const account = useUser()
  useEffect(() => {
    if (!noSubscription) {
      authStorage.addListener(setAuthenticated)
    }
  }, [noSubscription])

  return !(account && isAuthenticated) ? (
    children || false
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    false
  )
}

SignedOut.propTypes = {
  noSubscription: PropTypes.bool,
  redirectTo: RoutePath,
}

export const ForceSignedIn = (props) => {
  return <SignedIn {...props} redirectTo={routes.login} returnBack />
}

export const IncludesRole = ({ user, userRole, userType, excludeUserRole, children }) =>
  matchUser(user, { role: userRole, type: userType, excludedRole: excludeUserRole })
    ? children
    : false
IncludesRole.propTypes = {
  excludeUserRole: PropTypes.oneOfType([UserRole, PropTypes.arrayOf(UserRole)]),
  userRole: PropTypes.oneOfType([UserRole, PropTypes.arrayOf(UserRole)]),
  userType: PropTypes.oneOfType([UserType, PropTypes.arrayOf(UserType)]),
}

export const WithDashboard = ({ children, redirectTo }) => {
  const user = useUser()
  return user?.dashboard ? children || null : redirectTo ? <Redirect to={redirectTo} /> : null
}

WithDashboard.propTypes = {
  redirectTo: PropTypes.string,
}
