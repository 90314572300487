import React from 'react'
import PropTypes from 'prop-types'

import { SecretField } from 'ui/field/secret'

import { cn } from 'utils'

const PasswordField = React.forwardRef(({ className, ...props }, ref) => (
  <SecretField {...props} ref={ref} className={cn('password', className)} />
))

PasswordField.displayName = 'PasswordField'

PasswordField.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

PasswordField.defaultProps = {
  autoComplete: 'password',
  label: 'Password',
}

export { PasswordField }
