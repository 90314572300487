import React, { FC } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

type Props = Pick<
  Intl.DateTimeFormatOptions,
  'year' | 'month' | 'day' | 'hour' | 'minute' | 'second'
>

export interface ILocalDate extends Props {
  value: string
  utc?: boolean
  className?: string
}

export const LocalDate: FC<ILocalDate> = ({
  value,
  year,
  month,
  day,
  hour,
  minute,
  second,
  utc,
  className,
}) => {
  const date = new Date(value)
  const formattedDate = date.toLocaleDateString('en-US', {
    year,
    month,
    day,
    hour,
    minute,
    second,
    ...(utc && { timeZone: 'UTC' }),
  })
  return <span className={cn('local-date', className)}>{formattedDate}</span>
}

// LocalDate.propTypes = {
//   day: PropTypes.oneOf(['numeric', '2-digit']),
//   hour: PropTypes.oneOf(['numeric', '2-digit']),
//   minute: PropTypes.oneOf(['numeric', '2-digit']),
//   month: PropTypes.oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
//   second: PropTypes.oneOf(['numeric', '2-digit']),
//   value: PropTypes.string,
//   year: PropTypes.oneOf(['numeric', '2-digit']),
// }

LocalDate.defaultProps = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
