import './radio-button-group.css'
import React from 'react'

import { RadioInputGroup } from 'ui/form'

import { cn } from 'utils'

import { BaseField, withFormDefaultValues } from '../base-field'
import { LabelText } from '../label'
import { RadioOption } from './option/radio-option'

export class Field extends BaseField {
  static displayName = 'RadioButtonGroup'

  renderField(props) {
    return (
      <RadioInputGroup {...props} optionComponent={RadioOption}>
        {this.props.children}
      </RadioInputGroup>
    )
  }

  getInputProps() {
    const { horizontal, ...props } = super.getInputProps()
    return props
  }

  render() {
    const {
      label,
      hideValidationMessage,
      validationMessage,
      className,
      children,
      horizontal,
      vertical,
      touched,
      ...props
    } = this.props

    const editable = !props.disabled && !props.readOnly
    return (
      <fieldset
        name={props.name}
        className={this.getWrapperClassName('radio-button-group', horizontal && 'horizontal')}
      >
        {this.renderDescription()}
        <LabelText as="label" className={cn(editable && props.required && 'required')}>
          {label}
        </LabelText>
        {this.renderField(this.getInputProps())}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </fieldset>
    )
  }
}

export const RadioButtonGroup = withFormDefaultValues(Field)
