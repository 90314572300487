import PropTypes from 'prop-types'

import { Field as TextField } from 'ui/field/text'

import { withFormDefaultValues } from '../base-field'

class Field extends TextField {
  displayName = 'EmailField'

  getWrapperClassName() {
    return super.getWrapperClassName('email')
  }

  getInputProps() {
    const { autoCompletePrefix, ...props } = super.getInputProps()
    return {
      ...props,
      type: 'email',
      autoComplete: [autoCompletePrefix, 'email'].filter(Boolean).join(' '),
    }
  }
}

export const EmailField = withFormDefaultValues(Field)

EmailField.propTypes = {
  autoComplete: PropTypes.string,
  autoCompletePrefix: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

EmailField.defaultProps = {
  label: 'Email',
}
