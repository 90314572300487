import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'ui/button'
import { ButtonProps } from 'ui/button/button'

import { useFormProps } from './context'

interface FormButtonsProps extends ButtonProps {
  disableUnmodified?: boolean
  disableInvalid?: boolean
}

export const SubmitButton = React.forwardRef<HTMLButtonElement, FormButtonsProps>(
  ({ disabled, disableUnmodified, ...props }, ref) => {
    const { pending, modified } = useFormProps()

    // TODO: Add some global Defaults/Config - to set whether a button should be disabled for invalid form
    return (
      <Button
        {...props}
        type="submit"
        ref={ref}
        disabled={disabled || pending || (disableUnmodified && !modified)}
        pending={pending}
      />
    )
  },
)
SubmitButton.displayName = 'SubmitButton'

export const OptimisticSubmitButton = React.forwardRef<HTMLButtonElement, FormButtonsProps>(
  ({ disabled, disableUnmodified, ...props }, ref) => {
    const { pending, modified } = useFormProps()
    return (
      <Button
        {...props}
        type="submit"
        ref={ref}
        disabled={disabled || pending || (disableUnmodified && !modified)}
        pending={pending}
      />
    )
  },
)

OptimisticSubmitButton.displayName = 'OptimisticSubmitButton'

export const SaveButton = React.forwardRef<HTMLButtonElement, FormButtonsProps>(
  ({ disabled, disableInvalid, ...props }, ref) => {
    const { pending, modified, valid } = useFormProps()
    // TODO: Add some global Defaults/Config - to set whether a button should be disabled for invalid form

    return (
      <Button
        {...props}
        type="submit"
        disabled={!modified || disabled || pending || (disableInvalid && !valid)}
        pending={pending}
        ref={ref}
      />
    )
  },
)
SaveButton.displayName = 'SaveButton'

export const ResetButton = ({
  disabled,
  disableUnmodified,
  disableInvalid,
  ...props
}: FormButtonsProps): ReactElement => {
  const { pending, modified, valid } = useFormProps()
  return (
    <Button
      {...props}
      type="reset"
      disabled={
        (disableUnmodified && !modified) || disabled || pending || (disableInvalid && !valid)
      }
    />
  )
}

// SubmitButton.propTypes = {
//   disableUnmodified: PropTypes.bool,
//   disabled: PropTypes.bool,
// }
//
// OptimisticSubmitButton.propTypes = {
//   disableUnmodified: PropTypes.bool,
//   disabled: PropTypes.bool,
// }
//
// SaveButton.propTypes = {
//   disableUnmodified: PropTypes.bool,
//   disabled: PropTypes.bool,
// }
//
// ResetButton.propTypes = {
//   disableUnmodified: PropTypes.bool,
//   disabled: PropTypes.bool,
// }
