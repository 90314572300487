import './alert-message.css'
import React, { FC } from 'react'

import { IconButton } from 'ui/button'
import { Link } from 'ui/link'

import { cn } from 'utils'
import IconClose from 'icons/close.svg'
import { RoutePath } from 'router'

export type TAlertsApi = {
  close: (string) => void
}

const enum Theme {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export interface IAlertMessage {
  className: string
  messageKey?: string
  theme: Theme
  message: string
  alertsApi: TAlertsApi
  persist?: boolean
  link: {
    path: RoutePath
    text: string
    closeAlert?: boolean
  }
}

export const AlertMessage: FC<IAlertMessage> = ({
  className,
  messageKey,
  theme,
  message,
  alertsApi,
  persist,
  link,
}) => {
  const close = () => {
    alertsApi.close(messageKey)
  }
  return (
    <div className={cn('alert-message', !persist && 'closable', theme, className)}>
      <span>
        {message}{' '}
        {link && (
          <Link to={link.path} onClick={link.closeAlert ? close : undefined}>
            {link.text}
          </Link>
        )}
      </span>
      {!persist && (
        <IconButton onClick={close} title="Close Alert">
          <IconClose />
        </IconButton>
      )}
    </div>
  )
}

// AlertMessage.propTypes = {
//   alertsApi: PropTypes.shape({
//     close: PropTypes.func.isRequired,
//   }),
//   link: PropTypes.shape({
//     path: RoutePath.isRequired,
//     text: PropTypes.string.isRequired,
//     closeAlert: PropTypes.bool,
//   }),
//   message: PropTypes.node.isRequired,
//   messageKey: PropTypes.string,
//   persist: PropTypes.bool,
//   theme: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
// }
