export const routes = {
  landing: '/',

  // login
  login: '/login',
  // password
  recoveryPassword: '/recovery-password',
  recoveryPasswordDone: '/recovery-password/done',
  passwordReset: '/reset-password',
  clientConfirm: '/client-confirm',
  userInvite: '/invite',
  jointSign: '/joint-sign',
  associatedSign: '/associated-sign',
  issuerSign: '/issuer-sign',
  brokerDealerSign: '/broker-dealer-sign',
  customSign: '/custom-signature',
  redirectAfterSignIn: '/redirect-sign-in',

  // register
  emailConfirmation: '/register/confirm',
  registerExtraPage: '/register/extra',
  register: '/register/public', // - default register path (to use in links)
  registerByType: '/register/:type?', // - common register route
  survey: '/survey',
  registerThankYouPage: '/register-thank-you/:role?',

  // user account
  account: '/account',
  accountSecurity: '/account/security',
  accountSettings: '/account/profile',
  accountSurvey: '/account/survey',
  accountDocuments: '/account/documents/my',
  accountNotifications: '/account/notifications',
  accountRequests: '/account/requests',
  accountRequestsApproval: '/account/requests/:uuid',
  accountSharedDocuments: '/account/shared-documents',
  //licensee account
  accountLicensee: '/licensee',
  licenseeOfferings: '/licensee/offerings',
  // user-investor account
  accountInvestor: '/my',
  investorAddAdvisor: '/my/advisors/add',
  investorAdvisorsList: '/my/advisors/list',
  investorInvestments: '/my/investments/list',
  investorInvestment506cVerificationRequests:
    '/my/investments/list/:investmentUuid/verification-requests',
  investorInvestment506cVerificationRequestDetails:
    '/my/investments/list/:investmentUuid/verification-requests/:requestUuid/details',
  investorInvestmentDocuments: '/my/investment-documents',
  investorInvestmentHoldings: '/my/investment-holdings',
  investorOfferingUpdates: '/my/offering-updates',
  // investorInvestments: '/my/investments',
  investorProfiles: '/my/profiles',
  investorProfile: '/my/profiles/:uuid',
  investorCreateProfile: '/my/profiles/new',
  investorProfileInvestments: '/my/profiles/:uuid/investments',
  investorProfileInvestmentDocuments: '/my/profiles/:uuid/investment-documents',
  investorProfileInvestmentHoldings: '/my/profiles/:uuid/investment-holdings',
  investorProfileOfferingUpdates: '/my/profiles/:uuid/offering-updates',
  investorProfilePaymentMethod: '/my/profiles/:uuid/payment-method',
  investorProfileDetails: '/my/profiles/:uuid/details',
  investorProfileAssociatedPersons: '/my/profiles/:uuid/associated-persons',
  investorProfileAccreditation: '/my/profiles/:uuid/accreditation',
  investorProfilePersonalDocuments: '/my/profiles/:uuid/documents',
  // user-advisor
  accountAdvisor: '/clients',
  advisorClientsInvestmentsList: '/clients/clients-investments',
  advisorClientsInvestment506cVerificationRequests:
    '/clients/clients-investments/:investmentUuid/verification-requests',
  advisorClientsInvestment506cVerificationRequestDetails:
    '/clients/clients-investments/:investmentUuid/verification-requests/:requestUuid/details',
  advisorClientsInvestmentsDocumentsList: '/clients/clients-investment-documents',
  // advisorSupervisors: '/clients/supervisors',
  advisorCreateClient: '/clients/new',
  advisorClientsList: '/clients/list',
  advisorClientsActivityReports: '/clients/activity-reports',
  advisorClient: '/clients/:clientUuid',
  advisorClientAccount: '/clients/:clientUuid/account',
  advisorClientInvestments: '/clients/:clientUuid/investments',
  advisorClientSurvey: '/clients/:clientUuid/survey',
  advisorClientDocuments: '/clients/:clientUuid/documents',
  advisorClientInvestmentDocuments: '/clients/:clientUuid/investment-documents',
  advisorClientOfferingUpdates: '/clients/:clientUuid/offering-updates',
  advisorClientActivity: '/clients/:clientUuid/activity',
  //advisor client investors
  advisorClientInvestorProfiles: '/clients/:clientUuid/profiles',
  advisorClientInvestorProfile: '/clients/:clientUuid/profiles/:uuid',
  advisorClientInvestorCreateProfile: '/clients/:clientUuid/profiles/new',
  advisorClientInvestorProfileInvestments: '/clients/:clientUuid/profiles/:uuid/investments',
  advisorClientInvestorProfilePaymentMethod: '/clients/:clientUuid/profiles/:uuid/payment-method',
  advisorClientInvestorProfileDetails: '/clients/:clientUuid/profiles/:uuid/details',
  advisorClientInvestorProfileAssociatedPersons:
    '/clients/:clientUuid/profiles/:uuid/associated-persons',
  advisorClientInvestorProfileAccreditation: '/clients/:clientUuid/profiles/:uuid/accreditation',
  advisorClientInvestorProfileDocuments: '/clients/:clientUuid/profiles/:uuid/documents',
  advisorClientInvestorProfileInvestmentDocuments:
    '/clients/:clientUuid/profiles/:uuid/investment-documents',
  advisorInvestorProfileInvestmentHoldings:
    '/clients/:clientUuid/profiles/:uuid/investment-holdings',
  advisorInvestorProfileOfferingUpdates: '/clients/:clientUuid/profiles/:uuid/offering-updates',
  advisorClientInvestorProfileOfferingUpdates:
    '/clients/:clientUuid/profiles/:uuid/offering-updates',

  // user-accountant
  accountantAccount: '/accountant',
  accountantClientsInvestmentsList: '/accountant/clients-investments',
  accountantClientsInvestmentsDocumentsList: '/accountant/clients-investment-documents',

  //  user-supervisor
  supervisorAccount: '/supervisor',
  supervisorClientsInvestmentsList: '/supervisor/clients-investments',
  supervisorAdvisorsList: '/supervisor/advisors/list',
  supervisorAdvisor: '/supervisor/advisors/:advisorUuid',
  supervisorAdvisorAccount: '/supervisor/advisors/:advisorUuid/account',
  supervisorAdvisorClientsList: '/supervisor/advisors/:advisorUuid/clients/list',
  supervisorAdvisorClient: '/supervisor/advisors/:advisorUuid/clients/:clientUuid',
  supervisorAdvisorClientsActivityReports:
    '/supervisor/advisors/:advisorUuid/clients/activity-reports',
  supervisorAdvisorClientAccount: '/supervisor/advisors/:advisorUuid/clients/:clientUuid/account',
  supervisorAdvisorClientInvestments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/investments',
  supervisorAdvisorClientDocuments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/documents',
  supervisorAdvisorClientInvestmentDocuments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/investment-documents',
  supervisorAdvisorClientOfferingUpdates:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/offering-updates',
  supervisorAdvisorClientActivity: '/supervisor/advisors/:advisorUuid/clients/:clientUuid/activity',
  supervisorAdvisorClientInvestorProfiles:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles',
  supervisorAdvisorClientInvestorProfile:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid',
  supervisorAdvisorClientInvestorCreateProfile:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/new',
  supervisorAdvisorClientInvestorProfileInvestments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/investments',
  supervisorAdvisorClientInvestorProfilePaymentMethod:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/payment-method',
  supervisorAdvisorClientInvestorProfileDetails:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/details',
  supervisorAdvisorClientInvestorProfileAssociatedPersons:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/associated-persons',
  supervisorAdvisorClientInvestorProfileAccreditation:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/accreditation',
  supervisorAdvisorClientInvestorProfileDocuments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/documents',
  supervisorAdvisorClientInvestorProfileInvestmentDocuments:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/investment-documents',
  supervisorAdvisorClientInvestorProfileInvestmentHoldings:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/investment-holdings',
  supervisorAdvisorClientInvestorProfileOfferingUpdates:
    '/supervisor/advisors/:advisorUuid/clients/:clientUuid/profiles/:uuid/offering-updates',

  // offerings
  offerings: '/offerings',
  offeringsClosed: '/offerings-closed',
  offering: '/offerings/:uuid',
  offeringAdvisorSnapshot: '/offerings-advisor-snapshot/:uuid',
  offeringDueDiligence: '/offerings-due-diligence/:uuid',
  offeringDueDiligenceTest: '/offerings-due-diligence-test/:uuid',
  offeringSecondPage: '/offerings-second-page/:uuid',
  offeringThirdPage: '/offerings-third-page/:uuid',
  //current offerings 1776ing(default portal) only
  offeringsCurrent: '/offerings-current',

  // offering onboarding
  offeringOnboarding: '/offering-onboarding/:uuid/:step?',

  // investments
  addInvestment: '/invest',
  addInvestmentWithOffering: '/invest/offering/:uuid/:step?',
  addInvestmentFromDraft: '/invest/draft/:uuid/:step?',
  addInvestmentFromDraftSupervisor: '/invest/draft-supervisor/:uuid/:step?',
  addInvestmentWithIoiOffering: '/invest/ioi-offering/:uuid/:step?',
  addInvestmentWithIoiOfferingDraft: '/invest/ioi-draft/:uuid/:step?',
  addInvestmentWithUser: '/invest/user/:uuid/:step?',
  addInvestmentWithUserDraft: '/invest/user-draft/:uuid/:step?',

  //Error
  errorPage: '/error',

  //Contact Us
  contactUs: '/contact-us',

  //old Admin
  oldAdmin: '/admin',

  //Dashboard
  dashboard: '/dashboard',

  dashboardUsersList: '/dashboard/users',
  dashboardUser: '/dashboard/users/:userUuid',
  dashboardUsersDetails: '/dashboard/users/:userUuid/details',
  dashboardUsersInvestorsList: '/dashboard/users/:userUuid/investors',
  dashboardUsersClientsList: '/dashboard/users/:userUuid/clients',
  // dashboardUsersSupervisorAdvisorsList: '/dashboard/users/:userUuid/supervisor-advisors',
  dashboardUsersActivity: '/dashboard/users/:userUuid/activity',
  dashboardUsersAdvisorsList: '/dashboard/users/:userUuid/advisors',
  dashboardUsersAdvisorsAdd: '/dashboard/users/:userUuid/add-advisor',
  dashboardUsersSurvey: '/dashboard/users/:userUuid/survey',
  dashboardUsersWcodesInvestorsList: '/dashboard/users/:userUuid/wcodes-investors',
  dashboardUsersWcodesWholesalersList: '/dashboard/users/:userUuid/wcodes-wholesalers',
  dashboardUsersWcodesLicenseeRestrictionList:
    '/dashboard/users/:userUuid/wcodes-licensee-restriction',
  dashboardUsersCrdLicenseeRestrictionList: '/dashboard/users/:userUuid/crd-licensee-restriction',
  dashboardUsersSharedDocuments: '/dashboard/users/:userUuid/shared-documents',

  dashboardLicenseesList: '/dashboard/licensees',
  dashboardLicensee: '/dashboard/licensees/:userUuid',
  dashboardLicenseesDetails: '/dashboard/licensees/:userUuid/details',

  dashboardRepresentativesList: '/dashboard/registered-representatives',
  dashboardRIAsList: '/dashboard/registered-investment-advisor ',
  dashboardBusinessAdvisorsList: '/dashboard/business-advisors',
  dashboardCPAsList: '/dashboard/certified-public-accountant',

  dashboardInvestmentsExtendedList: '/dashboard/investments-extended',
  dashboardInvestmentsExtendedDetails: '/dashboard/investments-extended/:investmentUuid/details',
  dashboardInvestmentsExtendedProcessing:
    '/dashboard/investments-extended/:investmentUuid/processing',
  dashboardInvestmentsExtendedInvestor: '/dashboard/investments-extended/:investmentUuid/investor',
  dashboardInvestmentsExtendedInvestorAssociatedPersons:
    '/dashboard/investments-extended/:investmentUuid/associated-persons',
  dashboardInvestmentsExtendedInvestorIraAssociatedPerson:
    '/dashboard/investments-extended/:investmentUuid/ira-associated-person',
  dashboardInvestmentsExtendedInvestorAssociatedPersonsShow:
    '/dashboard/investments-extended/:investmentUuid/associated-persons/:personUuid/show',
  dashboardInvestmentsExtendedSA:
    '/dashboard/investments-extended/:investmentUuid/subscription-agreement',
  dashboardInvestmentsExtendedAMLExceptionsList:
    '/dashboard/investments-extended/:investmentUuid/aml-exceptions-list',
  dashboardInvestmentsExtendedBackgroundChecksList:
    '/dashboard/investments-extended/:investmentUuid/background-checks',
  dashboardInvestmentsExtendedACHPayments:
    '/dashboard/investments-extended/:investmentUuid/ACH-payments',

  dashboardInvestmentsList: '/dashboard/investments',
  dashboardInvestment: '/dashboard/investments/:investmentUuid',
  dashboardInvestmentsDetails: '/dashboard/investments/:investmentUuid/details',
  dashboardInvestmentsProcessing: '/dashboard/investments/:investmentUuid/processing',
  dashboardInvestmentsInvestor: '/dashboard/investments/:investmentUuid/investor',
  dashboardInvestmentsInvestorAssociatedPersons:
    '/dashboard/investments/:investmentUuid/associated-persons',
  dashboardInvestmentsInvestorIraAssociatedPerson:
    '/dashboard/investments/:investmentUuid/ira-associated-person',
  dashboardInvestmentsInvestorAssociatedPersonsShow:
    '/dashboard/investments/:investmentUuid/associated-persons/:personUuid/show',
  dashboardInvestmentsSA: '/dashboard/investments/:investmentUuid/subscription-agreement',
  dashboardInvestmentsAMLExceptionsList:
    '/dashboard/investments/:investmentUuid/aml-exceptions-list',
  dashboardInvestmentsBackgroundChecksList:
    '/dashboard/investments/:investmentUuid/background-checks',
  dashboardInvestmentsACHPayments: '/dashboard/investments/:investmentUuid/ACH-payments',
  dashboardInvestmentPayments: '/dashboard/investments/:investmentUuid/payments',
  dashboardInvestmentPaymentsCreate: '/dashboard/investments/:investmentUuid/payments/new',
  dashboardInvestmentPaymentsEdit: '/dashboard/investments/:investmentUuid/payments/:paymentUuid',
  dashboardInvestmentSurveyCustom: '/dashboard/investments/:investmentUuid/survey-custom',
  dashboardInvestmentUser: '/dashboard/investments/:investmentUuid/user',
  dashboardInvestmentAdvisor: '/dashboard/investments/:investmentUuid/advisor',
  dashboardInvestmentBeneficiaries: '/dashboard/investments/:investmentUuid/beneficiaries',
  dashboardInvestmentInvestorDocuments: '/dashboard/investments/:investmentUuid/investor-documents',
  // dashboardInvestmentWisemanRequests: '/dashboard/investments/:investmentUuid/wiseman-requests',
  dashboardInvestmentVerificationRequests:
    '/dashboard/investments/:investmentUuid/verification-requests',
  dashboardInvestmentVerificationRequestsNew:
    '/dashboard/investments/:investmentUuid/verification-requests/new',
  dashboardInvestmentVerificationRequest:
    '/dashboard/investments/:investmentUuid/verification-requests/:requestUuid',
  dashboardInvestmentAssigningAdvisors: '/dashboard/investments/:investmentUuid/assigning-advisors',
  dashboardInvestmentCustodian: '/dashboard/investments/:investmentUuid/custodian',
  dashboardInvestmentSignatures: '/dashboard/investments/:investmentUuid/signatures',
  dashboardDistributionInstructions:
    '/dashboard/investments/:investmentUuid/distribution-instructions',
  dashboardInvestmentWcodes: '/dashboard/investments/:investmentUuid/wcodes',
  dashboardInvestmentNotes: '/dashboard/investments/:investmentUuid/notes',

  dashboardInvestorsList: '/dashboard/investors',
  dashboardInvestorAddNew: '/dashboard/investors-add/:step?',
  dashboardInvestor: '/dashboard/investors/:investorUuid',
  dashboardInvestorDetails: '/dashboard/investors/:investorUuid/details',
  dashboardInvestorAssociatedPersons: '/dashboard/investors/:investorUuid/associated-persons',
  dashboardInvestorIraAssociatedPerson: '/dashboard/investors/:investorUuid/ira-associated-person',
  dashboardInvestorAssociatedPersonsShow:
    '/dashboard/investors/:investorUuid/associated-persons/:personUuid/show',
  dashboardInvestorAssociatedPersonsCreate:
    '/dashboard/investors/:investorUuid/associated-persons/new',
  dashboardInvestorInvestments: '/dashboard/investors/:investorUuid/investments',
  dashboardInvestorBackgroundChecksList: '/dashboard/investors/:investorUuid/background-checks',
  dashboardInvestorDocuments: '/dashboard/investors/:investorUuid/documents',
  dashboardInvestorKYCList: '/dashboard/investors/:investorUuid/kyc-list',
  dashboardInvestorTinCheckList: '/dashboard/investors/:investorUuid/tin-check-list',
  dashboardInvestorInvestmentProfileAccreditation:
    '/dashboard/investors/:investorUuid/profile-accreditation',
  dashboardInvestorTinCheckAssociatedPersonsList:
    '/dashboard/investors/:investorUuid/tin-check-associated-persons-list',
  dashboardInvestorTinCheckAssociatedPersonsListTinCheckList:
    '/dashboard/investors/:investorUuid/tin-check-associated-persons-list/:personUuid',
  dashboardInvestorTinCheckJointList: '/dashboard/investors/:investorUuid/tin-check-joint-list',

  dashboardAMLExceptionsList: '/dashboard/aml-exceptions',
  dashboardAMLException: '/dashboard/aml-exceptions/:exceptionUuid',

  dashboardBackgroundChecksList: '/dashboard/background-checks',
  dashboardBackgroundChecks: '/dashboard/background-checks/:checksUuid',
  dashboardBackgroundChecksDetails: '/dashboard/background-checks/:checksUuid/details',

  dashboardOfferingsList: '/dashboard/offerings',
  dashboardOffering: '/dashboard/offerings/:offeringUuid',
  dashboardOfferingDetails: '/dashboard/offerings/:offeringUuid/details',
  dashboardOfferingEscrow: '/dashboard/offerings/:offeringUuid/escrow',
  dashboardOfferingIssuer: '/dashboard/offerings/:offeringUuid/issuer',
  dashboardOfferingInvestments: '/dashboard/offerings/:offeringUuid/investments',
  dashboardOfferingUnitClasses: '/dashboard/offerings/:offeringUuid/unit-classes',
  dashboardOfferingUnitClassesCreate: '/dashboard/offerings/:offeringUuid/unit-classes/new',
  dashboardOfferingUnitClassesEdit:
    '/dashboard/offerings/:offeringUuid/unit-classes/:unitClassUuid',

  dashboardOfferingsUpdatesList: '/dashboard/offerings-updates',
  dashboardOfferingUpdates: '/dashboard/offerings-updates/:offeringUuid',
  dashboardOfferingUpdatesStatus: '/dashboard/offerings-updates/:offeringUuid/status',
  dashboardOfferingUpdatesDocuments: '/dashboard/offerings-updates/:offeringUuid/documents',
  dashboardOfferingUpdatesDocumentsCreate:
    '/dashboard/offerings-updates/:offeringUuid/documents/new',
  dashboardOfferingUpdatesDocumentsEdit:
    '/dashboard/offerings-updates/:offeringUuid/documents/:documentUuid',
  dashboardOfferingUpdatesPosts: '/dashboard/offerings-updates/:offeringUuid/posts',
  dashboardOfferingUpdatesPostsCreate: '/dashboard/offerings-updates/:offeringUuid/posts/new',
  dashboardOfferingUpdatesPostsEdit: '/dashboard/offerings-updates/:offeringUuid/posts/:postUuid',

  dashboardPortalsList: '/dashboard/portals',
  dashboardPortal: '/dashboard/portals/:portalUuid',
  dashboardPortalDetails: '/dashboard/portals/:portalUuid/details',
  dashboardPortalOfferings: '/dashboard/portals/:portalUuid/offerings',
  dashboardPortalOfferingsAddNew: '/dashboard/portals/:portalUuid/offerings/new',
  dashboardPortalLandingPage: '/dashboard/portals/:portalUuid/landing-page',
  dashboardPortalLandingPageDetails: '/dashboard/portals/:portalUuid/landing-pages/:uuid/details',
  dashboardPortalLicensees: '/dashboard/portals/:portalUuid/licensees',
  dashboardPortalPages: '/dashboard/portals/:portalUuid/pages',
  dashboardPortalPageDetails: '/dashboard/portals/:portalUuid/pages/:uuid/details',

  dashboardEscrowDisbursementsList: '/dashboard/escrow-disbursement',
  dashboardEscrowDisbursements: '/dashboard/escrow-disbursement/:escrowDisbursementsUuid',
  dashboardEscrowDisbursementsDetails:
    '/dashboard/escrow-disbursement/:escrowDisbursementsUuid/details',

  dashboardUsersActivityList: '/dashboard/user-activity',

  dashboardExternalServicesList: '/dashboard/external-services',

  dashboardImportQueuesList: '/dashboard/import-queues',
  dashboardImportQueues: '/dashboard/import-queues/:queueUuid',
  dashboardImportQueuesFilesList: '/dashboard/import-queues/:queueUuid/files-list',

  dashboardSectionRequestsList: '/dashboard/section-requests',
  dashboardSectionRequests: '/dashboard/section-requests/:requestUuid',
  dashboardSectionRequestsDetails: '/dashboard/section-requests/:requestUuid/details',

  dashboardReportsList: '/dashboard/reports',
  dashboardReportsListUserActivity: '/dashboard/reports/user-activity',
  dashboardReportsInvestorFiles: '/dashboard/reports/investor-file',
  dashboardReportsSubscriptionAgreement: '/dashboard/reports/subscription-agreements',

  dashboardEmailHistoryList: '/dashboard/email-history',

  dashboardIppFormList: '/dashboard/ipp-forms',
  dashboardIppForm: '/dashboard/ipp-forms/:ippFormUuid',
  dashboardIppFormDetails: '/dashboard/ipp-forms/:ippFormUuid/details',
  dashboardIppFormAddNew: '/dashboard/ipp-forms/new',

  userActivityXlsx: '/app/xlsx-report/user_activity',
  userActivityPdf: '/app/pdf-report/user_activity',
  generatedReport: '/app/async-generated-report/:uuid',
  clientConfirmLink: '/client-confirm?token=:token',
}
