import './nav-main.css'

import React from 'react'
import PropTypes from 'prop-types'

import { MenuLink, Menu } from 'ui/menu'

import { usePortal } from 'components/portal-provider'
import { usePortalPages } from 'components/portal-pages-provider'

import { routes } from 'const/routes'

export const useHeaderMenuItems = () => {
  const { pages } = usePortalPages()
  const {
    dedicatedOfferingsPage,
    dedicatedClosedOfferingsPage,
    pending,
    default: defaultPortal,
  } = usePortal() || {}
  if (pending) return []

  const result = []
  // if (dedicatedOfferingsPage) {
  //   result.push({ url: routes.offerings, title: 'Offerings', uuid: 'offerings-list' })
  // }
  // if (dedicatedClosedOfferingsPage) {
  //   result.push({
  //     url: routes.offeringsClosed,
  //     title: 'Completed Offerings',
  //     uuid: 'offerings-closed-list',
  //   })
  // }
  // if (defaultPortal) {
  //   const title = process.env.OFFERINGS_CURRENT_TITLE ?? 'Offerings'
  //   result.push({
  //     url: routes.offeringsCurrent,
  //     title: title,
  //     uuid: 'offerings-current-list',
  //   })
  // }

  if (pages?.length > 0) {
    result.push(...pages.filter(({ url, menu }) => url && menu !== 'footer'))
  }
  return result
}

export const HeaderMenu = ({ items, ...props }) => {
  return (
    <Menu {...props} className="main-nav">
      {items.map(({ uuid, url, title }, index) => (
        <MenuLink to={url} key={uuid} style={{ '--index': index++ }}>
          {title}
        </MenuLink>
      ))}
    </Menu>
  )
}
HeaderMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
    }),
  ),
}
