import React from 'react'
import PropTypes from 'prop-types'

import { FormattedInput } from 'ui/form/formatted-input'
import { useFormValues } from 'ui/form/context'

import { INVESTOR_TYPE } from 'const/types'

import { withFormDefaultValues } from '../base-field'
import { SecureField } from '../secure-field'

const US_CONFIG = {
  delimiter: ' - ',
  blocks: [3, 2, 4],
  numericOnly: true,
}
const US_CONFIG_COMPANY = {
  delimiter: ' - ',
  blocks: [2, 7],
  numericOnly: true,
}

const usCompanyValidation = (required) => (data) => {
  if (!required && !data) return null
  if (data && String(data).length === 9) return null
  return 'Must be 9 digits'
}

const CONFIG = {}

export class Field extends SecureField {
  static displayName = 'SecureTaxIdField'

  static propTypes = {
    ...super.propTypes,
    country: PropTypes.string, // optional
    ownerType: PropTypes.oneOf(Object.values(INVESTOR_TYPE)), // optional
  }
  static defaultProps = {
    ...super.defaultProps,
    country: 'US',
    ownerType: INVESTOR_TYPE.PERSON,
  }

  getInputProps() {
    const { country, ownerType, ...props } = super.getInputProps()
    return props
  }

  renderNonUsInput({ defaultValue, ...props }) {
    return <FormattedInput {...props} key="non-us" options={CONFIG} />
  }
  renderUsPersonalInput({ ownerType, country, defaultValue, ...props }) {
    return (
      <FormattedInput {...props} key="us-personal" options={US_CONFIG} placeholder="XXX-XX-XXXX" />
    )
  }
  renderUsCompanyInput({ defaultValue, ...props }) {
    return (
      <FormattedInput
        {...props}
        key="us-company"
        options={US_CONFIG_COMPANY}
        placeholder="XX-XXXXXXX"
        validation={usCompanyValidation(props.required)}
      />
    )
  }

  getWrapperClassName() {
    return super.getWrapperClassName('field box-field tax-id-field')
  }

  get label() {
    const { country, ownerType } = this.props
    const investorCountry = country
    const investorOwnerType = ownerType
    const isUS = (investorCountry || '').toUpperCase() === 'US'
    return isUS ? (
      investorOwnerType === INVESTOR_TYPE.PERSON ? (
        <abbr title="Social Security Number">SSN</abbr>
      ) : (
        <abbr title="Employer Identification Number">EIN</abbr>
      )
    ) : (
      'Tax ID'
    )
  }

  renderField(props) {
    const { ownerType, country } = this.props
    if ((country || '').toUpperCase() !== 'US') {
      return this.renderNonUsInput(props)
    }
    if (ownerType === INVESTOR_TYPE.PERSON) {
      return this.renderUsPersonalInput(props)
    }
    if ([INVESTOR_TYPE.COMPANY, INVESTOR_TYPE.TRUST, INVESTOR_TYPE.IRA].includes(ownerType)) {
      return this.renderUsCompanyInput(props)
    }
    throw new Error('Unexpected state')
  }
}

const BoundField = withFormDefaultValues(Field)

export const SecuredTaxIdField = React.forwardRef(
  ({ ownerType, country, countryField, ownerTypeField, ...props }, ref) => {
    const { [countryField]: countryFieldValue, [ownerTypeField]: ownerTypeFieldValue } =
      useFormValues()
    const investorCountry = country ?? countryFieldValue
    const investorOwnerType = ownerType ?? ownerTypeFieldValue
    const isUS = (investorCountry || '').toUpperCase() === 'US'
    const title = isUS ? (investorOwnerType === INVESTOR_TYPE.PERSON ? 'SSN' : 'EIN') : 'Tax ID'

    return (
      <BoundField
        {...props}
        country={investorCountry}
        ownerType={investorOwnerType}
        ref={ref}
        title={title}
      />
    )
  },
)

SecuredTaxIdField.displayName = `with(${BoundField.displayName})`

SecuredTaxIdField.propTypes = {
  country: PropTypes.string,
  countryField: PropTypes.string,
  ownerType: PropTypes.string,
  ownerTypeField: PropTypes.string,
}
