import './styles.css'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useAlert } from 'ui/alert'
import { Button } from 'ui/button'

import { useUser } from 'components/user'

import { api } from 'api'
import { CONFIRM_EMAIL_ALERT_KEY, USER_ROLE } from 'const/types'
import { CONFIRM_EMAIL_ALERT, RESEND_EMAIL_ALERT } from 'const/labels'

export const ConfirmAlert = ({ resendEmail }) => {
  return (
    <div className="confirm-email-alert">
      <span>{CONFIRM_EMAIL_ALERT}&nbsp;</span>
      <Button theme="inline" onClick={resendEmail}>
        Send again
      </Button>
    </div>
  )
}

ConfirmAlert.propTypes = {
  resendEmail: PropTypes.func,
}

export const EmailConfirmedAlert = () => {
  const user = useUser()
  const alerts = useAlert()

  const resendEmail = async () => {
    try {
      await api.register.emailConfirmResend()
      alerts.success({ message: RESEND_EMAIL_ALERT })
      alerts.close(CONFIRM_EMAIL_ALERT_KEY)
    } catch ({ error, errors }) {
      alerts.error({ message: error })
    }
  }

  useEffect(() => {
    if (user && !user.confirmed && !user.roles?.includes(USER_ROLE.ADMIN)) {
      alerts.warning({
        message: <ConfirmAlert resendEmail={resendEmail} />,
        key: CONFIRM_EMAIL_ALERT_KEY,
      })
    } else {
      alerts.close(CONFIRM_EMAIL_ALERT_KEY)
    }
  }, [user]) //eslint-disable-line

  return false
}
