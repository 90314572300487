import './currency.css'
import React from 'react'
import PropTypes from 'prop-types'

import { FormattedInput } from 'ui/form/formatted-input'

import { BaseField, withFormDefaultValues } from '../base-field'

const CLEAVE_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  numeralPositiveOnly: true,
  numeralDecimalScale: 2,
  stripLeadingZeroes: true,
}

export class Field extends BaseField {
  static displayName = 'CurrencyField'
  static propTypes = {
    ...BaseField.propTypes,
    max: PropTypes.number,
    min: PropTypes.number,
    multipliesOf: PropTypes.number,
  }

  validate = (value) => {
    const { min, max, multipliesOf, validation } = this.props
    if (Number.isFinite(value)) {
      const hasMin = min !== undefined && min !== null
      if (hasMin && value < min) {
        return `Must be greater than or equals to ${min}`
      }
      if (max !== undefined && max !== null && value > max) {
        return `Must be lesser than or equals to ${max}`
      }
      if (multipliesOf !== undefined && multipliesOf !== null) {
        if ((value * 100).toFixed(0) % (multipliesOf * 100).toFixed(0) !== 0) {
          return `Must be entered in multiples of ${multipliesOf}`
        }
      }
    }
    if (validation) {
      return validation(value)
    }
  }
  getWrapperClassName() {
    return super.getWrapperClassName('currency')
  }
  renderField({ min, max, multipliesOf, ...props }) {
    return (
      <>
        <FormattedInput {...props} validation={this.validate} options={CLEAVE_OPTIONS} />
        <span className="currency-label">{props?.labelIcon ?? '$'}</span>
      </>
    )
  }
}

export const CurrencyField = withFormDefaultValues(Field)
