import './alert-container.css'
import React, { FC } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { AlertMessage, IAlertMessage, TAlertsApi } from './alert-message'

interface Message extends IAlertMessage {
  key: string
}

export type Messages = Message[]

interface AlertsContainer {
  messages: Messages
  alertsApi: TAlertsApi
}

export const AlertsContainer: FC<AlertsContainer> = ({ messages, alertsApi }) => (
  <div className="alert-container">
    <TransitionGroup className="messages">
      {messages?.map(({ message, key, ...props }) => (
        <CSSTransition timeout={500} classNames="message" key={key}>
          <AlertMessage {...props} messageKey={key} message={message} alertsApi={alertsApi} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
)

// AlertsContainer.propTypes = {
//   alertsApi: PropTypes.shape({
//     close: PropTypes.func.isRequired,
//   }).isRequired,
//   messages: PropTypes.arrayOf(PropTypes.shape(AlertMessage.propTypes)),
// }
