import './icon-button.css'
import React, { FC } from 'react'

import { cn } from 'utils'
interface IconButtonProps extends React.ComponentProps<'button'> {
  pending?: boolean
}
export const IconButton: FC<IconButtonProps> = ({ className, pending, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={cn('icon-button', className, pending && 'pending')}
    />
  )
}
