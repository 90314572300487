import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useAlert } from 'ui/alert'

import { unauthorizedLogOutStorage } from 'api/storage'

import { useUnauthorized } from './unauthorized-hook'

const logoutMessage = 'Your session has expired. Please log in again.'

export const UnauthorizedLogOut = () => {
  const alerts = useAlert()
  const unauthorized = useUnauthorized()
  useEffect(() => {
    if (unauthorized) {
      unauthorizedLogOutStorage.value = false
      alerts.error({ message: logoutMessage })
    }
  }, [unauthorized]) //eslint-disable-line
  return null
}

UnauthorizedLogOut.propTypes = {}
