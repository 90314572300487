import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { TextField } from 'ui/field/text'
import { useFormValues } from 'ui/form/context'

const US = 'US'
const CA = 'CA'
const GB = 'GB'

const PostalCode = ({ country, autoCompletePrefix, label, labelAfter, ...props }) => {
  const isUS = country && country.toUpperCase() === US
  const fullLabel = [label || '', isUS ? 'ZIP' : 'Postal Code', labelAfter || ''].join(' ')
  const autoComplete = [autoCompletePrefix, 'postal-code'].filter(Boolean).join(' ')
  return (
    <TextField
      {...props}
      label={fullLabel}
      autoComplete={autoComplete}
      maxLength={isUS ? 5 : null}
    />
  )
}

PostalCode.propTypes = {
  autoCompletePrefix: PropTypes.string,
  country: PropTypes.string,
  label: PropTypes.string,
  labelAfter: PropTypes.string,
}

PostalCode.defaultProps = {
  country: US,
}

const createValidation = (country) => (value) => {
  if (!value) {
    return null
  }
  const reUS = /^[0-9]{5}(?:-[0-9]{4})?$/
  const reCA = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/
  const reGB = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/
  switch (country && country.toUpperCase()) {
    case US:
      return reUS.test(value) ? '' : 'Invalid ZIP code'
    case CA:
      return reCA.test(value) ? '' : 'Invalid postal code'
    case GB:
      return reGB.test(value) ? '' : 'Invalid postal code'
    default:
      return null
  }
}

export const PostalCodeField = React.forwardRef(({ country, countryField, ...props }, ref) => {
  const { [countryField]: countryValue } = useFormValues()
  const validation = useMemo(
    () => createValidation(country ?? countryValue),
    [country, countryValue],
  )
  return (
    <PostalCode {...props} ref={ref} country={country ?? countryValue} validation={validation} />
  )
})

PostalCodeField.displayName = 'PostalCodeField'
PostalCodeField.propTypes = {
  autoCompletePrefix: PropTypes.string,
  country: PropTypes.string,
  countryField: PropTypes.string,
  required: PropTypes.bool,
}
