import React from 'react'

import { HiddenInput } from 'ui/form'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'HiddenField'

  render() {
    const {
      validationMessage,
      label,
      hideValidationMessage,
      className,
      children,
      tooltip,
      touched,
      ...props
    } = this.props

    return <HiddenInput {...props} ref={this.element} />
  }
}

export const HiddenField = withFormDefaultValues(Field)
