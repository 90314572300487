import { toSearchParamsString } from 'utils/url'

import { authStorage, unauthorizedLogOutStorage, wcodeEnableSavingStorage } from '../storage'

// eslint-disable-next-line no-undef
const BASE_URL = new URL(process.env.API_PATH, location.origin)
BASE_URL.username = ''
BASE_URL.password = ''

const CRITICAL_ERROR_CODES = [502]
const AUTH_ERROR_CODES = [401]
const GENERIC_NETWORK_ERROR = 'Something went wrong'
const GENERIC_VALIDATION_ERROR = 'Unable to Proceed'

export const getEndpointUrl = (endpoint) => {
  console.assert(!!BASE_URL, 'BASE_URL is ' + BASE_URL) //eslint-disable-line
  return BASE_URL + endpoint
}

const getRequestConfig = (endpoint, params, config = {}) => {
  const method = config?.method?.toUpperCase() ?? 'GET'
  const isFormData = params instanceof FormData
  const headers = {
    Accept: 'application/ld+json;application/json',
    //browser add 'Content-Type': 'multipart/form-data' by itself, when we add it manually it will not understand that it's a 'multipart/form-data'  request
    ...(!isFormData && { 'Content-Type': 'application/ld+json' }),
    ...config?.headers,
  }
  const urlParams = method === 'GET' && params ? toSearchParamsString(params) : ''
  return {
    credentials: 'include',
    ...(method !== 'GET' && params && { body: isFormData ? params : JSON.stringify(params) }),
    method,
    ...config,
    headers,
    url: getEndpointUrl(endpoint) + urlParams,
  }
}

const parseResponseBody = async (response) => {
  // we need to make sure that response body is not empty before parsing it
  const body = await response.text()
  try {
    return body ? JSON.parse(body) : null
  } catch (error) {
    throw {
      error: `Parsing server response error: ${error}`,
      critical: true,
      body,
      response,
    }
  }
}
const unauthorizedLogOut = () => {
  if (!unauthorizedLogOutStorage.value && authStorage.auth) {
    wcodeEnableSavingStorage.value = true
    unauthorizedLogOutStorage.value = true
    authStorage.auth = null
  }
}
const parseResponse = async (response) => {
  if (CRITICAL_ERROR_CODES.includes(response.status)) {
    throw {
      critical: true,
      error: 'Server is down. Please try again a bit later',
      response,
    }
  }
  const body = await parseResponseBody(response)
  if (response.ok) {
    return body
  }
  // auth
  if (AUTH_ERROR_CODES.includes(response.status)) {
    unauthorizedLogOut()
    throw {
      error: parseAuthError(body),
      response,
      body,
      unauthorized: true,
    }
  }
  throw {
    error: parseValidationError(body) || response?.statusText || GENERIC_NETWORK_ERROR,
    errors: parseValidationErrors(body),
    response,
    body,
  }
}

export const createClient = (fetch, logSuccess, logError) => {
  return async (endpoint, params, config = {}) => {
    let start = logSuccess && performance.now()
    const requestConfig = getRequestConfig(endpoint, params, config)
    const { url, ...fetchConfig } = requestConfig
    let result
    try {
      result = await fetch(url, fetchConfig).then(parseResponse)
      logSuccess &&
        logSuccess({
          config: requestConfig,
          endpoint,
          params,
          result,
          time: performance.now() - start,
        })
      return result
    } catch (error) {
      const isOnline = navigator.onLine
      if (!isOnline) {
        throw {
          error: 'Network unavailable',
        }
      }
      logError && logError({ error, config: requestConfig, time: performance.now() - start })
      throw error
    }
  }
}

/**
 * @param {{title:String,source:String,detail:String}[]} errors
 * @returns {Object.<String,String>|null}
 */
function parseValidationErrors({ violations: errors } = {}) {
  if (Array.isArray(errors)) {
    return Object.fromEntries(
      errors
        .filter(({ propertyPath }) => !!propertyPath)
        .map(({ propertyPath, message }) => [
          propertyPath,
          message
            // Probably redundant flow because of old BE answers
            // .toLocaleLowerCase()
            // .replace(/^\w/, (c) => c.toUpperCase())
            .replace(/_/g, ' '),
        ]),
    )
  }
  return null
}

function parseValidationError(body = {}) {
  const unnamedErrors = body?.violations?.filter(({ propertyPath }) => !propertyPath)
  if (unnamedErrors?.length > 0) {
    return unnamedErrors.map(({ message }) => message).join(',\n')
  }
  if (body) {
    const title = body['hydra:title']
    const description = body['hydra:description']
    if (title && description) return `${title}: ${description}`
    if (title) return title
  }

  return GENERIC_VALIDATION_ERROR
}

function parseAuthError(body = {}) {
  const description = body?.['hydra:description']
  return description
    ? description
        .split(':')[0]
        // Probably redundant flow because of old BE answers
        // .toLocaleLowerCase()
        // .replace(/^\w/, (c) => c.toUpperCase())
        .replace(/_/g, ' ')
    : GENERIC_VALIDATION_ERROR
}
