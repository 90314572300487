let sessionStorage = null
try {
  sessionStorage = window.sessionStorage
} catch (e) {
  //
}
const read = <T = any>(key: string): T => {
  try {
    return JSON.parse(sessionStorage.getItem(key))
  } catch (e) {
    return null
  }
}

const write = <T>(key: string, value?: T | null): void => {
  if (value === null || value === undefined) {
    sessionStorage.removeItem(key)
    return
  }
  try {
    sessionStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // limit exceeded?
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

type Listener<T> = (value: T | null) => void

const addListener = function <T>(key: string, cb: Listener<T>): () => void {
  const listener = (event) => {
    if (event.key === null || (event.key === key && event.newValue !== event.oldValue)) {
      let value
      try {
        value = event.newValue ? JSON.parse(event.newValue) : event.newValue
      } catch (e) {
        value = null
      }
      cb(value)
    }
  }

  window.addEventListener('session-storage', listener)

  return () => {
    window.removeEventListener('session-storage', listener)
  }
}

export const storage = {
  read,
  write,
  addListener,
}
