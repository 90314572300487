import React from 'react'
import PropTypes from 'prop-types'

import { BaseInput } from 'ui/form/base-input'

import { optionsConverter } from 'utils/options-converter'

export class RadioInputGroup extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    optionComponent: PropTypes.elementType,
    options: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.node),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.node,
          value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
          disabled: PropTypes.bool,
        }),
      ),
    ]),
    validation: PropTypes.func,
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    //Validate default value
    // if (value) this.validate() // TELL ME WHY?

    element.addEventListener('change', (event) => {
      if (event.target.matches('input')) {
        this.handleChange(event)
      }
    })

    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  handleChange = (event) => {
    const { value, checked } = event.target
    if (!checked) return

    // let the parent form knows about changes
    // start validation on change
    this.broadcastUpdates({ value: this.normalizeValue(value), touched: true })
  }

  normalizeValue(value) {
    if (value === null) {
      return null
    }

    const options = this.props.options

    const option = Array.isArray(options)
      ? options.find((option) => typeof option !== 'string' && String(option?.value) === value)
      : null

    return option ? option.value : value
  }

  isValid() {
    if (!this.element) return false
    if (this.props.required && this.getValue() === null) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  getValidationMessage() {
    if (this.props.required && this.getValue() === null) return 'Required field'
    return this.checkCustomValidity() || null
  }

  setValue(value = null) {
    if (!this.element) return
    const radio = this.element.querySelector(`input[value="${value}"]`)
    if (radio) {
      radio.checked = true
      this.broadcastUpdates({ value })
    }
  }

  getValue() {
    if (!this.element) return undefined
    const radio = this.element.querySelector(`input[name="${this.props.name}"]:checked`)
    const value = radio && radio.value
    return value === '' ? null : this.normalizeValue(value)
  }

  focus() {
    const option =
      this.element.querySelector('input:checked') || this.element.querySelector('input')
    option?.focus()
  }

  reset() {
    const actualValue = this.getValue()
    const radio = this.element?.querySelector(`input[value="${actualValue}"]`)
    if (radio) {
      radio.checked = false
    }
    super.reset()
  }
  renderOptions(options, props) {
    const { optionComponent: Option, name } = this.props
    const { disabled: allDisabled } = props
    if (!Option) {
      throw new Error('RadioInputGroup: invalid prop: optionComponent')
    }
    return optionsConverter(options).map(([value, label, title, disabled]) => (
      <Option
        data-lpignore="true"
        {...props}
        value={value}
        name={name}
        key={name + value}
        disabled={allDisabled || disabled}
      >
        {label}
      </Option>
    ))
  }

  render() {
    const {
      disabled,
      validation,
      validationMessage,
      validationMessages,
      onReset,
      options,
      className,
      id,
      children,
      required,
      optionComponent,
      readOnly,
      ...restProps
    } = this.props

    const props = {
      ...restProps,
      // onChange: this.handleChange,
      disabled: readOnly || disabled,
    }
    return (
      <div ref={this.setElement} id={id} className={className}>
        {options
          ? this.renderOptions(options, props)
          : React.Children.map(children, (child) => React.cloneElement(child, props))}
      </div>
    )
  }
}
