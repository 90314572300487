import React from 'react'
import PropTypes from 'prop-types'

import { BaseInput } from './base-input'

export class HiddenInput extends BaseInput {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }
  static defaultProps = {}

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || (this.props.defaultValue === false ? false : null)
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  isValid() {
    return true
  }
  getValidationMessage() {
    return null
  }

  getValue() {
    if (!this.element) return undefined
    const value = String(this.element.value).trim()
    return value === '' ? null : value
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.broadcastUpdates()
  }

  render() {
    const { validation, ...props } = this.props
    return <input {...props} type="hidden" ref={this.setElement} id={props.id || props.name} />
  }
}
