import { useCallback, useEffect } from 'react'

import { useHistory } from 'router'
import { api } from 'api'
import { userActivityButtonClickType, userActivityDocumentDownloadType } from 'const/types'

const basePath = window.location.origin

export const ClickActivity = () => {
  const history = useHistory()
  const handleActivity = useCallback(
    (event) => {
      const el = event.target
      const a = el.closest('a')
      const button = el.closest('button')

      // data-activity-disabled snake case transformed to camel case activityDisabled
      if (a?.dataset?.activityDisabled || button?.dataset?.activityDisabled) {
        return
      }

      try {
        if (a) {
          const activityType = a?.dataset?.documentDownload
            ? userActivityDocumentDownloadType
            : userActivityButtonClickType
          api.userActivity.post({
            activityType: activityType,
            activityData: {
              url:
                basePath +
                history.location.pathname +
                history.location.hash +
                history.location.search,
              action: a.title || a.textContent,
            },
          })
        }
        if (button) {
          api.userActivity.post({
            activityType: userActivityButtonClickType,
            activityData: {
              url:
                basePath +
                history.location.pathname +
                history.location.hash +
                history.location.search,
              action: button.title || button.textContent,
            },
          })
        }
      } catch (e) {
        //nothing
      }
    },
    [history.location.hash, history.location.pathname, history.location.search],
  )
  useEffect(() => {
    document.addEventListener('click', handleActivity, true)
    return () => document.removeEventListener('click', handleActivity, true)
  }, [handleActivity])
  return false
}
