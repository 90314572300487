import React from 'react'

import { NumberInput } from 'ui/form'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'NumberField'
  getWrapperClassName() {
    return super.getWrapperClassName('number')
  }
  renderField(props) {
    return <NumberInput {...props} />
  }
}

export const NumberField = withFormDefaultValues(Field)
