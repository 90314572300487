import React from 'react'

import { Section } from 'ui/page'
import './access-denied.css'
export const AccessDenied = () => {
  return (
    <Section className="access-denied-con">
      <div>
        <h1>Access Denied</h1>
      </div>
    </Section>
  )
}
