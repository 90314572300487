import { useEffect } from 'react'

import { usePortal } from 'components/portal-provider'

import { useHistory, matchPath } from 'router'
import { api } from 'api'
import { userActivityPageVisitType } from 'const/types'
import { routes } from 'const/routes'

const getPageName = (route) => {
  if (!route) {
    return null
  }
  const result = route.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const basePath = window.location.origin

//This routes has different user activity handler
const disabledRoutes = [
  routes.addInvestment,
  routes.addInvestmentWithOffering,
  routes.addInvestmentFromDraft,
  routes.addInvestmentFromDraftSupervisor,
  routes.addInvestmentWithIoiOffering,
  routes.addInvestmentWithIoiOfferingDraft,
  routes.addInvestmentWithUser,
  routes.addInvestmentWithUserDraft,
  routes.offering,
  routes.offeringDueDiligence,
]

export const PageActivity = () => {
  const history = useHistory()
  const { uuid: portalUuid, pending } = usePortal()
  useEffect(() => {
    //disable sending data before receiving portal data
    if (pending) return

    const pathName = history.location.pathname
    if (disabledRoutes.some((route) => matchPath(pathName, { path: route, exact: false }))) {
      //disable activity for investment flow and offering
      return
    }

    try {
      const [name] =
        Object.entries(routes).find(([, route]) =>
          matchPath(pathName, { path: route, exact: true }),
        ) ?? []

      const pageName = name ? getPageName(name) : false
      api.userActivity.post({
        activityType: userActivityPageVisitType,
        activityData: {
          url:
            basePath + history.location.pathname + history.location.hash + history.location.search,
          ...(pageName && { page: pageName }),
          portalUuid,
        },
      })
    } catch (e) {
      //nothing
    }
  }, [history.location, pending, portalUuid])
  return false
}
