import 'react-mde/lib/styles/css/react-mde-all.css'
import T from 'prop-types'
import React from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'

import { BaseInput } from './base-input'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

export class MdeInput extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    defaultValue: T.string,
    disabled: T.bool,
    name: T.string.isRequired,
    onBlur: T.func,

    validationMessages: T.exact({
      valueMissing: T.string,
      typeMismatch: T.string,
      patternMismatch: T.string,
      tooLong: T.string,
      tooShort: T.string,
    }),
  }
  static defaultProps = {
    validationMessages: {},
  }

  state = {
    ...super.state,
    value: this.props.defaultValue || '',
    tab: 'write',
  }

  textArea = null

  handleTabChange(tab) {
    this.setState({ tab })
  }

  handleBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = (value) => {
    this.setValue(value)
  }

  /**
   * @override
   */

  isValid(value = this.getValue()) {
    if (!this.willValidate()) return true
    if (this.props.required && !(value !== null && value !== undefined && value !== '')) {
      return false
    }
    if (this.checkCustomValidity()) return false
    return true
  }

  /**
   * @override
   */
  setValue(value = '') {
    // if (!this.element) return
    // this.element.value = value
    this.setState({ value })
    this.broadcastUpdates({ value })
    // this.resetCustomValidity()
  }

  /**
   * @override
   */
  getValue() {
    const value = this.state.value ? String(this.state.value).trim() : ''
    return value
  }

  getValidationMessage() {
    return super.getValidationMessage() || this.checkCustomValidity()
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate && super.componentDidUpdate(prevProps, prevState)
    if (prevProps && this.props.defaultValue !== prevProps.defaultValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.defaultValue })
    }
  }
  componentDidMount() {
    this.broadcastUpdates()
  }

  reset() {
    super.reset()
    this.setState({ value: this.props.defaultValue ?? '' })
  }

  render() {
    const { validation, validationMessages, validationMessage, ...props } = this.props
    return (
      <ReactMde
        {...props}
        // refs={{
        //   textarea: this.setElement,
        // }}
        id={props.id || props.name}
        value={this.state.value}
        onChange={this.handleChange}
        onTabChange={(tab) => this.handleTabChange(tab)}
        generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
        selectedTab={this.state.tab}
        childProps={{
          textArea: {
            onBlur: this.handleBlur,
            name: props.name,
            placeholder: props.placeholder,
            maxLength: props.maxLength,
            minLength: props.minLength,
            disabled: props.disabled,
            readOnly: props.readOnly,
            required: props.required,
          },
        }}
      />
    )
  }
}
