import { useEffect, useState } from 'react'

import { wcodeStorage } from 'api/storage'

export const useWcode = () => {
  const [wcode, setWcode] = useState(wcodeStorage.value)
  useEffect(() => {
    const unsubscribe = wcodeStorage.addListener((code) => {
      setWcode(code)
    })
    return unsubscribe
  }, [])
  return wcode
}
