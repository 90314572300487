import React from 'react'
import PropTypes from 'prop-types'

import { BaseInput } from 'ui/form/base-input'

import { optionsConverter } from 'utils/options-converter'
import { CHECKBOX_GROUP_SORT_OPTIONS } from 'const/types'

export class CheckboxInputGroup extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    delimiter: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    optionComponent: PropTypes.elementType,
    options: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.node),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.node,
          value: PropTypes.string.isRequired,
          disabled: PropTypes.bool,
        }),
      ),
    ]),
    sort: PropTypes.oneOf(CHECKBOX_GROUP_SORT_OPTIONS),
    validation: PropTypes.func,
  }
  static defaultProps = {
    delimiter: ',',
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  values = this.getValuesArray(this.props.defaultValue)

  getValue() {
    return this.values.join(this.props.delimiter) || null
  }

  setValue(value) {
    this.values = this.getValuesArray(value)
    Array.from(this.element.querySelectorAll('input')).forEach((input) => {
      input.checked = this.values.includes(input.value)
    })
    this.broadcastUpdates({ value })
  }

  handleBlur = () => {
    this.broadcastUpdates({ touched: true })
    this.props.onBlur?.()
  }

  handleChange = (event) => {
    const { value, checked } = event.target
    const includes = this.values.includes(value)

    if (checked && !includes) {
      this.values = [...this.values, value].sort()
    } else if (!checked && includes) {
      this.values = this.values.filter((val) => value !== val)
    }
    // let the parent form knows about changes
    // start validation on change
    this.broadcastUpdates({ touched: true })
  }

  getValuesArray(value, delimiter = this.props.delimiter) {
    if (!value) return []
    if (Array.isArray(value)) return value.sort()
    return value.split(delimiter).sort()
  }

  isValid() {
    if (this.props.required && this.getValue() === null) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  getValidationMessage() {
    if (this.props.required && this.getValue() === null) return 'Required field'
    return this.checkCustomValidity() || null
  }

  focus() {
    const option =
      this.element.querySelector('input:checked') ||
      this.element.querySelector('input:not([disabled])')
    option?.focus()
  }

  reset() {
    this.values = this.getValuesArray(this.props.defaultValue)
    super.reset()
  }

  sortOptions(options, sortedOption) {
    const sortCallbacks = {
      [CHECKBOX_GROUP_SORT_OPTIONS.alphabetically]: (array) =>
        array?.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())),
      [CHECKBOX_GROUP_SORT_OPTIONS.descending]: (array) =>
        array?.sort((a, b) => b?.label?.toLowerCase().localeCompare(a?.label?.toLowerCase())),
    }
    return sortCallbacks?.[sortedOption]?.(options)
  }

  renderOptions(options, props) {
    const { optionComponent: Option, name, sort } = this.props
    const sortedOptions = sort ? this.sortOptions(options, sort) : options
    return optionsConverter(sortedOptions).map(([value, label]) => (
      <Option
        data-lpignore="true"
        {...props}
        value={value}
        name={name}
        key={name + value}
        defaultChecked={this.values.includes(value)}
      >
        {label}
      </Option>
    ))
  }

  componentDidMount() {
    this.broadcastUpdates()
  }

  render() {
    const {
      disabled,

      validation,
      validationMessage,
      validationMessages,
      onReset,
      options,
      className,
      id,
      children,
      required,
      optionComponent,
      readOnly,
      ...restProps
    } = this.props

    const props = {
      ...restProps,
      onChange: this.handleChange,
      disabled: readOnly || disabled,
    }

    return (
      <div ref={this.setElement} id={id} className={className} onBlur={this.handleBlur}>
        {options
          ? this.renderOptions(options, props)
          : React.Children.map(children, (child) => React.cloneElement(child, props))}
      </div>
    )
  }
}
