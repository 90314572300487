import './dialog.css'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { IconButton } from 'ui/button'

import { cn } from 'utils'
import DeleteButton from 'icons/close.svg'

// https://developer.mozilla.org/ru/docs/Web/HTML/Element/dialog

const Portal = ({ children }) => {
  const [modalContainer] = useState(document.createElement('div'))
  useEffect(() => {
    let modalRoot = document.getElementById('dialog-root')
    if (!modalRoot) {
      const tempEl = document.createElement('div')
      tempEl.id = 'dialog-root'
      document.body.append(tempEl)
      modalRoot = tempEl
    }
    modalRoot.appendChild(modalContainer)
    return () => {
      modalRoot.removeChild(modalContainer)
    }
  }, []) //eslint-disable-line

  return createPortal(children, modalContainer)
}

interface IDialog {
  title?: string
  onClose?: () => void
  onCloseButtonOnly?: () => void
  className?: string
}

export class Dialog extends React.PureComponent<IDialog> {
  // static propTypes = {
  //   onClose: PropTypes.func.isRequired,
  //   onCloseButtonOnly: PropTypes.func,
  //   title: PropTypes.string,
  // }

  handleButtonClose = (): void => {
    this.props.onClose?.()
    this.props.onCloseButtonOnly?.()
  }

  componentDidMount(): void {
    document.body.classList.add('noScroll')
  }

  componentWillUnmount(): void {
    document.body.classList.remove('noScroll')
  }

  render(): JSX.Element {
    const { children, title, onClose, onCloseButtonOnly, className } = this.props
    return (
      <Portal>
        <div
          tabIndex={0}
          role="button"
          className={cn('dialog-bg', className)}
          onClick={onClose}
          onKeyPress={onClose}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <dialog open className="dialog" onClick={stopPropagation} onKeyPress={stopPropagation}>
            <header>
              {(onClose || onCloseButtonOnly) && (
                <IconButton className="close" onClick={this.handleButtonClose} title="Close Dialog">
                  <DeleteButton />
                </IconButton>
              )}
              <h2>{title}</h2>
            </header>
            {children}
          </dialog>
        </div>
      </Portal>
    )
  }
}

const stopPropagation = (event) => event.stopPropagation()
