import React, { ComponentProps, FC } from 'react'

import { cn } from 'utils'
export const MailToLink: FC<ComponentProps<'a'> & { email: string }> = ({
  className,
  email,
  children,
  ...props
}) => (
  <a
    href={`mailto:${email}`}
    {...props}
    className={cn('link', className)}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children || email}
  </a>
)
