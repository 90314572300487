import * as React from 'react'

export type LoaderError = {
  error?: string
  errors?: Record<string, string>
  code?: string
}
export type LoaderState<T> = {
  pending: boolean
  error?: LoaderError
  result: T | null
}

type LoaderInnerState<T> = LoaderState<T> & {
  requestId: number
}

type LoaderAction<T> =
  | { type: 'PENDING'; id: number }
  | { type: 'ERROR'; payload: LoaderError; id: number }
  | { type: 'SUCCESS'; payload: T; id: number }

type Resolver<T> = (...args) => Promise<T>
type LoadFunction<T> = (fn: Resolver<T>, ...args) => Promise<T | void>
type AbortFunction = () => void

function reducer<T>(
  state: LoaderInnerState<T> = { pending: true, requestId: 0, result: null },
  action: LoaderAction<T>,
): LoaderInnerState<T> {
  switch (action.type) {
    case 'PENDING':
      return { ...state, pending: true, error: null, requestId: action.id }
    case 'SUCCESS':
      return state.requestId === action.id
        ? { ...state, pending: false, error: null, result: action.payload }
        : state
    case 'ERROR':
      return state.requestId === action.id
        ? { ...state, pending: false, error: action.payload, result: null }
        : state
    default:
      return state
  }
}

const reducerDefaultValues: LoaderInnerState<null> = {
  requestId: null,
  pending: true,
  error: null,
  result: null,
}

function getId(): number {
  return Math.round(new Date().valueOf() * Math.random())
}

function createRequestDispatcher<T>(dispatch): [LoadFunction<T>, AbortFunction] {
  let isMounted = true
  const load: LoadFunction<T> = function (fn, ...args) {
    const id = getId()
    dispatch({ type: 'PENDING', id })
    return fn(...args)
      .then((payload: T) => {
        isMounted && dispatch({ type: 'SUCCESS', id, payload })
        return payload
      })
      .catch((payload) => {
        isMounted && dispatch({ type: 'ERROR', id, payload })
      })
  }
  const abort = function (): void {
    isMounted = false
  }
  return [load, abort]
}

export function useLoaderState<T>(
  defaultValues: Partial<LoaderState<T>> = {},
): [LoaderState<T>, LoadFunction<T>, AbortFunction] {
  const [state, dispatch] = React.useReducer<React.Reducer<LoaderInnerState<T>, LoaderAction<T>>>(
    reducer,
    { ...reducerDefaultValues, ...defaultValues },
  )
  const [load, abort] = createRequestDispatcher<T>(dispatch)
  return [state, load, abort]
}
