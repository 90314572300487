import './file-field.css'
import React from 'react'
import PropTypes from 'prop-types'

import { FileInput } from 'ui/form/file'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'FileField'

  static propTypes = {
    name: PropTypes.string.isRequired,
    upload: PropTypes.func.isRequired,
    validation: PropTypes.func,
  }

  getWrapperClassName() {
    return super.getWrapperClassName(
      'file',
      this.props.required && 'required',
      this.props.disabled && 'disabled',
      this.props.readOnly && 'read-only',
    )
  }

  renderField(props) {
    return (
      <>
        <FileInput valueToFilename={valueToFilename} {...props} />
        <div className="pseudo-input" />
      </>
    )
  }
}

export const FileField = withFormDefaultValues(Field)

export const valueToFilename = (value) => (value ? value?.file?.split('_', 2)[1] : null)
