import React from 'react'
import PropTypes from 'prop-types'

import { BaseInput } from './base-input'

export class FileInput extends BaseInput {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    validation: PropTypes.func,
    valueToFilename: PropTypes.func.isRequired,
  }
  static defaultProps = {
    valueToFilename: (f) => f,
  }

  state = {
    value: this.props.defaultValue,
    filename: this.props.valueToFilename(this.props.defaultValue),
    validationMessage: null,
  }

  setElement = (element) => {
    this.element = element
  }

  handleChange = async (event) => {
    const file = event.target.files[0]

    if (!file) {
      return
    }

    const { valueToFilename } = this.props
    this.setState({
      value: file,
      valid: true,
      touched: true,
      filename: valueToFilename(file),
    })
    await this.broadcastUpdates({
      value: file,
      validationMessage: null,
      valid: true,
      touched: true,
    })
  }

  handleBrowseFile = () => {
    this.element && this.element.click()
  }

  reset() {
    const { defaultValue, valueToFilename } = this.props
    const value = defaultValue || null
    this.setState({ filename: valueToFilename(value), value }, () => {
      this.broadcastUpdates({ value, touched: false })
    })
  }

  /**
   * @override
   */
  setValue(value) {
    this.setState(
      {
        value,
        fileName: this.props.valueToFilename(value),
      },
      () => {
        this.broadcastUpdates({ value: value || null })
      },
    )
  }
  /**
   * @override
   */
  getValue() {
    return this.state.value
  }

  isValid() {
    if (this.props.required && !this.getValue()) return false
    return !this.state.uploading && !this.state.validationMessage
  }

  getValidationMessage() {
    const { uploading, validationMessage } = this.state
    if (uploading) return 'Uploading'
    if (validationMessage) return this.state.validationMessage
    if (this.props.required && !this.getValue()) return 'Required'
    return null
  }
  willValidate() {
    return this.element.willValidate
  }

  focus() {
    this.element?.focus?.()
  }

  componentDidMount() {
    this.broadcastUpdates({ value: this.props.defaultValue })
  }

  render() {
    const { filename, uploading } = this.state
    const {
      validation,
      validationMessage,
      validationMessages,
      className,
      defaultValue,
      valueToFilename,
      label,
      required,
      children,
      ...props
    } = this.props
    return (
      <>
        <input
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
          {...props}
          noValidate
          tabIndex="-1"
          className="input"
          type="file"
          ref={this.setElement}
          id={props.id || props.name}
          onChange={this.handleChange}
          name={props.name}
        />

        <button
          className="browse-button"
          type="button"
          onClick={this.handleBrowseFile}
          disabled={uploading || props.disabled || props.readOnly}
        >
          {filename && <span className="file-name">{filename}</span>}
          {uploading ? 'Uploading...' : 'Browse...'}
        </button>
      </>
    )
  }
}
