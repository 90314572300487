/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import './main-header.css'
import React from 'react'

import { HamburgerMenu } from 'ui/hamburger-menu'
import { MenuGroup, Menu } from 'ui/menu'
import { Media } from 'ui/media'

import { SignedOut, SignedIn } from 'components/signed'
import { Logo } from 'components/logo'
import { NotificationIndicator } from 'components/notification-indicator'
import { useNotifications } from 'components/use-notifications'

import { routes } from 'const/routes'
import { cn } from 'utils'
import { Switch, Route } from 'router'

import { UserMenuItems, ProfileButton, IconAccount, UserName } from './nav-user-signed-in'
import { NavSignedOff } from './nav-user-signed-out'
import { useHeaderMenuItems, HeaderMenu } from './nav-main'

const DesktopContent = ({ className }) => {
  const items = useHeaderMenuItems()
  return (
    <>
      <HeaderMenu direction="horizontal" className="desktop" items={items} />
      <SignedOut>
        <NavSignedOff className="signed-out desktop" direction="horizontal" />
      </SignedOut>
      <SignedIn>
        <Menu dropdown toLeft className={cn('profile desktop', className)}>
          <MenuGroup label={<ProfileButton />} className="profile">
            <UserMenuItems />
          </MenuGroup>
        </Menu>
      </SignedIn>
    </>
  )
}

const MobileContent = () => {
  const items = useHeaderMenuItems()
  const {
    notifications: [list],
  } = useNotifications()
  return (
    <>
      <SignedIn>
        <IconAccount className="account-icon" />
        {list?.length > 0 && <NotificationIndicator className="mobile-account-icon" />}
      </SignedIn>
      <SignedOut>
        <div className="account-icon" />
      </SignedOut>
      <HamburgerMenu className="tablet-content">
        <HeaderMenu direction="vertical" className="tablet-nav" items={items} />
        {items.length > 0 && <hr />}
        <SignedOut>
          <NavSignedOff className="signed-out mobile" direction="vertical" />
        </SignedOut>
        <SignedIn>
          <UserName className="tablet-user-name" />
          <Menu direction="vertical" className="profile">
            <UserMenuItems />
          </Menu>
        </SignedIn>
      </HamburgerMenu>
    </>
  )
}

export const MainHeader = ({ className }) => {
  return (
    <Switch>
      <Route path={routes.addInvestment}>
        <header className={cn('main-header clean-layout', className)}>
          <Logo />
        </header>
      </Route>
      <Route>
        <header className={cn('main-header', className)}>
          <Logo />
          <Media query="--desktop">
            <DesktopContent />
          </Media>
          <Media query="--tablet-">
            <MobileContent />
          </Media>
        </header>
      </Route>
    </Switch>
  )
}
