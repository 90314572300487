import React from 'react'

import { TextInput } from 'ui/form'

import { BaseField, withFormDefaultValues } from '../base-field'

const validationMessages = { patternMismatch: 'Account Number should contain only digits' }

export class Field extends BaseField {
  static displayName = 'AccountNumberField'
  renderField(props) {
    // wrong native validation for number with 16,17 lengths digits
    // return <NumberInput {...props} min={100} max={999999999999999} />
    return (
      <TextInput
        {...props}
        minLength={3}
        maxLength={17}
        inputMode="numeric"
        pattern="[0-9]*"
        validationMessages={validationMessages}
      />
    )
  }
}
export const AccountNumberField = withFormDefaultValues(Field)
