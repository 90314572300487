interface Option {
  value: string
  label: string | HTMLElement
  title?: string
  disabled?: boolean
}

type Options = Record<string, string | HTMLElement> | Array<Option>

export const optionsConverter = (
  options: Options,
): Array<Array<string | number | HTMLElement | boolean>> =>
  Array.isArray(options)
    ? options.map(({ value, label, title, disabled }) => [value, label, title, disabled])
    : Object.entries(options)
