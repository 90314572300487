import { logSuccessResponse, logErrorResponse } from './logger'
import { createClient } from './http-client'

export const createClientWithLogger = (client = fetch) =>
  createClient(
    client,
    process.env.NODE_ENV === 'development' && logSuccessResponse,
    process.env.NODE_ENV === 'development' && logErrorResponse,
  )

export const createClientHelpers = (client) => ({
  get: (endpoint, params, config) =>
    client(
      endpoint,
      params,
      extendConfig(config, {
        method: 'GET',
      }),
    ),
  list: (endpoint, params, config = {}) =>
    client(
      endpoint,
      prepareListQuery(params),
      extendConfig(config, {
        method: 'GET',
      }),
    ).then(parseListResults),
  post: (endpoint, params, config = {}) =>
    client(endpoint, params, extendConfig(config, { method: 'POST' })),
  patch: (endpoint, params, config) =>
    client(endpoint, params, extendConfig(config, { method: 'PATCH' })),
  put: (endpoint, params, config) =>
    client(endpoint, params, extendConfig(config, { method: 'PUT' })),
  remove: (endpoint, config) =>
    client(endpoint, undefined, extendConfig(config, { method: 'DELETE' })),
  postFormData: (endpoint, data = {}, config) =>
    client(endpoint, toFormData(data), extendConfig(config, { method: 'POST' })),
  patchFormData: (endpoint, data = {}, config) =>
    client(endpoint, toFormData(data), extendConfig(config, { method: 'PATCH' })),
})

function extendConfig(config, extendedConfig) {
  return {
    ...config,
    ...extendedConfig,
    headers: {
      'X-Client-Timezone': new Date()?.getTimezoneOffset() ?? 0,
      ...config?.headers,
      ...extendedConfig?.headers,
    },
  }
}

export function toFormData(data) {
  const formData = new FormData()
  Object.entries(data).forEach(([name, value]) => formData.append(name, value))
  return formData
}

export function parseListResults(result) {
  return {
    items: result?.['hydra:member'] ?? [],
    totalItems: result?.['hydra:totalItems'] ?? 0,
  }
}

function prepareListQuery({ order, ...query } = {}) {
  if (!order) return query
  return {
    ...query,
    ...Object.fromEntries(Object.entries(order).map(([name, order]) => [`order[${name}]`, order])),
  }
}
