import './phone-field.css'
import React from 'react'

import { BasePhoneInput } from 'ui/form/phone-input'

import { cn } from 'utils'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'PhoneField'

  getWrapperClassName() {
    return super.getWrapperClassName(cn('phone-field', this.props?.required && 'required'))
  }

  renderField(props) {
    return <BasePhoneInput {...props} />
  }
}

export const PhoneField = withFormDefaultValues(Field)
