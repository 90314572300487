import PropTypes from 'prop-types'

import { useField } from '../context'

export const IsFieldModified = ({ name, not, children }) => {
  const { value, defaultValue } = useField(name)
  let result = value !== defaultValue
  if (not) {
    result = !result
  }
  return result ? children : null
}

IsFieldModified.propTypes = {
  // field name
  name: PropTypes.string.isRequired,
  not: PropTypes.bool,
}
