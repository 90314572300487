import './styles.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

export const NotificationIndicator = ({ className }) => {
  return <span className={cn('notification-indicator', className)} />
}

NotificationIndicator.propTypes = { className: PropTypes.string }
