import { useEffect } from 'react'

import { LoaderState, useLoaderState } from './loader-state'

export const useLoader = function <T>(
  /** A function to call */
  fn: (...any) => Promise<T>,
  /** Arguments passed to the <fn> */
  ...args: Array<any>
): LoaderState<T> {
  if (typeof fn !== 'function') throw new TypeError('not a function')

  const [{ pending, error, result }, load, abort] = useLoaderState<T>()

  useEffect(() => {
    load(fn, ...args)
    return abort
  }, args) // eslint-disable-line react-hooks/exhaustive-deps

  return { pending, error, result }
}
