import './checkbox-option.css'
import React from 'react'
import PropTypes from 'prop-types'

import { CheckboxTick } from 'ui/field/checkbox/checkbox-tick'

import { cn } from 'utils'

export const CheckboxOption = ({
  value,
  defaultValue,
  className,
  children,
  style,
  touched,
  ...props
}) =>
  props.name ? (
    <label className={cn('option checkbox-option', className)} style={style}>
      <input {...props} type="checkbox" value={value} />
      <CheckboxTick />
      <span className="option-label">{children}</span>
    </label>
  ) : (
    false
  )

CheckboxOption.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.shape({
    '--index': PropTypes.number,
  }),
  touched: PropTypes.bool,
  value: PropTypes.string,
}
