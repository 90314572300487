import React from 'react'
import PropTypes from 'prop-types'

import { BaseHtmlValidationInput } from './base-html-validation-input'

export class NumberInput extends BaseHtmlValidationInput {
  static propTypes = {
    autoComplete: PropTypes.string,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,

    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    validation: PropTypes.func,
    validationMessages: PropTypes.exact({
      valueMissing: PropTypes.string,
      badInput: PropTypes.string,
      rangeOverflow: PropTypes.string,
      rangeUnderflow: PropTypes.string,
      stepMismatch: PropTypes.string,
    }),
  }
  static defaultProps = {
    autoComplete: 'off',
  }

  handleWheel = () => {
    this.element?.blur()
  }

  handleBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = (event) => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about changes
    this.broadcastUpdates()
  }

  /** @override */
  getValidationMessage() {
    return this.checkValidityProps([VALIDITY_PROPS]) || this.checkCustomValidity()
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.broadcastUpdates({ value: this.getValue() })
  }
  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    const textValue = String(this.element.value).trim()
    if (textValue === '') return null
    const number = parseFloat(this.element.value)
    return Number.isNaN(number) ? undefined : number
  }

  render() {
    const { validation, validationMessage, validationMessages, ...props } = this.props
    return (
      <input
        data-lpignore="true"
        {...props}
        type="number"
        ref={this.setElement}
        id={props.id || props.name}
        onWheel={this.handleWheel}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}

const VALIDITY_PROPS = [
  'valueMissing',
  'badInput',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
]
