import { singleton } from 'utils/singleton-promise'

import { createClientWithLogger, createClientHelpers } from './client'
import { authStorage, userStorage } from './storage'

const client = createClientWithLogger()

export const refreshToken = singleton(() =>
  client(
    'users/refresh',
    { refreshToken: authStorage.refreshToken },
    { method: 'POST', headers: authStorage.getHeaders() },
  )
    .then(({ auth, user }) => {
      authStorage.auth = auth
      userStorage.value = user
      return { auth, user }
    })
    .catch(() => {
      authStorage.auth = null
      userStorage.value = null
    }),
)

const authenticatedClient = async (endpoint, params, config) => {
  if (!authStorage.isAuthenticated()) {
    return client(endpoint, params, config)
  }
  if (authStorage.isAccessTokenExpired()) {
    await refreshToken()
  }
  return client(endpoint, params, {
    ...config,
    headers: { ...config?.headers, ...authStorage.getHeaders() },
  })
}

const { get, list, post, patch, put, remove, postFormData, patchFormData } =
  createClientHelpers(authenticatedClient)

export { get, list, post, patch, put, remove, postFormData, patchFormData }
