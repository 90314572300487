import './secret-field.css'
import React from 'react'

import { TextInput } from 'ui/form/text-input'
import { Filled } from 'ui/form'
import { IconButton } from 'ui/button'

import IconHide from 'icons/visibility-hide.svg'
import IconShow from 'icons/visibility-show.svg'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'SecretField'
  state = {
    visible: false,
  }

  toggleVisibility = () => {
    this.setState((state) => ({ visible: !state.visible }))
  }

  getWrapperClassName() {
    return super.getWrapperClassName('secret-field')
  }

  renderField(props) {
    const { visible } = this.state
    return (
      <>
        <TextInput {...props} type={visible ? 'text' : 'password'} />
        <Filled name={this.props.name}>
          <IconButton
            onClick={this.toggleVisibility}
            className="secret-field-icon"
            disabled={props.disabled}
            title={visible ? 'Hide field value' : 'Show field value'}
          >
            {visible ? <IconHide /> : <IconShow />}
          </IconButton>
        </Filled>
      </>
    )
  }
}

export const SecretField = withFormDefaultValues(Field)
