import React from 'react'

import { BaseField, withFormDefaultValues } from 'ui/field/base-field'
import { FileDropdownInput } from 'ui/form/file-dropdown-input'

import { valueToFilename } from '../file'

export class Field extends BaseField {
  static displayName = 'DropzoneFileField'

  renderField() {
    return <FileDropdownInput {...this.props} valueToFilename={valueToFilename} />
  }
}

export const DropzoneFileField = withFormDefaultValues(Field)
