import './page-section.css'
import React, { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

import { CustomHTML } from './custom-html'

type ReactComponentLike =
  | string
  | ((props: any, context?: any) => any)
  | (new (props: any, context?: any) => any)

export interface ISection {
  as?: ReactComponentLike
  cols?: number
  theme?: 'elevated' | 'narrow'
  center?: boolean
  className?: string
  children?: ReactNode
}

export const Section: FC<ISection> = ({
  className,
  as: Component,
  cols,
  theme,
  center,
  ...props
}) => (
  <Component
    {...props}
    className={cn('page-section', 'cols-' + cols, center && 'center', theme, className)}
  />
)

// Section.propTypes = {
//   as: PropTypes.elementType,
//   center: PropTypes.bool,
//   cols: PropTypes.number,
//   theme: PropTypes.oneOf(['elevated', 'narrow']),
// }

Section.defaultProps = {
  as: 'section',
  cols: 1,
  theme: 'elevated',
}

export interface ISectionHTML extends Omit<ISection, 'theme'> {
  theme?: 'elevated'
  html: string
}

export const SectionHTML: FC<ISectionHTML> = ({ html, className, ...props }) => (
  <Section {...props} className={cn('section-html', className)}>
    <CustomHTML html={html} />
  </Section>
)

// SectionHTML.propTypes = {
//   as: PropTypes.elementType,
//   html: PropTypes.string.isRequired,
//   theme: PropTypes.oneOf(['elevated']),
// }
