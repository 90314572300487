import React from 'react'
import PropTypes from 'prop-types'

import { FormFieldEmitter } from './context'

export class BaseInput extends React.PureComponent {
  static propTypes = {
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    touched: PropTypes.bool,
    validation: PropTypes.func,
  }
  static defaultProps = {}

  static contextType = FormFieldEmitter

  recentState = {
    value: undefined,
    valid: undefined,
    enable: undefined,
    validationMessage: undefined,
    defaultValue: null,
    touched: false,
  }

  /**
   * @param {*} [value]
   * @returns {String}
   */
  checkCustomValidity(value = this.getValue()) {
    const { validation } = this.props
    return validation ? validation(value) || '' : ''
  }

  /**
   * Returns true when field must be included into form validation
   * @param {Object} [props]
   * @param {Boolean} props.disabled
   * @param {Boolean} props.readOnly
   * @param {String} props.name
   * @param {Boolean}
   */
  willValidate({ disabled, name } = this.props) {
    return !!name && !disabled
  }

  /**
   * @abstract
   */
  setValue(value) {
    this.broadcastUpdates({ value })
  }

  set value(val) {
    this.setValue(val)
  }
  get value() {
    return this.getValue()
  }

  /**
   * @abstract
   */
  getValue() {
    throw 'define .getValue()'
  }

  /**
   * Trigger native validation
   * Sets <state.touched> = true
   */
  validate() {
    this.broadcastUpdates({ touched: true })
  }

  /**
   * @abstract
   * @param {*} value
   * @returns {boolean}
   */
  isValid(value = this.getValue()) {
    if (!this.willValidate()) return true
    if (this.props.required) {
      return value !== null && value !== undefined && value !== ''
    }
    return true
  }

  /**
   * @protected
   * @param {BroadcastUpdatesConfig} config
   */
  broadcastUpdates({
    value = this.getValue(),
    valid = this.isValid(value),
    enable = this.willValidate(),
    validationMessage = this.getValidationMessage(),
    defaultValue = this.props.defaultValue,
    touched = this.props.touched,
  } = {}) {
    const emitter = this.context
    if (!emitter) {
      throw new Error(`Missing statusListener`)
    }

    if (
      value !== this.recentState.value ||
      valid !== this.recentState.valid ||
      enable !== this.recentState.enable ||
      validationMessage !== this.recentState.validationMessage ||
      defaultValue !== this.recentState.defaultValue ||
      touched !== this.recentState.touched
    ) {
      const { name } = this.props
      this.recentState = {
        value,
        valid,
        enable,
        validationMessage,
        defaultValue,
        touched,
      }
      emitter({ ...this.recentState, name, ref: this })
      this.props.onChange?.({ target: this, name, ...this.recentState })
    }
  }

  /**
   * @protected
   */
  getValidationMessage() {
    if (!this.willValidate() || this.isValid()) return null
    const { required } = this.props
    const value = this.getValue()
    if (value === null) {
      return required ? 'The field is required' : null
    }
    return null
  }

  reset() {
    this.setValue(this.props.defaultValue)
  }

  focus() {
    throw new Error('focus() not implemented')
  }
  scrollIntoView() {
    throw new Error('scrollIntoView() not implemented')
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.defaultValue !== prevProps.defaultValue) {
      this.setValue(this.props.defaultValue)
    } else {
      this.broadcastUpdates()
    }
  }

  componentWillUnmount() {
    this.broadcastUpdates({ enable: false })
  }
}

/**
 * @typedef {Object} BroadcastUpdatesConfig
 * @prop {*} value - current user input (inc. invalid bullshit)
 * @prop {Boolean} [valid]
 * @prop {String} [name]
 * @prop {Boolean} [enable]
 * @prop {Boolean} [touched]
 * @prop {String} [validationMessage]
 */
