import React from 'react'

import { TextInput } from 'ui/form'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'TextField'

  getWrapperClassName() {
    return super.getWrapperClassName('text')
  }

  renderField({ ...props }) {
    return <TextInput {...props} />
  }
}

export const TextField = withFormDefaultValues(Field)
