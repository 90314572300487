import { storage } from 'utils/session-storage'

type Listener<T> = (value: T | null) => void

export class SessionStorageEmitter<T> {
  _listeners = []

  storageKey = null

  constructor(storageKey: string) {
    this.storageKey = storageKey
    storage.addListener(storageKey, () => {
      this.notifyListeners()
    })
  }
  get value(): T | null {
    return storage.read(this.storageKey)
  }

  set value(value: T | null) {
    storage.write(this.storageKey, value)
    this.notifyListeners()
  }

  addListener(listener: Listener<T>): () => void {
    this._listeners.push(listener)
    return () => {
      this._listeners = this._listeners.filter((l) => l !== listener)
    }
  }

  protected notifyListeners(): void {
    const value = this.value
    this._listeners.forEach((listener) => listener(value))
  }
}
