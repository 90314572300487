import React, { FC, ReactNode } from 'react'

// eslint-disable-next-line import/no-cycle
import { ErrorBoundarySection } from 'ui/error-boundary'

import { Container as PureContainer } from './page-content'

export { PageHeader as Header } from './page-header'
export { ErrorSection } from './error-section'
export { Section } from './page-section'

export const Container: FC<{ children?: ReactNode }> = ({ children, ...props }) => (
  <PureContainer {...props}>
    <ErrorBoundarySection>{children}</ErrorBoundarySection>
  </PureContainer>
)
