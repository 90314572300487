import PropTypes from 'prop-types'

import { useFormValues } from '../context'

export const FormControl = ({ children }) => {
  const values = useFormValues()
  return children({ values })
}
FormControl.propTypes = {
  children: PropTypes.func.isRequired,
}
