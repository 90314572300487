import './custom-html.css'
import React, { FC } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

type ReactComponentLike =
  | string
  | ((props: any, context?: any) => any)
  | (new (props: any, context?: any) => any)

interface ICustomHTML {
  html: string
  as?: ReactComponentLike
  className?: string
}

export const CustomHTML: FC<ICustomHTML> = ({ html, as: Component, className, ...props }) => (
  <Component
    {...props}
    className={cn('custom-html', className)}
    dangerouslySetInnerHTML={{ __html: html }}
  />
)

// CustomHTML.propTypes = {
//   as: PropTypes.elementType,
//   html: PropTypes.string.isRequired,
// }

CustomHTML.defaultProps = {
  as: 'div',
}
