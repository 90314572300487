/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './menu.css'

import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  FC,
  ComponentProps,
  ReactNode,
  KeyboardEvent,
} from 'react'
import PropTypes from 'prop-types'
import debounce from 'debounce'

import { Button, ToggleButton } from 'ui/button'
import { NavLink } from 'ui/link'
import { ButtonProps } from 'ui/button/button'

import { cn } from 'utils'
import { RoutePath } from 'const/types'
import Pimp from 'icons/pimp.svg'

/**
 * @param {HTMLElement} element
 * @param {string} query
 */
const focusNextSibling = (element: EventTarget & Element, query: string) => {
  if (!element.nextElementSibling) return
  const next = query ? element.nextElementSibling.querySelector(query) : element.nextElementSibling

  if (next && next instanceof HTMLElement) {
    next.focus()
  }
}
/**
 * @param {HTMLElement} element
 * @param {string} query
 */
const focusPreviousSibling = (element: EventTarget & Element, query: string) => {
  if (!element.previousElementSibling) return
  const previous = query
    ? element.previousElementSibling.querySelector(query)
    : element.previousElementSibling

  if (previous && previous instanceof HTMLElement) {
    previous.focus()
  }
}

/**
 * @param {KeyboardEvent} event
 */
export const navigateKeyboard = (event: KeyboardEvent): void => {
  switch (event.key) {
    case 'Down': // IE/Edge specific value
    case 'ArrowDown':
      focusNextSibling(event.currentTarget, 'a,button')
      event.preventDefault()
      return
    case 'Up': // IE/Edge specific value
    case 'ArrowUp':
      focusPreviousSibling(event.currentTarget, 'a,button')
      event.preventDefault()
      return
  }
}

interface IMenu extends ComponentProps<'nav'> {
  direction?: 'horizontal' | 'vertical'
  dropdown?: boolean
  toLeft?: boolean
}

export const Menu: FC<IMenu> = ({
  direction,
  dropdown = false,
  toLeft = false,
  className,
  children,
  ...props
}) => {
  return (
    <nav
      {...props}
      className={cn('menu', direction, dropdown && 'dropdown', toLeft && 'to-left', className)}
    >
      <ul className="menu-list">{children}</ul>
    </nav>
  )
}
// Menu.propTypes = {
//   direction: PropTypes.oneOf(['horizontal', 'vertical']),
//   dropdown: PropTypes.bool,
//   toLeft: PropTypes.bool,
// }
Menu.defaultProps = {
  direction: 'horizontal',
}

interface IMenuGroup {
  open?: boolean
  className: string
  label: ReactNode
}

export const MenuGroup: FC<IMenuGroup> = ({ open = false, className, label, children }) => {
  const [isOpen, setOpen] = useState(open)
  const element = useRef<HTMLLIElement>()
  const button = useRef<HTMLButtonElement>()

  useEffect(() => {
    open !== isOpen && setOpen(open)
  }, [open])

  // const toggleOpen = () => {
  //   if (isOpen) return
  //   button.current && button.current.focus()
  //   setOpen(true)
  // }

  const toggleClose = () => {
    if (!isOpen) return
    if (isSameOrChildElement(element.current, document.activeElement)) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }
    setOpen(false)
  }

  const toggle = () => {
    button?.current.blur()
    setOpen(!isOpen)
  }

  const blur = () => {
    if (isSameOrChildElement(element.current, document.activeElement)) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }
  }

  const validateFocus = useCallback(
    debounce(() => {
      if (!isSameOrChildElement(element.current, document.activeElement)) {
        toggleClose()
      }
    }, 50),
    [isOpen],
  )

  return (
    <li
      className={cn('menu-group', className, isOpen && 'open')}
      onBlur={validateFocus}
      onMouseOut={validateFocus}
      ref={element}
    >
      <Button onClick={toggle} className="menu-group-toggle desktop" ref={button}>
        {label}
        <Pimp className="menu-group-toggle-pimp" />
      </Button>
      <ToggleButton className="menu-group-toggle tablet">
        {label}
        <Pimp className="menu-group-toggle-pimp" />
      </ToggleButton>
      <SideMenuList onClick={blur}>{children}</SideMenuList>
    </li>
  )
}
// MenuGroup.propTypes = {
//   label: PropTypes.node,
//   open: PropTypes.bool,
// }

export const MenuLink: FC<NavLink> = ({ className, children, ...props }) => {
  return (
    <li className={cn('menu-item', className)} onKeyUp={navigateKeyboard}>
      <NavLink {...props} className="menu-link nav-link">
        {children}
      </NavLink>
    </li>
  )
}
// MenuLink.propTypes = {
//   to: RoutePath,
// }

export const SideMenu: FC<ComponentProps<'nav'>> = ({ children, className, ...props }) => {
  return (
    <nav {...props} className={cn('side-menu', className)}>
      <SideMenuList> {children} </SideMenuList>
    </nav>
  )
}

export const SideMenuList: FC<ComponentProps<'ul'>> = ({ children, className, ...props }) => {
  return (
    <ul className={cn('side-menu-list', className)} {...props}>
      {children}
    </ul>
  )
}

export const MenuButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <li className={cn('menu-item', className)} onKeyUp={navigateKeyboard}>
      <Button type="button" {...props} className="menu-link menu-button" />
    </li>
  )
}

export const MenuTitle: FC<ComponentProps<'li'>> = ({ className, ...props }) => {
  return <li {...props} className={cn('menu-item menu-title', className)} />
}

export function isSameOrChildElement(parent: Element, child: Element): boolean {
  return parent && child && (parent === child || parent.contains(child))
}
