import './field.css'
import React from 'react'
import PropTypes from 'prop-types'

import { useFormProps } from 'ui/form'
import { useField } from 'ui/form/context'
import { ValidationMessage } from 'ui/form/validation-message'

import { cn } from 'utils'

import { Label } from './label'
import { Description } from './description'

export class BaseField extends React.PureComponent {
  static propTypes = {
    description: PropTypes.node,
    hideValidationMessage: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    touched: PropTypes.bool,
    validationMessage: PropTypes.string,
  }
  static defaultProps = {
    hideValidationMessage: false,
  }

  element = React.createRef()

  setValue(value) {
    if (this.element.current) this.element.current.value = value
  }
  set value(val) {
    this.setValue(val)
  }

  getValue() {
    return this.element.current?.value
  }
  get value() {
    return this.getValue()
  }

  /** @private */
  get label() {
    return this.props.label
  }

  /** @protected */
  getWrapperClassName(...args) {
    return cn('field', this.props.className, ...args)
  }

  /** @private */
  get className() {
    return this.getWrapperClassName(this.props.className)
  }

  focus() {
    this.element?.current?.focus && this.element.current.focus()
  }

  /** @protected */
  shouldShowValidity() {
    const { hideValidationMessage, touched, validationMessage } = this.props
    return !hideValidationMessage && touched && !!validationMessage
  }

  /** @protected */
  getInputClassName() {
    const { touched, validationMessage } = this.props
    return cn(
      'input',
      touched && 'touched',
      this.shouldShowValidity() && validationMessage && 'invalid',
    )
  }

  /** @protected */
  getInputProps() {
    const {
      // get rid of these props:
      validationMessage,
      label,
      hideValidationMessage,
      className,
      children,
      touched,
      description,
      // the rest are passed to input
      ...props
    } = this.props
    return {
      ...props,
      touched,
      ref: this.element,
      validationMessage,
      className: this.getInputClassName(),
    }
  }

  renderDescription() {
    const description = this.props.description
    return description ? <Description>{description}</Description> : false
  }

  /** @protected */
  renderField() {
    throw 'override renderField(props)'
  }

  /** @protected */
  renderValidationMessage(props = this.props) {
    const { validationMessage } = props
    return <ValidationMessage error>{validationMessage}</ValidationMessage>
  }

  render() {
    return (
      <Label
        label={this.label}
        name={this.props.name}
        id={this.props.id}
        className={this.className}
      >
        {this.renderDescription()}
        {this.renderField(this.getInputProps())}
        {this.props.children}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </Label>
    )
  }
}

export function withFormDefaultValues(Component) {
  // eslint-disable-next-line react/prop-types
  const WrappedComponent = React.forwardRef((props, ref) => {
    const { name } = props
    const { defaultValues, disabled, readOnly } = useFormProps()
    const { touched, validationMessage } = useField(name)

    const defaultValue = defaultValues.hasOwnProperty(name) ? defaultValues[name] : undefined

    return (
      <Component
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
        touched={touched}
        validationMessage={validationMessage}
        ref={ref}
      />
    )
  })
  const displayName = Component.displayName || Component.name || 'Field'
  WrappedComponent.displayName = `withFormDefaults(${displayName})`

  WrappedComponent.propTypes = Component.propTypes

  WrappedComponent.defaultProps = Component.defaultProps
  return WrappedComponent
}
