import './error-section.css'
import React, { FC, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Section, ISection } from 'ui/page/page-section'

import { cn } from 'utils'
import { api } from 'api'

type Error =
  | string
  | {
      error: string
    }

interface ErrorSection extends ISection {
  error?: string
  fullError?: Error
}

const sendError = (error) => {
  if (!error) return

  try {
    const formattedError = JSON.stringify(error, Object.getOwnPropertyNames(error))
    api.errors.send({ errorUI: formattedError })
  } catch (e) {
    // nothing
  }
}

export const ErrorSection: FC<ErrorSection> = ({
  className,
  error,
  children,
  fullError,
  ...props
}) => {
  useEffect(() => {
    sendError(fullError)
  }, []) //eslint-disable-line
  return (
    <Section {...props} className={cn('error-section', className)}>
      {(error && <h1>{error}</h1>) || children}
    </Section>
  )
}
// ErrorSection.propTypes = {
//   error: PropTypes.string,
// }
