import { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'

import { useField } from '../context'

const createMatchValidator = (matchValue, label) => (value) =>
  value === matchValue ? null : `Must match ${label}`

export const AddMatchFieldValidation = ({ name, label, children }) => {
  const { value } = useField(name)

  return cloneElement(Children.only(children), {
    validation: createMatchValidator(value, label),
  })
}

AddMatchFieldValidation.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}
