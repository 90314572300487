import './validation-message.css'
import React, { ReactNode } from 'react'

import { cn } from 'utils'

type ErrorMessageProps = {
  children: ReactNode
  success?: false
  error: true
}
type SuccessMessageProps = {
  children: ReactNode
  success: true
  error?: false
}
type ValidationMessageProps = {
  className?: string
} & (ErrorMessageProps | SuccessMessageProps)

export const ValidationMessage = React.forwardRef<HTMLDivElement, ValidationMessageProps>(
  ({ className, error, success, children }, ref) => {
    if (error && success) {
      throw new Error('ValidationMessage can not be "error" and "success" at the same time')
    }
    return (
      <div
        className={cn(className, 'validation-message', error && 'error', success && 'success')}
        ref={ref}
      >
        {children}
      </div>
    )
  },
)
ValidationMessage.displayName = 'ValidationMessage'
