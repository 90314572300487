import { LIMIT_PARAM, SEARCH_PARAM, OFFSET_PARAM } from 'const/list'

export const getParamFromUrl = (
  url: string,
  name: string,
  defaultValue: string | number = null,
): string => {
  const urlSearchParam = new URLSearchParams(url)
  return urlSearchParam.has(name)
    ? urlSearchParam.get(name)
    : defaultValue === '' || defaultValue === null || defaultValue === undefined
    ? null
    : String(defaultValue)
}

export const getAllParamsFromUrl = (url: string): Record<string, string> => {
  const urlSearchParam = new URLSearchParams(url)
  return Object.fromEntries(urlSearchParam.entries())
}

export const fulfillUrlWithParam = (url: string, name: string, value = null): string => {
  const urlSearchParam = new URLSearchParams(url)
  if (!value) {
    urlSearchParam.has(name) && urlSearchParam.delete(name)
  } else {
    urlSearchParam.set(name, value)
  }
  return '?' + urlSearchParam.toString()
}

export const fulfillUrlWithParameters = (url: string, params: Record<string, string>): string => {
  const urlSearchParam = new URLSearchParams(url)
  Object.entries(params).map(([name, value]) => {
    if (!value) {
      urlSearchParam.has(name) && urlSearchParam.delete(name)
    } else {
      urlSearchParam.set(name, value)
    }
  })
  return '?' + urlSearchParam.toString()
}

export type ListURLParams = {
  page?: number
  sort?: string
  search?: string
  [LIMIT_PARAM]?: number
  orderBy?: string
  order?: string
}

export const getListParams = (
  url: string,
  { [OFFSET_PARAM]: page, sort, search, [LIMIT_PARAM]: limit }: ListURLParams = {},
): ListURLParams => {
  const [orderBy, order] = (getParamFromUrl(url, sort) || sort || '').split('=')
  return {
    [LIMIT_PARAM]: Number(getParamFromUrl(url, LIMIT_PARAM, limit)),
    search: getParamFromUrl(url, SEARCH_PARAM, search),
    page: Number(getParamFromUrl(url, OFFSET_PARAM, page)),
    orderBy,
    order,
  }
}

export const pathWithFutureRedirect = (
  path: string,
  futurePath?: string,
): string | { pathname: string; state?: { redirect?: string } } =>
  futurePath ? { pathname: path, state: { redirect: futurePath } } : path

const toUrlParamsObject = (
  source: Record<string, string>,
): [Record<string, string[]>, Record<string, string>] => {
  const arrayProps = {}
  const plainProps = {}
  Object.entries(source).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      arrayProps[key] = value
    } else {
      plainProps[key] = String(value)
    }
  })
  return [arrayProps, plainProps]
}

export const toSearchParamsString = (obj: Record<string, string>): string => {
  const [arrays, plains] = toUrlParamsObject(obj)

  const urlSearchParam = new URLSearchParams(plains)

  Object.entries(arrays).forEach(([key, values]) => {
    values.forEach((value) => {
      urlSearchParam.append(`${key}[]`, value)
    })
  })

  return '?' + urlSearchParam.toString()
}
