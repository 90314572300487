import './label.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

export const LabelText = ({ className, children, as: Component, ...props }) =>
  children ? (
    <Component {...props} className={cn('label-text', className)}>
      {children}
    </Component>
  ) : (
    false
  )

LabelText.defaultProps = {
  as: 'span',
}

LabelText.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
}

export const Label = ({ name, id, label, children, className, ...props }) => (
  <label {...props} htmlFor={id ?? name} className={cn('label', className)}>
    {children}
    <LabelText>{label}</LabelText>
  </label>
)

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
}
