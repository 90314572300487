/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import { userStorage } from 'api/storage'

export const useUser = () => {
  const [user, setUser] = useState(userStorage.value)
  useEffect(() => {
    const unsubscribe = userStorage.addListener((user) => {
      setUser(user)
    })
    return unsubscribe
  }, [])
  return user
}

export const useUserMatchRole = (value) => {
  const user = useUser()
  if (!user) return false
  if (Array.isArray(value)) return user.roles.some((role) => value.includes(role))
  if (typeof value === 'string') return user.roles.includes(value)
  throw `unhandled user role: ${String(value)}`
}

export const useUserConfirmed = () => {
  const user = useUser()
  return user?.confirmed
}

export const useUserMatchType = (value) => {
  const user = useUser()
  return user?.type === value
}

export const useSupervisorAccessGranted = () => {
  const user = useUser()
  return user?.supervisorAccess
}
