import './description.css'
import React, { useCallback, useState } from 'react'

import { cn } from 'utils'

import { IconButton } from '../button'

export const Description = ({ children }) => {
  const [show, setShow] = useState(false)
  const toggleShow = useCallback(() => setShow(!show), [show])
  return (
    <div className="description">
      <IconButton onClick={toggleShow}>{show ? '×' : '+'}</IconButton>
      <div className={cn('info', show && 'open')}>{children}</div>
    </div>
  )
}
