import './checkbox-tick.css'
import React from 'react'

import Icon from 'icons/checkbox-checked.svg'

export const CheckboxTick = () => (
  <span className="checkbox-tick">
    <Icon className="checkbox-tick-icon" />
  </span>
)
