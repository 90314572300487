import React, { useContext } from 'react'

export const FormFieldEmitter = React.createContext({})

export const FormPropsContext = React.createContext({
  disabled: false,
  readOnly: false,
  pending: false,
  modified: false,
  valid: false,
  defaultValues: {},
})

export const useFormProps = () =>
  useContext(FormPropsContext) || {
    defaultValues: {},
    disabled: false,
    readOnly: false,
    pending: false,
    modified: false,
    valid: false,
  }

export const useFormValues = () => {
  const { fields } = useFormProps()
  const entries = Object.entries(fields).map(([name, { value }]) => [name, value])
  return Object.fromEntries(entries)
}

export const useField = (name) => {
  const { fields } = useContext(FormPropsContext)
  return (fields && fields[name]) || {}
}
