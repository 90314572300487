import './date-field.css'
import React from 'react'
import PropTypes from 'prop-types'

import { DateInput } from 'ui/form'

import { isFirefox } from 'utils'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'DateField'
  getWrapperClassName() {
    return super.getWrapperClassName('date', isFirefox() && 'date-firefox')
  }
  getNormalizedValue(date) {
    if (!date) return null

    if (date instanceof Date) {
      return date.toISOString().replace(/T.*/, '')
    }

    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}/.test(date)) {
      return date.replace(/T.*/, '')
    }
    return null
  }
  renderField({ defaultValue, ...props }) {
    return <DateInput {...props} defaultValue={this.getNormalizedValue(defaultValue)} />
  }
}

export const DateField = withFormDefaultValues(Field)

DateField.propTypes = {
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  validation: PropTypes.func,
}
