import { useEffect, useState } from 'react'

import { unauthorizedLogOutStorage } from 'api/storage'

export const useUnauthorized = () => {
  const [unauthorized, setUnauthorized] = useState(unauthorizedLogOutStorage.value)
  useEffect(() => {
    const unsubscribe = unauthorizedLogOutStorage.addListener((unauthorized) => {
      setUnauthorized(unauthorized)
    })
    return unsubscribe
  }, [])
  return unauthorized
}
