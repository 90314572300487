import { useEffect } from 'react'

import { api } from 'api'
import { userStorage, wcodeEnableSavingStorage, wcodeStorage } from 'api/storage'

import { useWcode } from './use-wcode'

const delay = 1000

const errorsResendCountLimit = 2
const wcodeResendCountLimit = 20

const sendWcodeError = async (wCode, user, error) => {
  let errorsResendCount = 0
  const send = async () => {
    try {
      await api.errors.send({
        userName: user?.name,
        userEmail: user?.email,
        userUuid: user?.uuid,
        endpoint: `POST api/wcodes/investors`,
        wCode,
        error,
      })
    } catch (e) {
      if (errorsResendCount < errorsResendCountLimit) {
        errorsResendCount++
        await new Promise((res) => setTimeout(res, delay))
        await send()
      }
    }
  }
  await send()
}

const sendWcodeToBack = async (wCode, resendCount) => {
  const user = userStorage.value
  const wcodeSendingEnable = wcodeEnableSavingStorage.value
  if (!wCode || !user) {
    return
  }

  try {
    await api.wcodes.send({
      wCode,
      timestamp: new Date().getTime(),
      investorUuid: user?.uuid,
    })
    wcodeStorage.value = null
    wcodeEnableSavingStorage.value = true
  } catch (e) {
    if (wcodeSendingEnable) {
      if (resendCount >= wcodeResendCountLimit) {
        wcodeEnableSavingStorage.value = false
        return
      }
      resendCount++
      await sendWcodeError(wCode, user, e)
      await new Promise((res) => setTimeout(res, delay))
      await sendWcodeToBack(wCode, resendCount)
    }
  }
}

export const WcodesHandler = () => {
  const wCode = useWcode()

  useEffect(() => {
    let resendCount = 0
    sendWcodeToBack(wCode, resendCount)
  }, [wCode])
  return false
}
