import './main-footer.css'
import React from 'react'

import { MenuLink, Menu } from 'ui/menu'
import { CustomHTML } from 'ui/page/custom-html'

import { usePortalPages } from 'components/portal-pages-provider'
import { usePortal } from 'components/portal-provider'
import { Logo, PoweredByLogo } from 'components/logo'

export const MainFooter = () => {
  const { pages } = usePortalPages()
  const { copyright, default: defaultPortal } = usePortal()
  return (
    <footer className="main-footer">
      <header>
        <Logo />
        <div>
          {!defaultPortal && <PoweredByLogo />}
          {copyright && <CustomHTML html={copyright} />}
        </div>
      </header>
      <Menu className="main-nav">
        {pages?.length > 0 &&
          pages.map(
            ({ uuid, url, title, menu }) =>
              url &&
              menu !== 'header' && (
                <MenuLink to={url} key={uuid}>
                  {title}
                </MenuLink>
              ),
          )}
      </Menu>
    </footer>
  )
}
