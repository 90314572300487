import './throbber.css'
import * as React from 'react'

import { cn } from 'utils'

export const Throbber: React.FC<React.HTMLAttributes<HTMLDivElement>> = function ({
  className,
  ...props
}) {
  return (
    <div {...props} className={cn('throbber', className)}>
      <div />
      <div />
      <div />
    </div>
  )
}

export const LongTimeThrobber: React.FC<React.HTMLAttributes<HTMLDivElement>> = function ({
  className,
  title = 'Please wait... This might take a few seconds...',
  ...props
}) {
  return (
    <div className={cn('long-time-pending', className)}>
      <h3>{title}</h3>
      <Throbber />
    </div>
  )
}
