import React, { useContext } from 'react'

import { DefaultErrorHandler } from 'ui/data-loader/loader'

import { useLoader } from 'components/use-loader'

import { api } from 'api'

export const PortalContext = React.createContext()
export const PortalProvider = ({ children }) => {
  const { error, pending, result: portal } = useLoader(api.portal.get)
  if (error) return <DefaultErrorHandler error={error?.error || error} />
  return <PortalContext.Provider value={{ ...portal, pending }}>{children}</PortalContext.Provider>
}

export const usePortal = () => {
  return useContext(PortalContext)
}
