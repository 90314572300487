import React from 'react'
import PropTypes from 'prop-types'

import { Button, IconButton } from 'ui/button'

import IconHide from 'icons/visibility-hide.svg'
import IconShow from 'icons/visibility-show.svg'

import { BaseField } from '../base-field'
import { Field as TextField } from '../text'
import { Label } from '../label'

export const secureValue = (value, end = -3) =>
  value?.slice(0, end).replace(/\d/g, '*') + value?.slice(end)

export class SecureField extends BaseField {
  static propTypes = {
    component: PropTypes.elementType,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    excludedProps: PropTypes.array,
    isOpen: PropTypes.bool,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    title: PropTypes.string,
    touched: PropTypes.bool,
    validation: PropTypes.func,
    visibleValue: PropTypes.string,
  }

  state = {
    isOpen: false,
    visible: false,
  }

  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }

  toggleVisible = () => {
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  /** @private */
  get className() {
    return this.getWrapperClassName(this.props.className)
  }

  wrapLabel(label) {
    const { visible } = this.state
    const { visibleValue } = this.props
    const isEditable = !this.props.disabled && !this.props.readOnly
    return this.props.isOpen || !isEditable ? (
      label
    ) : !this.props.defaultValue ? (
      label
    ) : this.state.isOpen ? (
      <>
        {label}&nbsp;
        <Button
          className="inline"
          onClick={this.toggleOpen}
          title={(this.props.title || label) + ' discard edit'}
        >
          Discard
        </Button>
      </>
    ) : (
      <>
        {label}&nbsp;
        <Button
          className="inline"
          onClick={this.toggleOpen}
          title={(this.props.title || label) + ' edit'}
        >
          Edit
        </Button>
        {visibleValue && (
          <IconButton
            className="inline"
            onClick={this.toggleVisible}
            title={visible ? 'Show field value' : 'Hide field value'}
          >
            {visible ? <IconShow /> : <IconHide />}
          </IconButton>
        )}
      </>
    )
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate && super.componentDidUpdate()
    //touched changed true => false when form was reset
    if (!this.props.touched && prevProps?.touched) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: false })
    }
  }

  renderSecuredField({ defaultValue, isOpen: isOpenFromProps, ...props }) {
    const { isOpen, visible } = this.state
    const { visibleValue } = this.props

    if (isOpenFromProps || isOpen) {
      return this.renderField({ ...props })
    }

    let value
    if (visibleValue) {
      value = visible ? visibleValue : secureValue(defaultValue, -4)
    } else if (defaultValue) {
      value = defaultValue.replace(/\*/g, '∗')
    }

    if (value) {
      return <TextField {...props} defaultValue={value} disabled type="text" />
    }

    return this.renderField({ ...props })
  }

  /** @protected */
  renderField() {
    throw 'override renderField(props)'
  }

  render() {
    return (
      <Label label={this.wrapLabel(this.label)} name={this.props.name} className={this.className}>
        {this.renderDescription()}
        {this.renderSecuredField(this.getInputProps())}
        {this.props.children}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </Label>
    )
  }
}
