import './mde-field.css'
import React from 'react'

import { MdeInput } from 'ui/form/mde-input'

import { cn } from 'utils'

import { BaseField, withFormDefaultValues } from '../base-field'

export class Field extends BaseField {
  static displayName = 'MdeField'

  getWrapperClassName() {
    return super.getWrapperClassName(cn('mde-field', this.props?.required && 'required'))
  }

  renderField(props) {
    return <MdeInput {...props} />
  }
}

export const MdeField = withFormDefaultValues(Field)
