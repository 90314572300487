/* eslint-disable no-console */
import React, { ComponentType } from 'react'
import PropTypes from 'prop-types'

type Error =
  | string
  | {
      error: string
    }

export interface IErrorBoundaryProps {
  component: ComponentType<{ error?: null | string; fullError: Error }>
  onError?: (error: string) => void
}

interface IErrorBoundaryState {
  error: null | string
  fullError: null | Error
}

export class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  state = {
    error: null,
    fullError: null,
  }

  // static propTypes = {
  //   component: PropTypes.func,
  //   onError: PropTypes.func,
  // }

  static getDerivedStateFromError(error: Error) {
    if (error instanceof Error) {
      console.error(error)
      return { error: error.message, fullError: error }
    }
    if (typeof error === 'string') {
      return { error, fullError: error }
    }
    if (typeof error?.error !== 'string') {
      // Unknown error
      console.error(error)
      return { error: 'Something went wrong', fullError: error }
    }
    return { error, fullError: error }
  }

  componentDidUpdate(prevProps: IErrorBoundaryProps, prevState: IErrorBoundaryState) {
    const { error } = this.state
    const { onError } = this.props
    if (onError && error && (!prevState || prevState.error !== error)) {
      onError(error)
    }
  }

  // componentDidCatch(error, errorInfo) {
  //   // console.error(errorInfo)
  //   // console.error(error)
  // }

  render() {
    const { children, component: Component } = this.props

    if (this.state.error) {
      return <Component error={this.state.error} fullError={this.state.fullError} />
    }
    return children
  }
}
