import React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import { cn } from 'utils'

import { BaseInput } from './base-input'

export class BasePhoneInput extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    validation: PropTypes.func,
  }
  static defaultProps = {
    autoComplete: 'off',
    enableSearch: true,
    type: 'text',
  }

  state = {
    ...super.state,
    value: this.props.defaultValue || null,
    country: 'us',
    dialCode: '',
    reset: false,
  }

  /** @type {HTMLInputElement} */
  element = null
  _unmounted = false
  defaultCountry = 'us'
  dialCode = ''
  firstTouch = true

  setElement = (element) => {
    this.element = element
    if (!element || this._unmounted) return
    const value = this.props.defaultValue || null
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  onBlur = (e) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange = (value, data) => {
    let formattedValue = value
    let dialCode = data.dialCode

    // handle browser autofill and copy-paste phones without county codes
    if (this.firstTouch && value.length === 10) {
      dialCode = '1'
      formattedValue = dialCode + value
    }
    this.setState({ formattedValue })
    this.firstTouch = false
    this.dialCode = dialCode
    this.setValue(formattedValue)
  }

  setValue(value = null) {
    const normalValue = this.getNormalizedValue(value)
    const validationMessage = this.getValidationMessage(normalValue)
    this.broadcastUpdates({ value: normalValue, validationMessage, valid: !validationMessage })
    this.state.value !== normalValue && this.setState({ value: normalValue })
  }

  /**
   * @override
   */
  getValue() {
    return this.getNormalizedValue(this.state.value)
  }

  getNormalizedValue(value) {
    if (value === undefined || value === null) {
      return ''
    }
    if (typeof value === 'string' && String(value).trim() === '') {
      return ''
    }
    return value.replace(/\D/g, '')
  }

  getDefaultValue(defaultValue = this.props.defaultValue) {
    return this.getNormalizedValue(defaultValue)
  }

  getValidationMessage(value = this.getValue()) {
    if (!this.willValidate()) return null

    const { required, validation } = this.props
    if ((!value || (this.dialCode && !value.slice(this.dialCode.length))) && required) {
      return 'The field is required'
    }
    return (validation && validation(value)) || null
  }

  broadcastUpdates({
    value = this.getValue(),
    defaultValue = this.getDefaultValue(),
    ...props
  } = {}) {
    return super.broadcastUpdates({
      ...props,
      value: this.getNormalizedValue(value),
      defaultValue: this.getNormalizedValue(defaultValue),
    })
  }

  validate() {
    this.broadcastUpdates({ touched: true })
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.defaultCountry = this.element.state.selectedCountry.iso2
      //is needed for validation
      this.dialCode = this.element.state.selectedCountry.dialCode
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()
    if (
      prevProps &&
      this.props.defaultValue !== prevProps.defaultValue &&
      this.props.defaultValue !== this.state.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.defaultValue })
    }
  }

  componentWillUnmount() {
    this._unmounted = true
    super.componentWillUnmount()
  }

  reset() {
    const reset = this.state.reset
    //give know for a library that it needs to reset
    const resetState = () => this.setState({ reset: !reset })
    //create promise from setState
    const updateReset = new Promise((resolve) => setTimeout(resolve, resetState()))
    //update value to initial after reset, is needed for the formatting default value
    updateReset.then(() => this.setState({ value: this.props.defaultValue }))
  }

  focus() {
    this.element?.numberInputRef?.focus?.()
  }

  render() {
    const {
      validation,
      validationMessage,
      defaultValue,
      value,
      required,
      readOnly,
      disabled,
      enableSearch,
      placeholder,
      country,
      className,
      autoComplete,
      ...props
    } = this.props

    const touched = className?.includes('touched')
    const invalid = className?.includes('invalid')

    return (
      <PhoneInputWithReset
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={this.state.value || ''}
        defaultCountry={this.defaultCountry}
        country={this.props.defaultValue ? '' : country || 'us'}
        disableDropdown={disabled || readOnly}
        enableSearch={enableSearch}
        buttonClass={cn(
          disabled && 'disabled',
          readOnly && 'read-only',
          touched && 'touched',
          invalid && 'invalid',
        )}
        inputClass={className}
        inputProps={{
          'data-lpignore': 'true',
          id: props.id || props.name,
          name: props.id || props.name,
          required,
          readOnly,
          disabled,
          autoComplete,
        }}
        placeholder={placeholder || ''}
        reset={this.state.reset}
      />
    )
  }
}

export class PhoneInputWithReset extends PhoneInput {
  //added reset for library
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate && super.componentDidUpdate(prevProps, prevState)
    if (prevProps.reset !== this.props.reset) {
      const defaultSelectedCountry = this.state.onlyCountries?.find(
        (o) => o.iso2 === this.props.defaultCountry,
      )
      super.setState({
        country: this.props.defaultCountry,
        selectedCountry: defaultSelectedCountry,
      })
    }
  }
}
