import React, { ComponentProps, FC } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

export const ExternalLink: FC<ComponentProps<'a'>> = ({ className, href, children, ...props }) => (
  <a
    {...props}
    href={href}
    className={cn('link external', className)}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
}
