import React from 'react'
import PropTypes from 'prop-types'

import { withFormDefaultValues } from 'ui/field/base-field'
import { DateInput } from 'ui/form'

import { SecureField } from '../secure-field'

export class Field extends SecureField {
  static displayName = 'SecureDOBField'
  getWrapperClassName() {
    return super.getWrapperClassName('date')
  }
  renderField(props) {
    return <DateInput {...props} />
  }
}

export const SecureDOBField = withFormDefaultValues(Field)

SecureDOBField.propTypes = {
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  validation: PropTypes.func,
}
