import './styles.css'
import React from 'react'
import PropTypes from 'prop-types'

import { ValidationMessage } from 'ui/form/validation-message'
import { TextInput } from 'ui/form'

import Valid from 'icons/validation-ok.svg'
import InValid from 'icons/validation-bad.svg'
import UnTouched from 'icons/validation-untouched.svg'

import { withFormDefaultValues, BaseField } from '../base-field'

const validation = [
  {
    test: (password) => !!password && password.length >= 8 && password.length <= 24,
    message: 'Password must be 8-24 characters and',
  },
  {
    test: (password) => !!password && password.match(/.*[A-Z].*/),
    message: 'Must contain at least 1 upper case',
  },
  {
    test: (password) => !!password && password.match(/.*\d.*/),
    message: 'Must contain at least 1 number',
  },
  {
    test: (password) => !!password && password.match(/.*\W.*/),
    message: 'Must contain at least 1 special character',
  },
]

const validatePassword = (password) => {
  let allErrors = ''
  for (const { test, message } of validation) {
    if (!test(password)) {
      allErrors = allErrors.concat(`,${message}`)
    }
  }
  return allErrors
}

class Field extends BaseField {
  static displayName = 'NewPasswordField'

  static propTypes = {
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  }
  static defaultProps = {
    label: 'Password',
  }

  validate = (value) => {
    if (!value) return 'required'
    return validatePassword(value)
  }

  renderField({ ...props }) {
    return (
      <TextInput
        {...props}
        type="password"
        autoComplete="new-password"
        validation={this.validate}
      />
    )
  }

  shouldShowValidity() {
    return true
  }

  renderValidationMessage() {
    const { validationMessage, touched } = this.props
    const value = this.getValue()
    if (validationMessage) {
      return validation.map(({ test, message }) => {
        const valid = !!test(value)
        return (
          <ValidationMessage key={message} success={valid} error={!valid && touched}>
            <span className="password-message">
              {valid ? <Valid /> : !touched ? <UnTouched /> : <InValid />}
              {message}
            </span>
          </ValidationMessage>
        )
      })
    }
    return (
      <ValidationMessage success>
        <span className="password-message">
          <Valid />
          All password requirements met
        </span>
      </ValidationMessage>
    )
  }
}

export const NewPasswordField = withFormDefaultValues(Field)
