/* eslint-disable react/no-unused-state */

import './form.css'
import React from 'react'
import PropTypes from 'prop-types'

import { ValidationMessage } from 'ui/form/validation-message'

import { cn } from 'utils'

import { FormFieldEmitter, FormPropsContext } from './context'

/**
 * Forms, forms, forms...
 */
export class Form extends React.PureComponent {
  static propTypes = {
    defaultValues: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: (props, propName, componentName) => {
      const propType = typeof props[propName]
      if (props.readOnly || props.disabled || propType === 'function') return
      if (propType === 'undefined') {
        return (
          'Failed prop type: Invalid prop `onSubmit` of type `' +
          propType +
          ' supplied to ' +
          componentName +
          ', expected `function`'
        )
      }
      return new Error(
        'The prop `' +
          propName +
          '` is marked as required in' +
          ' `' +
          componentName +
          '` , but its value is `undefined`',
      )
    },
    readOnly: PropTypes.bool,
    redirect: PropTypes.func,
    validation: PropTypes.func,
  }

  static defaultProps = {
    defaultValues: {},
  }

  /**
   * @type {{ current: HTMLFormElement }}
   */
  element = React.createRef()

  /**
   * @type {{ current: HTMLDivElement }}
   */
  errorMessageRef = React.createRef()

  fieldRefs = {}

  /** @private */
  setFieldStatus = ({
    value = null,
    name,
    enable = true,
    defaultValue = null,
    validationMessage,
    touched,
    ref,
  } = {}) => {
    if (!name) throw 'field name is undefined'

    if (ref) {
      this.fieldRefs[name] = ref
    }

    return this.updateFieldState(name, {
      value,
      touched,
      validationMessage,
      defaultValue,
      enable,
    }).then(() => {
      this.props.onChange && this.props.onChange(this.getValues())
    })
  }

  state = {
    fields: {},
    pending: false,
    error: null,
    errors: {},
  }

  /**
   * @param {Event} event
   */
  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.validate().then(
      () => this.doSubmit(),
      () => this.focusFirstInvalidField(),
    )
  }

  handleReset = async () => {
    Object.values(this.fieldRefs).forEach((element) => {
      element?.reset?.()
    })
    await this.resetValues()
  }

  async doSubmit() {
    const { onSubmit: submit, redirect } = this.props
    await this.setStateAsync({ pending: true })
    try {
      const result = await submit(this.getValues())
      if (!this._unmounted) {
        await this.resetTouched()
        await this.setStateAsync({ pending: false })
      }
      redirect && redirect(result)
    } catch ({ error, errors }) {
      await this.setStateAsync({ pending: false })
      await this.setErrors(error, errors)
      this.focusFirstInvalidField()
    }
  }

  /**
   * @param {String} name
   * @param {{
   *  value:*,
   *  touched:Boolean,
   *  validationMessage:String,
   *  defaultValue:*,
   *  enable:Boolean
   * }} next
   */
  async updateFieldState(name, { value, touched, validationMessage, defaultValue, enable }) {
    // console.log(name, { value, touched, validationMessage, defaultValue, enable })

    if (this._unmounted) return

    return new Promise((resolve) => {
      this.setState((state) => {
        const { [name]: prev, ...fields } = state.fields
        if (!enable && !prev) {
          // was disabled, now disabled - nothing has changed
          resolve()
          return null
        }
        if (
          prev &&
          prev.value === value &&
          prev.touched === touched &&
          prev.validationMessage === validationMessage &&
          prev.defaultValue === defaultValue &&
          enable
        ) {
          // when nothing has changed
          resolve()
          return null
        }
        if (!enable) {
          // was enabled, now disabled - remove a field
          return { fields }
        }

        const next = { ...prev, value, touched, validationMessage, defaultValue, enable }
        return {
          fields: {
            ...fields,
            [name]: next,
          },
          error: null,
        }
      }, resolve)
    })
  }

  async resetTouched(value = false) {
    if (this._unmounted) return
    await this.setStateAsync((state) => {
      const entries = Object.entries(state.fields).map(([name, fieldState]) => {
        return [name, { ...fieldState, touched: value }]
      })
      return { fields: Object.fromEntries(entries) }
    })
  }

  async resetValues() {
    if (this._unmounted) return
    await this.setStateAsync((state) => {
      const entries = Object.entries(state.fields).map(([name, fieldState]) => {
        return [name, { ...fieldState, value: fieldState.defaultValue, touched: false }]
      })
      return { fields: Object.fromEntries(entries) }
    })
  }

  async setErrors(error, errors) {
    if (this._unmounted) return
    if (!errors || !Object.keys(errors).length) {
      await this.setStateAsync(() => ({ error, pending: false }))
      return
    }
    await this.setStateAsync((state) => {
      const nextFields = { ...state.fields }
      const unhandledMessages = []
      Object.entries(errors).forEach(([name, validationMessage]) => {
        if (nextFields.hasOwnProperty(name)) {
          nextFields[name] = {
            ...nextFields[name],
            validationMessage,
            touched: true,
          }
        } else {
          unhandledMessages.push(`${validationMessage} (${name})`)
        }
      })

      const nextState = { fields: nextFields, pending: false }

      if (unhandledMessages) {
        nextState.error = [error, ...unhandledMessages].filter(Boolean).join(', ')
      } else {
        nextState.error = error
      }

      return nextState
    })
  }

  reset() {
    this.element.current && this.element.current.reset()
    this.handleReset()
  }

  getCustomValidationError(values = this.getValues()) {
    const { validation } = this.props
    return validation ? validation(values) : null
  }

  /**
   * @returns {boolean}
   */
  isValid(values = this.getValues()) {
    if (this.hasInvalidField()) return false
    return !this.getCustomValidationError(values)
  }

  async validate() {
    await this.resetTouched(true)
    if (this.hasInvalidField()) {
      throw new Error('fail')
    }
    const error = await this.getCustomValidationError()
    await this.setStateAsync(() => ({ error }))
    if (error) {
      throw new Error('fail')
    }
  }

  /** @public */
  setValues(values = {}) {
    return Promise.all(
      Object.entries(values).map(([name, value]) => {
        if (this.fieldRefs[name]) {
          this.fieldRefs[name]?.setValue?.(value)
        }
      }),
    )
  }

  /** @public */
  getValues(fields = this.state.fields) {
    const entries = Object.entries(fields).map(([name, fieldState]) => [name, fieldState.value])
    return Object.fromEntries(entries)
  }
  hasInvalidField(fields = this.state.fields) {
    return Object.values(fields).some((fieldState) => !!fieldState.validationMessage)
  }

  getFirstInvalidFieldName(fields = this.state.fields) {
    for (const [name, state] of Object.entries(fields)) {
      if (state.validationMessage) return name
    }
  }

  hasModifiedField(fields = this.state.fields) {
    return Object.values(fields).some(
      ({ value, defaultValue }) => (value || defaultValue) && value !== defaultValue,
    )
  }

  getFieldsValidity(fields = this.state.fields) {
    const entries = Object.entries(fields).map(([name, fieldState]) => [
      name,
      !fieldState.validationMessage,
    ])
    return Object.fromEntries(entries)
  }

  focusFirstInvalidField() {
    const name = this.getFirstInvalidFieldName()
    const ref = this.fieldRefs[name]
    if (ref?.focus) {
      return ref.focus()
    }
    if (ref?.scrollIntoView) {
      return ref.scrollIntoView({ behavior: 'smooth' })
    } else {
      this.errorMessageRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  setStateAsync(state) {
    return Promise.race([
      new Promise((resolve) => {
        this.setState(state, resolve)
      }),
      // not sure whether the React's setState
      // executes the callback when state has no changes
      new Promise((resolve) => setTimeout(resolve, 1)),
    ])
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  render() {
    const {
      onSubmit,
      children,
      onChange,
      redirect,
      className,
      validation,
      readOnly,
      defaultValues,
      disabled,
      name,
      ...props
    } = this.props

    const { error, pending, fields, wasSubmitted } = this.state

    const modified = this.hasModifiedField()
    const values = this.getValues()
    const valid = this.isValid()

    // console.log('modified', modified)
    // console.log('values', values)
    // console.log('valid', valid)

    return (
      <form
        lang="en"
        {...props}
        name={name}
        id={props?.id ?? name}
        onSubmit={this.handleSubmit}
        ref={this.element}
        onReset={this.handleReset}
        className={cn(className, 'form')}
        noValidate
      >
        <ValidationMessage error ref={this.errorMessageRef}>
          {(!this.hasInvalidField() && error) || null}
        </ValidationMessage>

        <FormFieldEmitter.Provider value={this.setFieldStatus}>
          <FormPropsContext.Provider
            value={{
              readOnly,
              defaultValues,
              disabled,
              pending,
              modified,
              valid,
              values,
              fields,
              wasSubmitted,
            }}
          >
            {children}
          </FormPropsContext.Provider>
        </FormFieldEmitter.Provider>
      </form>
    )
  }
}
