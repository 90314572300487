import './radio-option.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

import { RadioButton } from './radio-button'

export const RadioOption = ({
  value,
  defaultValue,
  className,
  children,
  style,
  buttonComponent: Button,
  touched,
  ...props
}) =>
  props.name ? (
    <label className={cn('radio-option option', Button && 'hidden-input', className)} style={style}>
      <input
        {...props}
        type="radio"
        defaultChecked={String(value) === String(defaultValue)}
        value={value}
      />
      {Button && <Button />}

      <span className="option-label">{children}</span>
    </label>
  ) : (
    false
  )

RadioOption.propTypes = {
  buttonComponent: PropTypes.elementType,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.shape({
    '--index': PropTypes.number,
  }),
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

RadioOption.defaultProps = {
  buttonComponent: RadioButton,
}
