import './checkbox.css'
import React from 'react'

import { CheckboxInput } from 'ui/form/checkbox-input'

import { BaseField, withFormDefaultValues } from '../base-field'
import { CheckboxTick } from './checkbox-tick'

export class Field extends BaseField {
  static displayName = 'CheckboxField'

  getWrapperClassName() {
    return super.getWrapperClassName('checkbox')
  }

  renderField(props) {
    return (
      <>
        <CheckboxInput {...props} />
        <CheckboxTick />
      </>
    )
  }
}

export const Checkbox = withFormDefaultValues(Field)
