import PropTypes from 'prop-types'

import { useFormProps } from '../context'

export const IsModified = ({ children, show, not }) => {
  const { modified } = useFormProps()

  let result = show || modified
  if (not) {
    result = !result
  }
  return result ? children : false
}

IsModified.propTypes = {
  show: PropTypes.bool,
}
