import './survey-generator.css'
import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import * as Fields from 'ui/field'
import { ExternalLink } from 'ui/link'
import { IsFieldValue, useField } from 'ui/form'
import { LocalDate } from 'ui/local-date'
import { CustomHTML } from 'ui/page/custom-html'

import { useUser } from 'components/user'

import {
  OPERATORS,
  Question,
  USER_ROLE as U,
  QUESTION_TYPE as Q,
  QuestionType,
  QuestionProps,
} from 'const/types'

export const IsFieldValid = ({ name, children }) => {
  const { validationMessage, value } = useField(name)
  const valid = Boolean(value && !validationMessage)
  return valid ? children : false
}

const Components = { ...Fields }

const SURVEY_QUESTION_TYPES_CHOICES = {
  [Q.CHECKBOX]: 'Checkbox',
  [Q.CHECKBOX_GROUP]: 'CheckboxGroup',
  [Q.CURRENCY]: 'CurrencyField',
  [Q.DATE]: 'DateField',
  [Q.EMAIL]: 'EmailField',
  [Q.NUMBER]: 'NumberField',
  [Q.PHONE]: 'PhoneField',
  [Q.RADIO_BUTTON_GROUP]: 'RadioButtonGroup',
  [Q.SELECT]: 'Select',
  [Q.TEXTAREA]: 'TextareaField',
  [Q.TEXT]: 'TextField',
  [Q.STATIC_CONTENT]: 'Static Content',
  [Q.SEPARATOR]: 'Separator',
  [Q.HTML]: 'Html',
}

const FIELDS_WITH_OPTIONS = [
  SURVEY_QUESTION_TYPES_CHOICES[Q.CHECKBOX_GROUP],
  SURVEY_QUESTION_TYPES_CHOICES[Q.RADIO_BUTTON_GROUP],
  SURVEY_QUESTION_TYPES_CHOICES[Q.SELECT],
]

export const generateAnswers = (questions, answers) => {
  let fixedAnswers = { ...answers }
  questions.forEach(({ type, name, options }) => {
    if ([Q.RADIO_BUTTON_GROUP, Q.SELECT].includes(type)) {
      fixedAnswers[name] = options.find(({ value }) => answers[name] === value)?.label ?? '-'
    }
    if (Q.CHECKBOX_GROUP === type) {
      const selectedChoices = fixedAnswers[name]?.split(',')
      fixedAnswers[name] =
        selectedChoices
          ?.map((ans) => options.find(({ value }) => ans === value)?.label || '-')
          ?.join(', ') ?? '-'
    }
    if (Q.CHECKBOX === type) {
      fixedAnswers[name] = fixedAnswers[name] === '1' ? 'Yes' : '-'
    }
    fixedAnswers[name] = fixedAnswers[name] ?? '-'
  })
  return fixedAnswers
}

const getStingAndNumberValues = (value) =>
  Number(value).toString() === value ? [value, Number(value)] : [value]

const fieldValuePropsGenerator = (dependsOn) => {
  const { name, operator, value } = dependsOn
  const operatorWithNot = operator.includes('_')
  let not = false
  let fieldValueOperator = operator

  if (operatorWithNot) {
    fieldValueOperator = operator.split('_')[1]
    not = true
  }

  switch (fieldValueOperator) {
    case OPERATORS.ALL_OF: {
      return {
        name,
        [fieldValueOperator]: value.split(',').flatMap((e) => e.trim()),
        not,
      }
    }
    case OPERATORS.ONE_OF: {
      return {
        name,
        [fieldValueOperator]: value.split(',').flatMap((e) => getStingAndNumberValues(e.trim())),
        not,
      }
    }
    case OPERATORS.EQUALS: {
      const onOfValues = getStingAndNumberValues(value)
      return { name, oneOf: onOfValues, not }
    }
    case OPERATORS.BLANK: {
      return { name, [fieldValueOperator]: true, not }
    }
    default:
      return {}
  }
}

const FormField = ({
  type,
  options,
  label,
  required,
  maxChoices,
  minChoices,
  correctAnswers,
  name,
  dependsOn,
  disabled,
  readOnly,
  hideMemberVote,
  description,
  validation,
  step,
  maxlength,
  withCorrectAnswerNotification,
}) => {
  if (hideMemberVote) {
    return (
      <>
        <p>
          <ReactMarkdown source={label} renderers={{ paragraph: 'span', link: ExternalLink }} />
        </p>
        <p>INVESTOR ANSWERS WILL BE RECORDED BUT HIDDEN UNTIL THE OFFERING IS CLOSED</p>
      </>
    )
  }
  const fieldName = SURVEY_QUESTION_TYPES_CHOICES[type]
  const props = {
    name,
    required,
    description,
    disabled,
    readOnly,
    label: <ReactMarkdown source={label} renderers={{ paragraph: 'span', link: ExternalLink }} />,
  }
  if (FIELDS_WITH_OPTIONS.includes(fieldName)) {
    if (!options) {
      throw new Error('Missing options')
    }
    props.options = options.sort((a, b) => a.weight - b.weight)
  }
  if (SURVEY_QUESTION_TYPES_CHOICES[Q.NUMBER] === fieldName) {
    props.min = 0
    props.step = step
  }
  if (
    [SURVEY_QUESTION_TYPES_CHOICES[Q.TEXT], SURVEY_QUESTION_TYPES_CHOICES[Q.TEXTAREA]].includes(
      fieldName,
    )
  ) {
    props.maxlength = maxlength
  }
  const Component = Components[fieldName]

  const correctAnswersCheckBoxGroupValidation = (value) => {
    if (correctAnswers) {
      const [missing, extra] = getMissingAndExtraValues(value, correctAnswers)

      if (!missing?.length && !extra?.length) {
        return null
      }

      return `Incorrect Answer`
    }
  }

  const correctAnswersValidation = (value) => {
    if (correctAnswers) {
      return value === correctAnswers ? null : `Incorrect Answer`
    }
  }

  if (fieldName === SURVEY_QUESTION_TYPES_CHOICES[Q.CHECKBOX_GROUP] && (maxChoices || minChoices)) {
    props.validation = (value) => {
      if (maxChoices && value?.split(',')?.length > maxChoices) {
        return `Select not more than ${maxChoices} answer${maxChoices > 1 ? 's' : ''}`
      }

      if (minChoices && value?.split(',')?.length < minChoices) {
        return `Select not less than ${minChoices} answer${minChoices > 1 ? 's' : ''}`
      }

      return correctAnswersCheckBoxGroupValidation(value)
    }
  }

  if (
    fieldName === SURVEY_QUESTION_TYPES_CHOICES[Q.CHECKBOX_GROUP] &&
    !(maxChoices || minChoices)
  ) {
    props.validation = correctAnswersCheckBoxGroupValidation
  }

  if (fieldName !== SURVEY_QUESTION_TYPES_CHOICES[Q.CHECKBOX_GROUP]) {
    props.validation = correctAnswersValidation
  }

  if (dependsOn) {
    const fieldValueProps = fieldValuePropsGenerator(dependsOn)
    return (
      <div className="survey-field-wrapper">
        <IsFieldValue {...fieldValueProps}>
          <Component {...props} />
          {withCorrectAnswerNotification && correctAnswers && (
            <IsFieldValid name={name}>
              <span className="survey-validation-correct-message">Correct Answer</span>
            </IsFieldValid>
          )}
        </IsFieldValue>
      </div>
    )
  }
  return (
    <div className="survey-field-wrapper">
      <Component {...props} />
      {withCorrectAnswerNotification && correctAnswers && (
        <IsFieldValid name={name}>
          <span className="survey-validation-correct-message">Correct Answer</span>
        </IsFieldValid>
      )}
    </div>
  )
}
FormField.propTypes = {
  correctAnswers: PropTypes.string,
  createdAt: PropTypes.string,
  dependsOn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  description: PropTypes.node,
  disabled: PropTypes.bool,
  hideMemberVote: PropTypes.bool,
  label: PropTypes.string,
  maxChoices: PropTypes.number,
  maxlength: PropTypes.number,
  minChoices: PropTypes.number,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.number,
  type: QuestionType,
  updatedAt: PropTypes.string,
  validation: PropTypes.func,
  withCorrectAnswerNotification: PropTypes.bool,
}

function getMissingAndExtraValues(arrayStr1, arrayStr2) {
  if (!arrayStr1 || !arrayStr2) {
    return []
  }
  const array1 = arrayStr1.split(',')
  const array2 = arrayStr2.split(',')

  const missingFromFirst = array2?.filter((value) => !array1.includes(value))
  const extraFromSecond = array1?.filter((value) => !array2.includes(value))

  return [missingFromFirst, extraFromSecond]
}

const QuestionGenerator = ({
  order,
  type,
  label,
  uuid,
  content,
  correctAnswers,
  html,
  name,
  hideMemberVote,
  showCreated,
  showUpdated,
  withCorrectAnswerNotification,
  disabled,
  readOnly,
  ...props
}) => {
  const user = useUser()

  if (!type) {
    return null
  }

  const hasAdvisorOrSupervisorRole = [U.ADVISOR, U.REGISTERED_REPRESENTATIVE, U.SUPERVISOR].some(
    (role) => user.roles.includes(role),
  )

  const hasCreated = showCreated && props.createdAt
  const hasUpdated = showUpdated && props.updatedAt

  const description =
    hasUpdated || hasCreated ? (
      <>
        {hasCreated && (
          <>
            <small>
              <b>Created at:</b> <LocalDate value={props.createdAt} />
            </small>

            <br />
          </>
        )}
        {hasUpdated && (
          <small>
            <b>Updated at:</b> <LocalDate value={props.updatedAt} />
          </small>
        )}
      </>
    ) : (
      false
    )

  let validation

  switch (type) {
    case Q.STATIC_CONTENT:
      if (props.dependsOn) {
        const fieldValueProps = fieldValuePropsGenerator(props.dependsOn)
        return (
          <IsFieldValue {...fieldValueProps}>
            <ReactMarkdown key={uuid || name} source={content} renderers={{ link: ExternalLink }} />
          </IsFieldValue>
        )
      }
      return (
        <ReactMarkdown key={uuid || name} source={content} renderers={{ link: ExternalLink }} />
      )
    case Q.HTML:
      if (props.dependsOn) {
        const fieldValueProps = fieldValuePropsGenerator(props.dependsOn)
        return (
          <IsFieldValue {...fieldValueProps}>
            <CustomHTML key={uuid || name} html={html} />
          </IsFieldValue>
        )
      }
      return <CustomHTML key={uuid || name} html={html} />
    case Q.SEPARATOR:
      if (props.dependsOn) {
        const fieldValueProps = fieldValuePropsGenerator(props.dependsOn)
        return (
          <IsFieldValue {...fieldValueProps}>
            <hr key={uuid || name} />
          </IsFieldValue>
        )
      }
      return <hr key={uuid || name} />
    default:
      return (
        <FormField
          {...props}
          type={type}
          key={uuid || name}
          name={name || uuid}
          label={label}
          required={props.required}
          hideMemberVote={hasAdvisorOrSupervisorRole && hideMemberVote}
          disabled={disabled}
          description={description}
          readOnly={readOnly}
          validation={validation}
          correctAnswers={correctAnswers}
          withCorrectAnswerNotification={withCorrectAnswerNotification}
        />
      )
  }
}

QuestionGenerator.propTypes = QuestionProps

export const SurveyGenerator = ({
  questions,
  showCreated,
  showUpdated,
  withCorrectAnswerNotification,
  disabled,
  readOnly,
}) => {
  if (!questions) return null
  return questions
    ?.sort((a, b) => a.order - b.order)
    .map((question) => (
      <QuestionGenerator
        key={question.uuid || question.name}
        {...question}
        showCreated={showCreated}
        showUpdated={showUpdated}
        disabled={disabled || question?.disabled}
        withCorrectAnswerNotification={withCorrectAnswerNotification}
        readOnly={readOnly}
      />
    ))
}

SurveyGenerator.propTypes = {
  questions: PropTypes.arrayOf(Question),
}
