import { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'

import { useField } from '../context'

const createLesserValidator = (matchValue, label, includeEqual) => (value) => {
  if (includeEqual) {
    return value <= matchValue ? null : `Must be lesser or equal than ${label}`
  }
  return value < matchValue ? null : `Must be lesser than ${label}`
}

export const AddLesserThanFieldValidation = ({ name, label, includeEqual, children }) => {
  const { value } = useField(name)

  return cloneElement(Children.only(children), {
    validation: createLesserValidator(value, label, includeEqual),
  })
}

AddLesserThanFieldValidation.propTypes = {
  children: PropTypes.node.isRequired,
  includeEqual: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}
