import React, { useState, useCallback, SyntheticEvent } from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

import { Button, ButtonProps } from './button'

export interface ToggleButtonProps extends ButtonProps {
  open?: boolean
  onToggle?: (open: boolean) => void
  onClick?: (e: SyntheticEvent<EventTarget>) => void
}

export const ToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ open: _open = false, className, onToggle, onClick, ...props }, ref) => {
    const [open, setOpen] = useState(_open)
    const toggle = useCallback(
      (event) => {
        setOpen(!open)
        onClick && onClick(event)
        onToggle && onToggle(!open)
      },
      [onClick, onToggle, open],
    )

    return (
      <Button
        {...props}
        className={cn('toggle', className)}
        aria-expanded={open ? true : undefined}
        onClick={toggle}
        ref={ref}
      />
    )
  },
)
ToggleButton.displayName = 'ToggleButton'
ToggleButton.propTypes = {
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
}
