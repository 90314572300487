// import React from 'react'
import PropTypes from 'prop-types'

import { BaseInput } from './base-input'

export class BaseHtmlValidationInput extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    validationMessage: PropTypes.string,
    validationMessages: PropTypes.shape({
      valueMissing: PropTypes.string,
      typeMismatch: PropTypes.string,
      patternMismatch: PropTypes.string,
      tooLong: PropTypes.string,
      tooShort: PropTypes.string,
    }),
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  willValidate() {
    return this.props.name && !this.props.disabled
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid() {
    if (!this.element) return false
    if (!this.element.validity.valid) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  resetCustomValidity({ value = this.getValue() } = {}) {
    if (!this.element) return
    const current = this.getValidationMessage()
    const willValidate = this.willValidate()

    if (!willValidate) {
      current && this.element.setCustomValidity('')
      return
    }

    const customValidationMessage = this.checkCustomValidity(value)
    if (customValidationMessage) {
      this.element.setCustomValidity(customValidationMessage)
      return
    }

    const { validationMessage } = this.props
    if (
      validationMessage &&
      validationMessage !== current &&
      validationMessage !== this.recentState.validationMessage
    ) {
      this.element.setCustomValidity(validationMessage)
      return
    }

    this.element.setCustomValidity('')
  }

  checkValidityProps(props) {
    if (!this.element) return
    const { validationMessages } = this.props
    const { validity } = this.element
    const invalidOverriddenProp =
      validationMessages && props.find((prop) => validity[prop] && validationMessages[prop])

    const htmlValidationMessage = invalidOverriddenProp
      ? validationMessages[invalidOverriddenProp]
      : this.element.validationMessage

    return htmlValidationMessage || null
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity() || null
  }

  focus() {
    this.element.focus()
  }

  scrollIntoView(...args) {
    this.element.scrollIntoView(...args)
  }

  getValue() {
    if (!this.element) return undefined
    const value = String(this.element.value).trim()
    return value === '' ? null : value
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    this.element.value = value
    this.resetCustomValidity()
    this.broadcastUpdates()
  }

  /**
   * @public
   * @override
   */
  validate() {
    this.resetCustomValidity()
    this.broadcastUpdates({ touched: true })
  }

  componentDidUpdate(prevProps, prevState) {
    this.resetCustomValidity()
    super.componentDidUpdate(prevProps, prevState)
  }
}

const VALIDITY_PROPS = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
]
