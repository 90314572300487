import React from 'react'
import T from 'prop-types'

import { BaseInput } from './base-input'

export class CheckboxInput extends BaseInput {
  static propTypes = {
    ...BaseInput.propTypes,
    disabled: T.bool,
    name: T.string.isRequired,
    validation: T.func,
  }
  static defaultProps = {
    defaultValue: false,
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = (element) => {
    this.element = element
    if (!element) return
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.props.defaultValue || false })
  }

  handleChange = (event) => {
    const { checked } = event.target
    // let the parent form knows about changes
    this.broadcastUpdates({ value: checked, touched: true })
  }

  handleBlur = (event) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(event)
  }

  /**
   * @public
   * @override
   */
  getValidationMessage() {
    if (this.props.required && !this.element.checked) {
      return 'Required'
    }
    return this.checkCustomValidity()
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid() {
    if (!this.element) return false
    if (this.props.required && !this.element.checked) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    return this.element.checked || false
  }

  setValue(value = false) {
    if (!this.element) return
    this.element.checked = value
    this.broadcastUpdates()
  }

  focus() {
    this.element.focus()
  }

  scrollIntoView(...args) {
    this.element.scrollIntoView(...args)
  }

  render() {
    const { disabled, validation, validationMessage, defaultValue, readOnly, ...props } = this.props
    return (
      <input
        data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        disabled={disabled || readOnly}
        type="checkbox"
        defaultChecked={defaultValue}
      />
    )
  }
}
